import { API_ROOT_PATH } from "constants/api";
import { MICROSERVICES_GATEWAY } from "constants/microservices";

export const createProSubscription = async (body, session) => {
	const url = `${MICROSERVICES_GATEWAY}/subscription`;

	let error;
	try {
		let response = await fetch(url, {
			method: "POST",
			headers: session || {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to create subscription");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const reactivateSubscription = async (subsription_id, session) => {
	const url = `${MICROSERVICES_GATEWAY}/subscription/${subsription_id}`;

	let error;
	try {
		let response = await fetch(url, {
			method: "POST",
			headers: session || {
				"Content-Type": "application/json",
			},
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to create subscription");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};


export const fetchGetDiscount = async (session, subscriptionId) => {
	const url = `${MICROSERVICES_GATEWAY}/subscription/${subscriptionId}`;

	let error;
	try {
		let response = await fetch(url, {
			method: "PATCH",
			headers: session,
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to get discount");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const fetchCancelSubscription = async (session, subscriptionId) => {
	const url = `${MICROSERVICES_GATEWAY}/subscription/${subscriptionId}`;

	let error;
	try {
		let response = await fetch(url, {
			method: "DELETE",
			headers: session,
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to cancel company subscription");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const fetchBillingSettings = async (company_id, session) => {
	// const url = `${MICROSERVICES_GATEWAY}/subscription/${company_id}`;
	const url = `${API_ROOT_PATH}/v2/subscription/${company_id}`;

	let error;
	try {
		let response = await fetch(url, {
			method: "GET",
			headers: session,
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to get billing settings");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const fetchChangeCardDetails = async (company_id, session) => {
	const url = `${MICROSERVICES_GATEWAY}/payments/add-payment-method/${company_id}`;

	let error;
	try {
		let response = await fetch(url, {
			method: "GET",
			headers: session,
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to update card details");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const fetchFreeAccountCreationLimit = async (company_id, session) => {
	const url = `${API_ROOT_PATH}/v2/companies/${company_id}/allowance`;

	let error;
	try {
		let response = await fetch(url, {
			method: "GET",
			headers: session,
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to get creation totals");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const updateSubscription = async (session, body, company_id) => {
	// const url = `${MICROSERVICES_GATEWAY}/subscription`;
	const url = `${API_ROOT_PATH}/v2/companies/${company_id}/team_members/add`;

	let error;
	try {
		let response = await fetch(url, {
			method: "POST",
			headers: session,
			body: JSON.stringify(body),
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to update subscription");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const removeTeamMember = async (session, company_id, team_members_id) => {
	// const url = `${MICROSERVICES_GATEWAY}/subscription`;
	const url = `${API_ROOT_PATH}/v2/companies/${company_id}/team_members/${team_members_id}/remove`;

	let error;
	try {
		let response = await fetch(url, {
			method: "GET",
			headers: session,
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to remove user");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const updateTeamMemberRole = async (session, body, company_id) => {
	// const url = `${MICROSERVICES_GATEWAY}/subscription`;
	const url = `${API_ROOT_PATH}/v2/companies/${company_id}/team_members/edit`;

	let error;
	try {
		let response = await fetch(url, {
			method: "POST",
			headers: session,
			body: JSON.stringify(body),
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to update subscription");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const isProSubscription = (status) => {
	return ["active", "lifetime"].includes(status)
};
