import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
// import { AWS_CDN_URL } from "constants/api";
import { COLORS } from "constants/style";
import { getSignedURL } from "api/s3";
const AvatarDiv = styled.div`
	background: ${COLORS.suplement_light_grey};
	border-radius: ${(props) => (props.shape === "square" ? "4px" : "50%")};
	height: ${(props) => props.size}px;
	min-height: ${(props) => props.size}px;
	min-width: ${(props) => props.size}px;
	width: ${(props) => props.size}px;
`;

const AvatarImgDiv = styled(AvatarDiv)`
	background-image: url(${(props) => props.imgUrl});
	background-size: cover;
	background-position: center;
`;

const MisingAvatarDiv = styled(AvatarDiv)`
	color: ${(props) => props.color || COLORS.text_dark_grey};
	background: ${(props) => props.background || COLORS.suplement_light_grey};
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: ${(props) =>
		props.size >= 200
			? "62px"
			: props.size >= 100
			? "40px"
			: props.size >= 50
			? "20px"
			: props.size >= 30
			? "15px"
			: props.size <= 20
			? "10px"
			: "11px"};

	i {
		margin-bottom: 3px;
		margin-left: 1px;
	}
`;

const AvatarNameDiv = styled(AvatarDiv)`
	color: ${(props) => props.color || COLORS.text_dark_grey};
	background: ${(props) => props.background || COLORS.suplement_light_grey};
	font-size: ${(props) =>
		props.size >= 200
			? "62px"
			: props.size >= 100
			? "40px"
			: props.size >= 50
			? "20px"
			: props.size >= 30
			? "13px"
			: props.size <= 20
			? "10px"
			: "12px"};
	font-weight: 500;
	justify-content: center;
	object-fit: cover;
	text-transform: uppercase;
`;

const AvatarName = ({ name, size, shape, style, className, colors }) => {
	let index, firstName, lastName, initials;

	if (typeof name !== "string") {
		index = ""?.indexOf(" ");
		firstName = ""?.substr(0, index);
		lastName = ""?.substr(index + 1);
		initials = firstName?.charAt(0) + lastName?.charAt(0);
	} else {
		index = name?.indexOf(" ");
		firstName = name?.substr(0, index);
		lastName = name?.substr(index + 1);
		initials = firstName?.charAt(0) + lastName?.charAt(0);
	}

	return (
		<AvatarNameDiv
			shape={shape}
			size={size}
			style={style}
			className={"leo-flex-center " + className || ""}
			background={colors?.background}
			color={colors?.color}
		>
			{initials}
		</AvatarNameDiv>
	);
};

const AvatarImg = ({ imgUrl, size, shape, style, className }) => (
	<AvatarImgDiv shape={shape} size={size} imgUrl={imgUrl} style={style} className={className} />
);

const MissingAvatar = ({ size, shape, style, className, colors }) => (
	<MisingAvatarDiv
		shape={shape}
		size={size}
		// imgUrl={`${AWS_CDN_URL}/illustrations/MissingAvatar.png`}
		style={style}
		className={className}
		background={colors?.background}
		color={colors?.color}
	>
		<i className="fas fa-user" />
	</MisingAvatarDiv>
);

const AvatarIcon = ({
	name,
	imgUrl,
	size,
	shape,
	style,
	className,
	colors,
	uploading,
	...props
}) => {
	const [url, setUrl] = useState(undefined);
	const pxSize = useMemo(
		() => (typeof size === "number" ? size : sizeExchange[size] || 40),
		[size]
	);

	useEffect(() => {
		if (imgUrl) {
			getSignedURL(imgUrl, process.env.REACT_APP_DOCUMENT_AWS_BUCKET, setUrl);
		}
	}, [imgUrl]);

	return imgUrl === null ? (
		<AvatarName
			name={name}
			size={pxSize}
			shape={shape}
			style={style}
			className={className}
			colors={colors}
			{...props}
		/>
	) : url || imgUrl ? (
		<AvatarImg
			imgUrl={!uploading ? url : imgUrl}
			size={pxSize}
			shape={shape}
			style={style}
			className={className}
			{...props}
		/>
	) : name ? (
		<AvatarName
			name={name}
			size={pxSize}
			shape={shape}
			style={style}
			className={className}
			colors={colors}
			{...props}
		/>
	) : (
		<MissingAvatar
			size={pxSize}
			shape={shape}
			style={style}
			className={className}
			{...props}
			colors={colors}
		/>
	);
};

export const sizeExchange = {
	extraSmall: 30,
	small: 40,
	medium: 50,
	large: 100,
	extraLarge: 200,
};

export default AvatarIcon;
