import { API_ROOT_PATH } from "constants/api.js";
import fetch from "fetch-retry";
import notify from "notifications";

export const fetchSkills = async (session, company_id) => {
	const url = `${API_ROOT_PATH}/v1/skills/${company_id ? `?company_id=${company_id}` : ""}`;
	let error;
	try {
		let response = await fetch(url, {
			method: "GET",
			headers: session,
			retryOn: [500, 404],
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to fetch skills");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

// remove skills
export const removeSkill = async (session, skillId) => {
	const url = `${API_ROOT_PATH}/v1/skills`;
	let error;
	try {
		let response = await fetch(url, {
			method: "POST",
			headers: session,
			body: JSON.stringify({ id: [skillId] }),
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to remove skill");
		}
		notify("info", "The item has been deleted!");
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const fetchIndustries = async (session, company_id) => {
	const url = `${API_ROOT_PATH}/v1/categories/${company_id ? `?company_id=${company_id}` : ""}`;
	let error;
	try {
		let response = await fetch(url, {
			method: "GET",
			headers: session,
			retryOn: [500, 404],
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to fetch industries");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

// remove industries
export const removeIndustry = async (session, industryId) => {
	const url = `${API_ROOT_PATH}/v1/categories`;
	let error;
	try {
		let response = await fetch(url, {
			method: "POST",
			headers: session,
			body: JSON.stringify({ id: [industryId] }),
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to remove skill");
		}
		notify("info", "The item has been deleted!");
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const fetchLocations = async () => {
	const url = `${API_ROOT_PATH}/v1/locations/`;
	let error;
	try {
		let response = await fetch(url, {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to fetch locations");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const fetchCurrencies = async () => {
	const url = `${API_ROOT_PATH}/v1/currency/`;
	let error;
	try {
		let response = await fetch(url, {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to fetch currencies");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const fetchDepartments = async (session, company_id) => {
	const url = `${API_ROOT_PATH}/v2/companies/${company_id}/get_sectors`;
	let error;
	try {
		let response = await fetch(url, {
			method: "GET",
			headers: session,
			retryOn: [500, 404],
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to fetch departments");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const fetchBusinessAreas = async (session, company_id) => {
	const url = `${API_ROOT_PATH}/v2/companies/${company_id}/get_areas`;
	let error;
	try {
		let response = await fetch(url, {
			method: "GET",
			headers: session,
			retryOn: [500, 404],
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to fetch business areas");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};
export const fetchSectors = async (session, company_id, team_member_id) => {
	const url = `${API_ROOT_PATH}/v2/companies/${company_id}/sectors?team_member_id=${team_member_id}`;
	let error;
	try {
		let response = await fetch(url, {
			method: "GET",
			headers: session,
			retryOn: [500, 404],
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to fetch tags");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const fetchAddSectors = async (session, company_id, team_member_id, body) => {
	const url = `${API_ROOT_PATH}/v2/companies/${company_id}/sectors?team_member_id=${team_member_id}`;
	let error;
	try {
		let response = await fetch(url, {
			method: "POST",
			headers: session,
			retryOn: [500, 404],
			body: JSON.stringify(body),
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to fetch tags");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const fetchDeleteSectors = async (session, company_id, team_member_id, body) => {
	const url = `${API_ROOT_PATH}/v2/companies/${company_id}/sectors?team_member_id=${team_member_id}`;
	let error;
	try {
		let response = await fetch(url, {
			method: "DELETE",
			headers: session,
			retryOn: [500, 404],
			body: JSON.stringify(body),
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to fetch tags");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};
