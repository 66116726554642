// STORES BACK END DATA IN THE APP NEEDED FOR FUNCTIONING CORRECTLY
import { WIDTHS } from "constants/style";
const GLOBAL_STATE = {
	session: undefined,
	user: undefined,
	full_user: undefined,
	allMyCompanies: undefined,
	company: undefined,
	full_company: undefined,
	current_company_id: undefined,
	role: undefined,
	teamMembers: undefined,
	allTeamMembers: undefined,
	deletedTeamMembers: undefined,
	skills: undefined,
	industries: undefined,
	departments: undefined,
	business_areas: undefined,
	tags: undefined,
	interviewStages: undefined,
	sources: undefined,
	contacting_types: undefined,
	approval_process: undefined,
	emailProvider: undefined,
	job_extra_fields: undefined,
	twilio: undefined,
	nylas: undefined,
	settings: undefined,
	kpis: undefined,
	subscription_settings: undefined,
	total_creation_numbers: {
		users: 0,
		jobs: 0,
		candidates: 0,
		clients: 0,
		contacts: 0,
		deals: 0,
		pipelines: 0,
	},
	snow: false
};

// STORES DATA ONLY NEEDED FOR UI FUNCTIONALITY
export const STORAGE_STATE = {
	emailsScanPending: false,
	totals: {},
	main_endpoints_load: {
		user: false,
		all_companies: false,
		role: undefined,
	},
	stored_filters: {},
	stored_segment: {},
	stored_searches: {},
	use_stored_search: undefined,
	countries_info: undefined,
	currencies_info: undefined,
	ats_navbar_size: WIDTHS.left_navbar_compact,
	unread_message_notifications: undefined,
	subscription_modal: undefined,
};

export default GLOBAL_STATE;
