import { useReducer } from "react";
import HISTORY_STATE from "contexts/historyContext/HistoryState";
import HistoryReducer from "contexts/historyContext/HistoryReducer";
import { createContainer } from "react-tracked";

const useValue = () => useReducer(HistoryReducer, HISTORY_STATE);
export const {
	Provider: HistoryProvider,
	useTrackedState: useStore,
	useUpdate: useDispatch,
} = createContainer(useValue);
