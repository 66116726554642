import styled from "styled-components";
import { COLORS, COLORSV2 } from "constants/style";
import { Link } from "react-router-dom";
// <Button
//   full={true || undefined}
//   size={"small" || "large" || undefined = "default"}
//   theme={"dark" || "pink" || "blue" || "dark-blue" || "grey" || "light-grey" || "white" || "primary" || light-blue || "transparent" }
// />
const AppButton = styled.button`
	background: transparent;
	background-color: ${(props) => themes[props.theme]?.background || themes.default.background};
	border: ${(props) => themes[props.theme]?.border || 0};
	border-radius: ${(props) => radius[props.size] || radius.default}px;
	box-shadow: ${(props) =>
		props.theme === "white" || props.theme === "light-grey"
			? "none"
			: "0 1px 4px 0 rgba(0, 0, 0, 0.04), inset 0 0 0 1px rgba(0, 0, 0, 0.05)"};
	color: ${(props) => themes[props.theme]?.color || themes.default.color};
	cursor: pointer;
	display: inline-block;
	font-size: ${(props) => fontSize[props.size] || fontSize.default}px !important;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 0;
	padding: ${(props) => padding[props.size] || padding.default};
	text-align: center;
	touch-action: manipulation;
	transition: all 0.2s ease-in-out;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	width: ${(props) => (props.full ? "100%" : "initial")};

	&:hover,
	&:active,
	&:focus {
		background-color: ${(props) =>
			themes[props.theme]?.backgroundHover || themes.default.backgroundHover};
		color: ${(props) => themes[props.theme]?.color || themes.default.color};
		text-decoration: none;
	}
`;

export const AppLink = styled(Link)`
	background: transparent;
	background-color: ${(props) => themes[props.theme]?.background || themes.default.background};
	border: ${(props) => (props.theme === "white" ? `solid 1px ${COLORS.primary_theme_blue}` : 0)};
	border-radius: ${(props) => radius[props.size] || radius.default}px;
	box-shadow: ${(props) =>
		props.theme === "white" || props.theme === "light-grey"
			? "none"
			: "0 1px 4px 0 rgba(0, 0, 0, 0.04), inset 0 0 0 1px rgba(0, 0, 0, 0.05)"};
	color: ${(props) => themes[props.theme]?.color || themes.default.color};
	cursor: pointer;
	display: inline-block;
	font-size: ${(props) => fontSize[props.size] || fontSize.default}px !important;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 0;
	padding: ${(props) => padding[props.size] || padding.default};
	text-align: center;
	touch-action: manipulation;
	transition: all 0.2s ease-in-out;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	width: ${(props) => (props.full ? "100%" : "initial")};

	&:hover,
	&:active,
	&:focus {
		background-color: ${(props) =>
			themes[props.theme]?.backgroundHover || themes.default.backgroundHover};
		color: ${(props) => themes[props.theme]?.color || themes.default.color};
		text-decoration: none;
	}
`;

const radius = { micro: 3, small: 4, default: 4 };

const fontSize = {
	micro: 10,
	small: 12,
	large: 16,
	default: 14,
};

export const padding = {
	micro: "3px 5px",
	small: "6px 10px",
	large: "20px 50px",
	default: "10px 20px",
};

export const themes = {
	dark: {
		background: "#2c2c2c",
		backgroundHover: "#1F1F1F",
		color: COLORS.white,
	},
	pink: {
		background: "#ff3159",
		backgroundHover: "#F2244C",
		color: COLORS.white,
	},
	primary: {
		background: COLORS.primary_light_green,
		backgroundHover: "#00BE9A",
		color: COLORS.white,
	},
	blue: {
		background: "#004a6d",
		backgroundHover: "#003D60",
		color: COLORS.white,
	},
	"light-blue": {
		background: COLORS.primary_light_blue,
		backgroundHover: "#73BBD4",
		color: COLORS.white,
	},
	"dark-blue": {
		background: COLORSV2.nave_blue,
		backgroundHover: "#1D2A37",
		color: COLORS.white,
	},
	default: {
		background: COLORSV2.nave_blue,
		backgroundHover: COLORSV2.nave_blue,
		color: COLORS.white,
	},
	grey: {
		background: COLORS.text_dark_grey,
		backgroundHover: "#67696E",
		color: COLORS.white,
	},
	"light-grey": {
		background: "#EEEEEE",
		backgroundHover: "#B4B6BB",
		color: "#1E1E1E",
	},
	"grey-2": {
		background: COLORS.suplement_medium_grey,
		backgroundHover: "#BBC6D1",
		color: COLORS.primary_theme_blue,
	},
	white: {
		background: COLORS.white,
		backgroundHover: "#F6FFFF",
		color: COLORSV2.nave_blue,
		border: `solid 1px  ${COLORS.primary_theme_blue}`,
	},
	transparent: {
		background: "transparent",
		backgroundHover: "rgba(255, 255, 255, 0.2)",
		color: COLORS.white,
		border: `solid 1px  ${COLORS.white}`,
	},
	orange: {
		background: COLORS.primary_orange,
		backgroundHover: "#E6875D",
		color: COLORS.white,
	},
	"light_blue": {
		background: COLORSV2.light_blue,
		backgroundHover: "#E6875D",
		color: COLORS.white,
	},
};

export default AppButton;
