import React from "react";
import styled from "styled-components";
// import InfinitySvg from "./Infinity.svg";

export const InfinityIcon = ({ fill = "#2a3744" }) => (
	<StyledIcon>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			width="50"
			height="50"
			viewBox="0 0 226 226"
		>
			<g transform="translate(1.469,1.469) scale(0.987,0.987)">
				<g
					fill="none"
					fillRule="nonzero"
					stroke={fill}
					strokeLinecap="butt"
					strokeMiterlimit="10"
					strokeDasharray=""
					strokeDashoffset="0"
					fontFamily="none"
					fontWeight="none"
					fontSize="none"
					textAnchor="none"
				>
					<g id="Layer_1" fill={fill} stroke={fill} strokeWidth="7">
						<path d="M174.79687,63.5625c-13.24219,0 -25.60156,5.12031 -34.95937,14.65469l-12.88906,13.59531c-1.94219,2.11875 -1.94219,5.47344 0.17656,7.41562c2.11875,1.94219 5.47344,1.94219 7.41563,-0.17656l12.88906,-13.41875c7.23906,-7.41562 16.95,-11.47656 27.36719,-11.47656c10.41719,0 20.12813,4.06094 27.54375,11.3c7.41562,7.23906 11.3,17.12656 11.3,27.54375c0,10.41719 -4.06094,20.12812 -11.3,27.54375c-7.23906,7.41562 -17.12656,11.3 -27.54375,11.3c-10.41719,0 -20.12812,-4.06094 -27.36719,-11.3l-61.26719,-62.50313c-19.24531,-19.24531 -50.67344,-19.24531 -69.91875,0c-9.35781,9.35781 -14.47812,21.71719 -14.47812,34.95937c0,13.24219 5.12031,25.60156 14.47812,34.95937c19.24531,19.24531 50.67344,19.24531 69.91875,-0.17656l12.88906,-13.59531c1.94219,-2.11875 1.94219,-5.47344 -0.17656,-7.41563c-2.11875,-1.94219 -5.47344,-1.94219 -7.41562,0.17656l-12.88906,13.41875c-15.18438,15.18438 -39.72656,15.18438 -54.91094,0c-7.23906,-7.23906 -11.3,-16.95 -11.3,-27.36719c0,-10.41719 4.06094,-20.12813 11.3,-27.54375c15.18437,-15.18437 39.72656,-15.18437 54.91094,0l61.26719,62.50312c9.35781,9.35781 21.71719,14.47813 34.95937,14.47813c13.24219,0 25.60156,-5.12031 34.95938,-14.47813c9.35781,-9.35781 14.47812,-21.71719 14.47812,-34.95937c0,-13.24219 -5.12031,-25.60156 -14.47812,-34.95937c-9.35781,-9.35781 -21.71719,-14.47813 -34.95938,-14.47813z"></path>
					</g>
				</g>
			</g>
		</svg>
	</StyledIcon>
);

const StyledIcon = styled.div`
	margin-right: 5px;
	width: 35px;
	height: 35px;

	svg {
		max-width: 100%;
	}
`;
