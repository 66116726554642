import { useMemo } from "react";
import { generateReplaceString } from "sharedComponents/unbiased/helpers";

const HiddenText = ({ str = "", replace }) => {
	const hiddenString = useMemo(() => (str ? generateReplaceString(str) : ""), [str]);

	return replace ? hiddenString : str;
};

export default HiddenText;
