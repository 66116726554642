import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ROUTES } from "routes";
import { BREAKPOINTS } from "constants/breakpoints";
import AvatarIcon from "sharedComponents/AvatarIcon";
import Dropdown from "react-bootstrap/Dropdown";
import { COLORS, COLORSV2 } from "constants/style";
import Planinfo from "sharedComponents/PlanInfo";

const UserDropMenu = ({ name, avatarUrl, store }) => {
	return (
		<>
			<HeaderButtons className="leo-flex">
				{store.session && store.company && <Planinfo store={store} />}
				{store.session && (
					<>
						<TasksLink to={ROUTES.TasksManager.url("incomplete")} className="leo-relative">
							<TaskIcon />
							{store.totals?.uncompleted_tasks > 0 && (
								<NotificationBubble className="leo-flex">
									{store.totals.uncompleted_tasks}
								</NotificationBubble>
							)}
						</TasksLink>
					</>
				)}
				{/*store.company && (
					<Link to={ROUTES.TeamView.url(store.company.mention_tag, "user")}>
						<img src={`${AWS_CDN_URL}/icons/SettingsWheel.svg`} alt="Settings" />
					</Link>
				)*/}
				<HeaderDropdown className="dropdown leo-relative">
					<Dropdown.Toggle as="button" className="dropdown-toggle leo-flex">
						<AvatarIcon name={name} imgUrl={avatarUrl} size={"extraSmall"} />
					</Dropdown.Toggle>
					<Dropdown.Menu
						className="dropdown-new dropdown-menu dropdown-menu-right"
						style={{ top: "35px", zIndex: "1000000" }}
					>
						{store.company && (
							<MenuTab
								url={ROUTES.TeamView.url(store.company.mention_tag, "user")}
								title={"User Settings"}
							/>
						)}
						<li className="dropdown-new-option" style={{ border: "0" }}>
							<button
								className="dropdown-new-link"
								onClick={() => {
									window.Intercom("show");
								}}
							>
								Report a Bug
							</button>
						</li>
						<MenuTab url={ROUTES.ProfessionalLogoff.url()} title={"Logoff"} />
					</Dropdown.Menu>
				</HeaderDropdown>
			</HeaderButtons>
		</>
	);
};

const MenuTab = ({ url, title, external }) => {
	return (
		<DropdownItem className="dropdown-new-option">
			{!external ? (
				<InternalLink className="dropdown-new-link" to={url}>
					{title}
				</InternalLink>
			) : (
				<ExternalLink
					className="dropdown-new-link"
					href={url}
					rel="noopener noreferrer"
					target="_blank"
				>
					{title}
				</ExternalLink>
			)}
		</DropdownItem>
	);
};

export default UserDropMenu;

const HeaderButtons = styled.div`
	align-items: center;

	a {
		display: block;
	}
`;

const HeaderDropdown = styled(Dropdown)`
	height: 30px;
	margin-left: 20px;

	@media screen and (min-width: 1024px) {
		height: auto;
	}

	.dropdown-toggle {
		align-items: center;
		height: 30px;
		justify-content: space-between;
		padding: 0;
		width: 30px;
	}

	i {
		color: ${COLORS.white};
		font-size: 12px;
		position: absolute;
		right: 0;
	}
`;

const NotificationBubble = styled.div`
	align-items: center;
	background: ${COLORSV2.red};
	border-radius: 50%;
	color: ${COLORS.white};
	font-size: 8px;
	font-weight: 600;
	height: 15px;
	justify-content: center;
	line-height: 1;
	position: absolute;
	right: -7px;
	top: -5px;
	width: 15px;
`;

const DropdownItem = styled(Dropdown.Item)`
	padding: 0;
	border: 0;
`;

const InternalLink = styled(Link)`
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
`;

const ExternalLink = styled(Link)`
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
`;

const TasksLink = styled(Link)`
	@media ${BREAKPOINTS.for_phone_only} {
		display: none !important;
	}
`;

const TaskIcon = () => (
	<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="1" y="1" width="18" height="18" rx="3" stroke="#2A3744" strokeWidth="2" />
		<path
			d="M9.424 14.226a.515.515 0 01-.365-.15l-3.2-3.201a.516.516 0 11.73-.73l2.835 2.835 4.83-6.829a.517.517 0 01.73.73l-5.195 7.194a.518.518 0 01-.365.151z"
			fill="#fff"
			stroke="#2A3744"
		/>
	</svg>
);
