import React from "react";

const BurguerMenu = ({ expanded }) => {
	return (
		<div className={`hamburger ${expanded ? "is-active" : ""} hamburger--squeeze`}>
			<span className="hamburger-box">
				<span className="hamburger-inner"></span>
			</span>
		</div>
	);
};

export default BurguerMenu;
