import React from "react";
import styled from "styled-components";
import { AWS_CDN_URL } from "constants/api";

const FinalStage = () => {
	return (
		<Container className="leo-flex-center-center">
			<Title className="leo-mb-30">Enjoy your Unlimited</Title>
			<Img src={`${AWS_CDN_URL}/icons/BrandLogo.svg`} alt="BrandLogo" />
		</Container>
	);
};

const Container = styled.div`
	margin-top: 100px;
	flex-direction: column;
	z-index: 1;
`;

const Title = styled.h1`
	font-weight: bold;
	font-size: 30px;
	line-height: 36px;
	text-align: center;
	color: #ffffff;
`;
const Img = styled.img`
	height: 120px;
`;

export default FinalStage;
