import React, { useState, useEffect } from "react";
import styled from "styled-components";

let timeout;

const Fallback = ({ modal }) => {
	const [show, setShow] = useState(false);
	useEffect(() => {
		timeout = setTimeout(function () {
			setShow(true);
		}, 300);
		return () => clearTimeout(timeout);
	}, []);

	if (show) {
		return (
			<Wrapper className={`leo-flex-center-center ${modal ? "modal-case" : ""}`}>
				<div className="lds-ripple">
					<div></div>
					<div></div>
				</div>
			</Wrapper>
		);
	}
	return null;
};

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	min-height: 200px;
	transition: opacity 100ms;

	.lds-ripple {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-ripple div {
		position: absolute;
		background: #d9e0e6;
		opacity: 1;
		border-radius: 50%;
		animation: lds-ripple 1200ms cubic-bezier(0, 0.2, 0.8, 1) infinite;
	}
	.lds-ripple div:nth-child(2) {
		animation-delay: -0.5s;
	}
	@keyframes lds-ripple {
		0% {
			top: 36px;
			left: 36px;
			width: 0;
			height: 0;
			opacity: 1;
		}
		100% {
			top: 0px;
			left: 0px;
			width: 72px;
			height: 72px;
			opacity: 0;
		}
	}

	&.modal-case {
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		z-index: 100;
		background-color: #000;
		opacity: 0.5;

		.lds-ripple div {
			background: #2a3744;
		}
	}
`;

export default Fallback;
