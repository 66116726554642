import React from "react";
import { Container, SectionTitle, InputsGrid } from "components/CompanySignup/LeftForm";
import {
	Input,
	Label,
	RequiredSup,
	SelectType,
	ProgressButton,
	BackButton,
} from "components/CompanySignup/components.sc";
import { EMAIL_REGEX } from "constants/regex";

import notify from "notifications";
import { useTranslation } from "react-i18next";

const InfoStage = ({ form, setForm, setStage, setUpdatedInfo }) => {
	const { t } = useTranslation()
	const onChange = (e) => {
		setUpdatedInfo(true);
		setForm({ ...form, [e.target.name]: e.target.value });
	};

	const selectType = (newType) => {
		setUpdatedInfo(true);
		setForm({ ...form, company_type: newType });
	};

	const progressNextStage = () => {
		if (!form.name) {
			return notify("danger", t("statusMessage.mustInputAName"));
		}
		if (!form.email) {
			return notify("danger", t("statusMessage.mustInputAnEmail"));
		}
		if (EMAIL_REGEX.test(form.email) === false) {
			return notify("danger", t("statusMessage.mustInputAValidEmail"));
		}
		setStage("plan");
	};

	return (
		<Container className="no-margin">
			<SectionTitle>About yourself</SectionTitle>
			<InputsGrid className="leo-mb-50">
				<div>
					<Label>
						Name <RequiredSup>*</RequiredSup>
					</Label>
					<Input onChange={onChange} value={form.name} name="name" />
				</div>
				<div>
					<Label>Job Title</Label>
					<Input onChange={onChange} value={form.job_title} name="job_title" />
				</div>
				<div>
					<Label>
						Email Address <RequiredSup>*</RequiredSup>
					</Label>
					<Input onChange={onChange} value={form.email} name="email" type="email" />
				</div>
				<div>
					<Label>Phone Number</Label>
					<Input onChange={onChange} value={form.phone} name="phone" />
				</div>
			</InputsGrid>
			<SectionTitle>About your company</SectionTitle>
			<InputsGrid>
				<div className="leo-mb-30">
					<Label>
						Company Type <RequiredSup>*</RequiredSup>
					</Label>
					<SelectType
						onChange={selectType}
						value={form.company_type === "Agency" ? "Recruitment  Agency" : form.company_type}
						disabled={true}
					/>
				</div>
				<div className="leo-mb-30">
					<Label>
						Company Name <RequiredSup>*</RequiredSup>
					</Label>
					<Input onChange={onChange} value={form.company_name} name="company_name" />
				</div>
			</InputsGrid>
			<div className="leo-flex-center-center leo-mt-50">
				<BackButton
					className="no-margin"
					style={{ marginRight: "10px" }}
					onClick={() => setStage("initial")}
				>
					<i className="fas fa-angle-left leo-mr-10"></i> Back
				</BackButton>
				<ProgressButton
					onClick={progressNextStage}
					className="no-margin"
					style={{ marginLeft: "10px" }}
				>
					Next <i className="fas fa-angle-right leo-ml-10"></i>
				</ProgressButton>
			</div>
		</Container>
	);
};

export default InfoStage;
