import notify from "notifications";
import React, { useState } from "react";
import Checkbox from "sharedComponents/Checkbox";
import styled from "styled-components";
import { BackButton, ProgressButton } from "./components.sc";

export default function TermsAndConditions({ setStage, signUpAction, paymentMethod }) {
	const [termsCheckBox, setTermsCheckBox] = useState(true);
	const [privacyCheckBox, setPrivacyCheckBox] = useState(true);

	const handleSubmit = () => {
		if (termsCheckBox) {
			notify("danger", "Please, make sure you reviewed and accepted Our Terms and Conditions");
		}
		if (privacyCheckBox) {
			notify("danger", "Please, make sure you reviewed and accepted Our Privacy Policy");
		}
		if (!termsCheckBox && !privacyCheckBox) {
			signUpAction(paymentMethod);
		}
	};

	return (
		<>
			<TermsContainer>
				<ul>
					{termsAndConditions.map((term, idx) => (
						<li key={idx}>{term}</li>
					))}
				</ul>
			</TermsContainer>
			<CheckBoxContainer className="flex align-items-center">
				<Checkbox active={!termsCheckBox} onClick={() => setTermsCheckBox(!termsCheckBox)} />
				<p style={{ marginLeft: "10px" }}>
					You agree to {process.env.REACT_APP_NAME}&apos;s{" "}
					<a
						href={`${process.env.REACT_APP_MARKETING_SITE_URL}/termsOfService/`}
						target="_blank"
						rel="noopener noreferrer"
					>
						Terms and conditions
					</a>{" "}
				</p>
			</CheckBoxContainer>
			<CheckBoxContainer className="flex align-items-center">
				<Checkbox active={!privacyCheckBox} onClick={() => setPrivacyCheckBox(!privacyCheckBox)} />
				<p style={{ marginLeft: "10px" }}>
					You agree to {process.env.REACT_APP_NAME}&apos;s{" "}
					<a
						href={`${process.env.REACT_APP_MARKETING_SITE_URL}/privacyPolicy`}
						target="_blank"
						rel="noopener noreferrer"
					>
						Privacy Policy
					</a>{" "}
				</p>
			</CheckBoxContainer>

			<div className="leo-flex-center-center leo-mt-50">
				<BackButton
					className="no-margin"
					style={{ marginRight: "10px" }}
					onClick={() => setStage("bank_details")}
				>
					<i className="fas fa-angle-left leo-mr-10"></i> Back
				</BackButton>
				<ProgressButton onClick={handleSubmit} className="no-margin" style={{ marginLeft: "10px" }}>
					Sign Up
				</ProgressButton>
			</div>
		</>
	);
}

const termsAndConditions = [
	"If the Basic account is upgraded to PRO, you will be charged without a 14-day trial, from the day of the upgrade",
	"If you begin with PRO, you are entitled to a 14-day trial whereby you are charged once the trial ends.",
	"You are to submit billing details in order for your subscription to begin, again you will not be charged until after 14-days if this is your first account.",
	"You are entitled to unsubscribe at any time during the trial period, but data will not be extracted and deleted until 60 days after cancellation.",
	`PRO account users who unsubscribe will also have 60 days to extract their data before ${process.env.REACT_APP_NAME} deletes said data from its database.`,
];

const TermsContainer = styled.div`
	padding: 10px 30px;
	ul > li {
		font-size: 14px;
		line-height: 20px;
		list-style: number;
		margin-bottom: 5px;
	}
`;

// const Footer = styled.div`
// 	display: flex;
// 	align-items: center;
// 	justify-content: flex-end;
// `;

const CheckBoxContainer = styled.div`
	padding: 0px 10px;
	margin: 10px 0;
	margin-bottom: 20px;
`;
