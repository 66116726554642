import React, { useState, useEffect, Suspense } from "react";
import { ROUTES } from "routes";
import notify from "notifications";

import useGlobalContext from "contexts/globalContext/GlobalContext";
import useHistoryContext from "contexts/historyContext/HistoryContext";
import { Redirect } from "react-router-dom";
import { logOut, checkLastCompany } from "contexts/globalContext/GlobalMethods";

import Fallback from "styles/Fallback";
import retryLazy from "hooks/retryLazy";
import { useTranslation } from "react-i18next";

const CandidateMiniProfile = React.lazy(() =>
	retryLazy(() => import("modals/CandidateMiniProfileModal"))
);

let timeout;

const InnerPage = (props) => {
	const { t } = useTranslation()
	const store = useGlobalContext();
	const historyStore = useHistoryContext();
	const [redirect, setRedirect] = useState(false);
	const [checkSession, setCheckSession] = useState(false);
	const [activeModal, setActiveModal] = useState(undefined);
	const [tnProfileId, setTnProfileId] = useState(undefined);

	//WAIT 500MS TO CHECK FOR A SESSION TO REDIRECT TO SIGNIN IN CASE THE SESSION IS NOT VALID
	//THIS IS NEEDED TO GIVE TIME FOR THE STORE TO UPDATE SESSION AS THE PAGE LOADES
	useEffect(() => {
		timeout = setTimeout(() => {
			setCheckSession(true);
		}, 500);
		return () => clearTimeout(timeout);
	}, [store.session]);

	//REDIRECT TO SIGN IN IF THE SESSION DOES NOT EXIST OR IS NOT VALID, IF NOT VALID, DELETE THE SESSION FROM THE STORE
	useEffect(() => {
		if (checkSession) {
			if (!store.session || !store.session["access-token"]) {
				setRedirect(ROUTES.ProfessionalSignin.url());
			} else if (new Date(store.session.expiry * 1000) - Date.now() < 0) {
				notify("danger", t("statusMessage.sessionHasExpired"));
				logOut(store.dispatch, store.session);
				// setRedirect(ROUTES.ProfessionalSignin.url());
			}
		}
	}, [checkSession, store.session, store.dispatch]);

	//THIS useEffect REDIRECT THE USER TO CHANGE HIS PASSWORD IF IT IS A TEMPORARY PASSWORD
	useEffect(() => {
		if (
			store.user &&
			store.user.temp_password &&
			props.location.pathname !== ROUTES.NewPassword.url()
		) {
			setRedirect(ROUTES.NewPassword.url());
		}
	}, [store.user]);

	//THIS useEffect CHANGES THE COMPANY IN THE CONTEXT STORE BASED ON THE URL PARAM companyMentionTag
	useEffect(() => {
		if (props.match.params.companyMentionTag && store.allMyCompanies) {
			//IF THE MENTION TAG ALREADY MATCHES THE CURRENT COMPANY DON'T ACTIONS
			if (store.company && props.match.params.companyMentionTag === store.company.mention_tag)
				return;
			//FIND THE COMPANY IN THE COMPANIES ARRAY
			let companyMatch;
			store.allMyCompanies.map((company) => {
				if (company.mention_tag === props.match.params.companyMentionTag) {
					companyMatch = company;
				}
				return null;
			});
			//IF THERE IS NO COMPANY WITH THAT MENTION TAG IN THE USER ARRAY OF COMPANIES REDIRECT TO
			if (!companyMatch) {
				setRedirect(ROUTES.MyCompanies.url());
			} else {
				//IF THERE IS NO COMPANY IN THE STORE, STORE
				//IF THERE IS A COMPANY AND IT DOESN'T MATCH THE MENTION TAG PARAM, REPLACE IT
				if (!store.company) {
					store.dispatch({ type: "UPDATE_COMPANY", payload: companyMatch });
					store.dispatch({ type: "UPDATE_FULL_COMPANY", payload: undefined });
				} else if (store.current_company_id !== companyMatch.id) {
					store.dispatch({ type: "REPLACE_COMPANY", payload: companyMatch });
				}
			}
		}
		// else if (!props.match.params.companyMentionTag && store.company) {
		//   store.dispatch({ type: "DELETE_COMPANY" });
		// }
	}, [props.match.params.companyMentionTag, store.allMyCompanies]);

	//THIS useEffect REDIRECTS FROM THE BASE URL TO THE LAST USED COMPANY DASHBOARD OR FIRST COMPANY DASHBOARD
	useEffect(() => {
		//IF BASE URL AND COMPANIES ARRAY
		if (props.match.url === "/" && store.allMyCompanies) {
			//IF THE USER HAS NO COMPANIES REDIRECT TO
			if (!store.allMyCompanies.length) {
				setRedirect(ROUTES.MyCompanies.url());
				//IF THERE IS ONLY ONE COMPANY REDIRECT TO COMPANY DASHBOARD
			} else if (store.allMyCompanies.length === 1) {
				setRedirect(ROUTES.CompanyDashboard.url(store.allMyCompanies[0].mention_tag));
			} else {
				//IF MORE THAN ONE COMPANY CHECK LOCAL STORAGE FOR LAST COMPANY VISITED
				let lastCompanyId = checkLastCompany();
				let companyMatch;
				store.allMyCompanies.map((comp) =>
					comp.id === lastCompanyId ? (companyMatch = comp.mention_tag) : null
				);
				//IF LAST COMPANY VISITED EXISTS AND MATCHES A COMPANY IN THE USER COMPANIES ARRAY
				setRedirect(
					ROUTES.CompanyDashboard.url(companyMatch || store.allMyCompanies[0].mention_tag)
				);
			}
		}
	}, [props.match, store.allMyCompanies]);

	// update history state
	useEffect(() => {
		if (props.match.path) {
			if (historyStore.state.length > 0 && props.match.path === historyStore.state[0].path) {
				let historyCopy = [...historyStore.state];
				historyCopy[0] = {
					...props.match,
					...props.location,
				};
				historyStore.dispatch({
					type: "UPDATE_HISTORY",
					payload: historyCopy,
				});
			} else {
				historyStore.dispatch({
					type: "PUSH_HISTORY",
					payload: {
						...props.match,
						...props.location,
					},
				});
			}
			historyStore.dispatch({
				type: "UPDATE_CURRENT",
				payload: {
					match: props.match,
					location: props.location,
				},
			});
		}
	}, [props.match]);

	//open candidate profile based on query
	useEffect(() => {
		if (props.location) {
			let query = new URLSearchParams(props.location.search);
			let candidate_profile = query.get("candidate_profile");
			if (candidate_profile) {
				setActiveModal("candidate-mini-profile");
				setTnProfileId(candidate_profile);
			}
		}
	}, [props.location]);

	return (
		<>
			{redirect && <Redirect to={redirect} />}
			{props.children}
			{activeModal === "candidate-mini-profile" && tnProfileId && store.company && store.role && (
				<Suspense fallback={<Fallback modal={true} />}>
					<CandidateMiniProfile
						hide={() => {
							setActiveModal(undefined);
							setTnProfileId(undefined);
						}}
						tnProfileId={tnProfileId}
					/>
				</Suspense>
			)}
		</>
	);
};

export default InnerPage;
