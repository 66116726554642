import React, { useState, useEffect, useMemo } from "react";
// import { useLocation } from "react-router-dom";
import useGlobalContext from "contexts/globalContext/GlobalContext";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { checkLastCompany } from "contexts/globalContext/GlobalMethods";
import { ROUTES } from "routes";
import { WIDTHS, COLORS } from "constants/style";
import { BREAKPOINTS } from "constants/breakpoints";
import ElasticSearch from "containers/ElasticSearch/ElasticSearch";
import UserDropMenu from "components/AuthHeader/UserMenu";

import Spinner from "sharedComponents/Spinner";
// import HeaderSearch from "sharedComponents/layout/HeaderSearch";
import { AWS_CDN_URL } from "constants/api";
// import Logo from "assets/imgs/hirebuddy_smiley_blue.png";
// import { SantaBtn } from "sharedComponents/Snow/Snow";

const AuthHeader = () => {
	const store = useGlobalContext();
	// const location = useLocation();
	const [atsLink, setAtsLink] = useState(ROUTES.MyCompanies.url());
	const searchUrl = useMemo(
		() =>
			ROUTES.ElasticSearch.url(store?.company?.mention_tag || store.allMyCompanies[0]?.mention_tag),
		[store?.company, store.allMyCompanies]
	);
	const [elasticSearchActive, setElasticSearchActive] = useState(false);
	const changeElasticSearchState = (state) => () => setElasticSearchActive(state);

	useEffect(() => {
		if (store.allMyCompanies) {
			if (!store.allMyCompanies.length) {
				setAtsLink(ROUTES.MyCompanies.url());
			} else if (store.allMyCompanies.length === 1) {
				setAtsLink(ROUTES.CompanyDashboard.url(store.allMyCompanies[0].mention_tag));
			} else {
				let lastCompanyId = checkLastCompany();
				let companyMatch;
				store.allMyCompanies.map((comp) =>
					comp.id === lastCompanyId ? (companyMatch = comp.mention_tag) : null
				);
				setAtsLink(
					ROUTES.CompanyDashboard.url(companyMatch || store.allMyCompanies[0].mention_tag)
				);
			}
		} else if (atsLink !== ROUTES.MyCompanies.url()) {
			setAtsLink(ROUTES.MyCompanies.url());
		}
	}, [store.allMyCompanies]);

	return (
		<>
			<Header>
				<HeaderPositioning>
					<HeaderWrapper className="leo-flex">
						<MobilePlaceholder />
						<HeaderLogo className="leo-flex">
							<Link to={atsLink} className="leo-mt-5">
								{/*}<img
                  src={`${AWS_CDN_URL}/icons/BrandLogo.svg`}
                  alt="BrandLogo"
                />*/}
								<picture>
									<source
										srcSet={`${AWS_CDN_URL}/logos/plac-d-gradient-dots.png`}
										media={BREAKPOINTS.for_phone_only}
									/>
									{/* <img src={`${AWS_CDN_URL}/icons/Logo.svg`} alt="BrandLogo" className="leo-mb-5" /> */}

									<img src={`${AWS_CDN_URL}/logos/plac-d-gradient-dots.png`} alt="BrandLogo" />
									{/*}<img
											src={`${AWS_CDN_URL}/leo-logo-color.svg`}
											alt="BrandLogo"
											className="leo-mb-5"
										/>*/}
								</picture>
							</Link>
						</HeaderLogo>
						<SearchLink className="leo-flex" to={searchUrl}>
							<img src={`${AWS_CDN_URL}/icons/ElasticMagnifier.svg`} alt="" />
							<SearchInput placeholder="Power Search" readOnly />
						</SearchLink>
						<HeaderMenu className="leo-flex">
							{store.session && store.user && (
								<>
									{/*<HeaderNotifications
                      userId={store.session.id}
                      session={store.session}
                    />*/}
									{/* {store.user && store.user.user_status !== "private" && (
                <UserProfileLink
                  name={store.user.name}
                  username={store.user.username}
                  avatarUrl={store.user.avatar_url}
                />
              )} */}
									{/* keeping this for next year */}
									{/* <SantaBtn store={store}/> */}
									<UserDropMenu
										store={store}
										companyMentionTag={store.company?.mention_tag}
										avatarUrl={store.user.avatar_url}
										name={store.user.name}
										username={store.user.username}
										ownedCompanies={store.allMyCompanies}
									/>
								</>
							)}
						</HeaderMenu>
					</HeaderWrapper>
				</HeaderPositioning>
			</Header>
			<HeaderPlaceholder />
			{!store.session && !store.user && <Spinner />}
			{elasticSearchActive && <ElasticSearch changeElasticSearchState={changeElasticSearchState} />}
		</>
	);
};

export default AuthHeader;

const Header = styled.header`
	background: ${COLORS.white};
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 10;
	border-bottom: 1px solid #dfe1e6;
	box-shadow: 0px 1px 4px rgba(223, 225, 230, 0.5);
	height: ${WIDTHS.top_navbar};
	display: flex;

	@media ${BREAKPOINTS.for_phone_only} {
		/* background: ${COLORS.primary_theme_blue}; */
	}
`;

const HeaderWrapper = styled.div`
	align-items: center;
	height: ${WIDTHS.top_navbar};
	justify-content: space-between;
`;

const HeaderLogo = styled.div`
	align-items: center;

	img {
		height: 50px;
		width: 50px;
	}
`;

const HeaderMenu = styled.div`
	align-items: center;
`;

const SearchLink = styled(Link)`
	margin-left: 25px;
	align-items: center;

	@media ${BREAKPOINTS.for_phone_only} {
		display: none;
	}

	img {
		height: 12px;
		width: 12px;
	}

	&:hover,
	&:active {
		text-decoration: none;
	}
`;

const SearchInput = styled.input`
	background: none;
	border: none;
	margin-left: 9px;
	font-size: 14px;
	width: 350px;
	color: ${COLORS.white};
	height: 16px;
`;

const HeaderPlaceholder = styled.div`
	height: ${WIDTHS.top_navbar};
`;

const HeaderPositioning = styled.div`
	width: 100%;
	margin-left: 150px;
	margin-right: 35px;

	@media ${BREAKPOINTS.for_phone_only} {
		margin-left: 20px;
		margin-right: 20px;
	}
`;

const MobilePlaceholder = styled.div`
	width: 30px;
	display: none;
	@media ${BREAKPOINTS.for_phone_only} {
		display: block;
	}
`;
