import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { permissionChecker } from "constants/permissionHelpers";
import { COLORS } from "constants/style";
// const agencyTabsMap = [
//   "Candidates",
//   "Jobs",
//   "Interviews",
//   "Contacts",
//   "Clients",
//   "Deals",
//   "Meetings",
//   "Emails",
//   "Documents",
// ];
//
// const employerTabsMap = [
//   "Candidates",
//   "Jobs",
//   "Interviews",
//   "Emails",
//   "Documents",
// ];

export const ElasticTabs = ({ activeTab, resultCounts, company, role, historyStore }) => {
	// const tabsMap = company.type === "Agency" ? agencyTabsMap : employerTabsMap;
	const [tabs, setTabs] = useState([]);

	useEffect(() => {
		if (role && company) {
			setTabs([
				...(permissionChecker(role.role_permissions, {
					recruiter: true,
					hiring_manager: true,
				}).view
					? ["Candidates", "Jobs"]
					: []),
				...(permissionChecker(role.role_permissions, { business: true }).view &&
				company.type === "Agency"
					? ["Contacts", "Clients", "Deals"]
					: []),
				// "Documents",
			]);
		}
	}, [role, company]);

	return (
		<Tabs className="leo-flex">
			<TabButton
				className={(activeTab === "_all" ? "active" : "") + " leo-flex-center"}
				key={`all-tab`}
				to={`/${company?.mention_tag}/search${historyStore.current?.location?.search || ""}`}
			>
				All
			</TabButton>
			{tabs.map((tab) => (
				<Tab
					to={`/${company?.mention_tag}/search/${tab?.toLowerCase()}${
						historyStore.current?.location?.search || ""
					}`}
					key={`${tab}-tab`}
					className={(tab.toLowerCase() === activeTab ? "active" : "") + " leo-flex-center"}
				>
					{tab}
					<span className="count">{resultCounts?.[tab?.toLowerCase()] ?? 0}</span>
				</Tab>
			))}
		</Tabs>
	);
};

const Tabs = styled.ul`
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #f2f4f5;
	height: 50px;
	width: 100%;
`;

const TabButton = styled(Link)`
	background-color: transparent;
	border-right: 1px solid ${COLORS.suplement_dark_grey};
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: ${COLORS.text_black};
	margin: 0;
	margin-right: 25px;
	padding: 0;
	padding-right: 25px;
	transition: opacity ease-in-out 0.25s;

	&:hover {
		background-color: transparent !important;
		color: ${COLORS.text_black} !important;
		text-decoration: none;
	}

	&.active {
		opacity: 1;
	}
`;

const Tab = styled(Link)`
	background-color: transparent;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: ${COLORS.text_black};
	padding: 0;
	margin: 0;
	opacity: 0.5;
	transition: opacity ease-in-out 0.25s;

	&:hover {
		background-color: transparent !important;
		color: ${COLORS.text_black} !important;
		text-decoration: none;
	}

	&.active {
		opacity: 1;
	}

	:not(:last-child) {
		margin-right: 25px;
	}

	.count {
		background: rgba(176, 189, 202, 0.25);
		border-radius: 2px;
		font-size: 10px;
		font-weight: 700;
		margin-left: 3px;
		min-width: 17px;
		padding: 2px;
		text-align: center;
	}

	&.active {
		color: 	color: ${COLORS.text_black} !important;

		.count {
			// background: #24313e;
		}
	}
`;
