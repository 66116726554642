import React, { useState, useEffect } from "react";
import useGlobalContext from "contexts/globalContext/GlobalContext";
import { Link } from "react-router-dom";
import AvatarIcon from "sharedComponents/AvatarIcon";
import styled from "styled-components";
import spacetime from "spacetime";
import { createJobSlug } from "helpersV2/jobs";
import { ROUTES } from "routes";
import { COLORS } from "constants/style";

export const ResultBlock = ({ resultSource, result }) => {
	const store = useGlobalContext();
	const [resultLimit, setResultLimit] = useState(4);
	// Name of the props that needs to be taken out from 'match' obj
	const [matchHeader, setMatchHeader] = useState([]);
	const [matchFooter, setMatchFooter] = useState([]);
	const [profileLink, setProfileLink] = useState(null);

	const changeResultLimit = (val) => () => setResultLimit(val);

	useEffect(() => {
		// GENERATE PROP NAMES FOR RESULT ITEM INFO
		((resultSource) => {
			switch (resultSource) {
				case "candidates":
					setMatchHeader(["name"]);
					setProfileLink(() => (companyMentionTag, id) =>
						ROUTES.CandidateProfile.url(companyMentionTag, id)
					);
					return setMatchFooter(["title", "locations"]);
				case "contacts":
					setMatchHeader(["name", "company_name"]);
					setProfileLink(() => (companyMentionTag, id) =>
						ROUTES.ContactProfile.url(companyMentionTag, id)
					);
					return setMatchFooter(["email", "number"]);
				case "clients":
					setMatchHeader(["name"]);
					setProfileLink(() => (companyMentionTag, id) =>
						ROUTES.ClientProfile.url(companyMentionTag, id)
					);
					return setMatchFooter(["industries", "locations"]);
				case "deals":
					setMatchHeader(["title"]);
					setProfileLink(() => (companyMentionTag, id) =>
						ROUTES.DealProfile.url(companyMentionTag, id)
					);
					return setMatchFooter(["pipeline", "stage", "value"]);
				case "jobs":
					setMatchHeader(["title"]);
					setProfileLink(() => (companyMentionTag, jobSlug) =>
						ROUTES.JobDashboard.url(companyMentionTag, jobSlug)
					);
					return setMatchFooter(["company_name", "client_name"]);
				// case "interviews":
				// 	setMatchHeader(["name"]);
				// 	return setMatchFooter(["start", "end", "job", "company"]);
				// case "meetings":
				// 	setMatchHeader(["name"]);
				// 	return setMatchFooter(["start", "end", "source", "company"]);
				// case "emails":
				// 	setMatchHeader(["subject"]);
				// 	return setMatchFooter(["receiver", "sent_at"]);
				// case "documents":
				// 	setMatchHeader(["title"]);
				// 	return setMatchFooter(["doc_for"]);
				default:
					setMatchHeader([]);
					return setMatchFooter([]);
			}
		})(resultSource);
	}, [resultSource]);

	return (
		<StyledBlock>
			<Heading className="leo-mb-20">{resultSource.replace("_", " ")}</Heading>
			<div>
				{result[0]?.slice(0, resultLimit).map((match, index) => {
					let profileUrl = "";
					if (resultSource === "jobs") {
						profileUrl = profileLink?.(
							store.company.mention_tag,
							createJobSlug(match._source.title, match._id)
						);
					} else if (resultSource === "candidates") {
						profileUrl = profileLink?.(store.company.mention_tag, match._source.professional_id);
					} else if (resultSource === "clients") {
						profileUrl = profileLink?.(store.company.mention_tag, match._source.employer_id);
					} else {
						profileUrl = profileLink?.(store.company.mention_tag, match._id);
					}

					return (
						<Match key={`${resultSource}-match-#${index++}`} className="leo-flex leo-relative">
							<AvatarIcon imgUrl={null} name={match._source[matchHeader[0]]} shape={"circle"} size={30} />
							<Info className="leo-flex leo-align-start leo-justify-between">
								<Link to={profileUrl}>
									<h3>{matchHeader.map((prop) => match._source[prop]).join(" ")}</h3>
								</Link>
								<span>
									{matchFooter
										.map((prop) => {
											if (prop === "salary" || prop === "value") {
												return undefined;
											}
											if (prop === "applicants" || prop === "hired") {
												let source = prop.charAt(0).toUpperCase() + prop.slice(1);
												return `${source} ${match._source[prop]}`;
											}
											if ((prop === "start" || prop === "end") && !!match._source[prop]) {
												let date = spacetime(match._source[prop]);
												return date.format("{date} {month-short} {time}");
											}

											return match._source[prop];
										})
										.filter((val) => !!val)
										.join(" - ")}
								</span>
								{resultSource === "deals" && !!match._source.value && <Currency>{match._source.value}</Currency>}
							</Info>
						</Match>
					);
				})}
			</div>
			{result[0]?.length > 4 ? (
				resultLimit !== -1 ? (
					<Button onClick={changeResultLimit(-1)}>View All</Button>
				) : (
					<Button onClick={changeResultLimit(4)}>Collapse</Button>
				)
			) : null}
		</StyledBlock>
	);
};

const StyledBlock = styled.div``;

const Heading = styled.h3`
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: ${COLORS.text_black};
`;

const Button = styled.button`
	color: ${COLORS.white};
	font-size: 12px;

	&:hover {
		text-decoration: underline;
	}
`;

const Match = styled.div`
	border-bottom: 1px solid rgba(255, 255, 255, 0.25);
	margin: 10px 0;
	padding-bottom: 10px;
`;

const Info = styled.div`
	flex-direction: column;
	margin-left: 10px;

	h3 {
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: ${COLORS.text_black};

		&:hover {
			text-decoration: underline;
		}
	}

	span {
		font-size: 12px;
		line-height: 15px;
		color: ${COLORS.text_medium_grey};
	}
`;

const Currency = styled.div`
	background: ${COLORS.suplement_light_grey};
	border-radius: 4px;
	height: 20px;
	position: absolute;
	top: 20%;
	right: 0;
	width: 55px;

	color: ${COLORS.primary_theme_blue};
	font-size: 10px;
	font-weight: 500;
	letter-spacing: 0.05em;
	text-align: center;
`;
