import { useReducer } from "react";
import { reducer, initialState } from "./calendarReducer";

import { createContainer } from "react-tracked";

const useValue = () => useReducer(reducer, initialState);
export const {
	Provider: CalendarContextProvider,
	useTrackedState: useStore,
	useUpdate: useDispatch,
} = createContainer(useValue);

export const useCalendarContext = () => {
	const state = useStore();
	const dispatch = useDispatch();

	return { state, dispatch };
};
