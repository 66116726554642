import React from "react";
import ActionSuccessfulModal from "./ActionSuccessfulModal";

export default function SubscriptionReactivatedModal(props) {
	return (
		<ActionSuccessfulModal
			{...props}
			title="You are a PRO!"
			copy="Thank you, your subscription has been successfully reactivated."
		/>
	);
}
