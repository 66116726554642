import React from "react";
import retryLazy from "hooks/retryLazy";
import RouteConstructor from "routes/RouteConstructor";
const MyCompanies = React.lazy(() => retryLazy(() => import("containers/MyCompanies")));
const CompanyDashboard = React.lazy(() => retryLazy(() => import("containers/CompanyDashboard")));
const TalentNetwork = React.lazy(() => retryLazy(() => import("containers/TalentNetwork")));
const ViewJobs = React.lazy(() => retryLazy(() => import("containers/ViewJobs")));
const Vendors = React.lazy(() => retryLazy(() => import("containers/Vendors")));
const Calendar = React.lazy(() => retryLazy(() => import("containers/Calendar")));
const Analytics = React.lazy(() => retryLazy(() => import("containers/Analytics/Analytics")));
const TeamView = React.lazy(() => retryLazy(() => import("containers/TeamView")));
const ClientManager = React.lazy(() => retryLazy(() => import("containers/ClientManager")));
const TasksManager = React.lazy(() => retryLazy(() => import("containers/TasksManager")));
const ElasticSearch = React.lazy(() => retryLazy(() => import("containers/ElasticSearch")));
const MarketingEmails = React.lazy(() => retryLazy(() => import("containers/MarketingEmails")));
const JobCreation = React.lazy(() => retryLazy(() => import("containers/JobCreation")));
const TempManager = React.lazy(() => retryLazy(() => import("containers/TempManager")));
const InvoiceCreation = React.lazy(() => retryLazy(() => import("containers/InvoiceCreation")));
const VideoInterviewContainer = React.lazy(() =>
	retryLazy(() => import("containers/VideoInterview/VideoInterviewContainer"))
);
const VideoInterviewReview = React.lazy(() =>
	retryLazy(() => import("containers/ReviewVideoInterview"))
);

const role_permissions = {
	marketer: false,
	hiring_manager: false,
	recruiter: false,
	business: false,
};

const all_role_permissions = {
	marketer: true,
	hiring_manager: true,
	recruiter: true,
	business: true,
	all: true,
};

const subscriptionViews = {
	free: true,
	active: true,
	trial: true,
	starter: true,
	cancelled: false,
	incomplete: false,
	incomplete_expired: false,
	past_due: false,
	unpaid: false,
	lifetime: true,
};

const allSubscriptionViews = {
	free: true,
	active: true,
	trial: true,
	starter: true,
	cancelled: true,
	incomplete: true,
	incomplete_expired: true,
	past_due: true,
	unpaid: true,
	lifetime: true,
};

const ATS_BASE_ROUTES = {
	MyCompanies: {
		path: "/companies",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="companies"
				routeObject={ATS_BASE_ROUTES.MyCompanies}
				{...args}
			>
				<MyCompanies {...args} />
			</RouteConstructor>
		),
		url: () => "/companies",
		role_permissions: { ...all_role_permissions },
		subscriptions_view: { ...allSubscriptionViews },
	},
	ViewJobs: {
		path: "/:companyMentionTag/jobs",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="jobs"
				routeObject={ATS_BASE_ROUTES.ViewJobs}
				{...args}
			>
				<ViewJobs {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag) => `/${companyMentionTag}/jobs`,
		role_permissions: {
			...role_permissions,
			recruiter: true,
			hiring_manager: true,
		},
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	Calendar: {
		path: "/:companyMentionTag/calendar",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="calendar"
				routeObject={ATS_BASE_ROUTES.Calendar}
				{...args}
			>
				<Calendar {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag) => `/${companyMentionTag}/calendar`,
		role_permissions: {
			...role_permissions,
			recruiter: true,
			business: true,
		},
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	TeamView: {
		path: "/:companyMentionTag/settings/:tab?",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="settings"
				routeObject={ATS_BASE_ROUTES.TeamView}
				{...args}
			>
				<TeamView {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, tab, query) =>
			`/${companyMentionTag}/settings/${tab || "company"}${query ? `${query}` : ""}`,
		role_permissions: { ...all_role_permissions },
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	Analytics: {
		path: "/:companyMentionTag/analytics/:tab?",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="analytics"
				routeObject={ATS_BASE_ROUTES.Analytics}
				{...args}
			>
				<Analytics {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, tab) => `/${companyMentionTag}/analytics/${tab || ""}`,
		role_permissions: { ...role_permissions, recruiter: true, business: true },
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	Vendors: {
		path: "/:companyMentionTag/vendors/:tab?",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="vendors"
				routeObject={ATS_BASE_ROUTES.Vendors}
				{...args}
			>
				<Vendors {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, tab) => `/${companyMentionTag}/vendors/${tab || "active"}`,
		role_permissions: { ...role_permissions },
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	ClientManager: {
		path: "/:companyMentionTag/clients/:tab?",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="clients"
				routeObject={ATS_BASE_ROUTES.ClientManager}
				{...args}
			>
				<ClientManager {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, tab, query) =>
			`/${companyMentionTag}/clients/${tab || "deals"}${query ? `${query}` : ""}`,
		role_permissions: { ...role_permissions, business: true },
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	MarketingEmails: {
		path: "/:companyMentionTag/marketing/:tab?",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="marketing"
				routeObject={ATS_BASE_ROUTES.MarketingEmails}
				{...args}
			>
				<MarketingEmails {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, tab) => `/${companyMentionTag}/marketing/${tab || "overview"}`,
		role_permissions: { ...role_permissions, marketer: true },
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: false },
	},
	TalentNetwork: {
		path: "/:companyMentionTag/talent",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="TalentNetwork"
				routeObject={ATS_BASE_ROUTES.TalentNetwork}
				{...args}
			>
				<TalentNetwork {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag) => `/${companyMentionTag}/talent`,
		role_permissions: {
			...role_permissions,
			recruiter: true,
			hiring_manager: true,
		},
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	CompanyDashboard: {
		path: "/:companyMentionTag/dashboard",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="dashboard"
				routeObject={ATS_BASE_ROUTES.CompanyDashboard}
				{...args}
			>
				<CompanyDashboard {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag) => `/${companyMentionTag}/dashboard`,
		role_permissions: { ...all_role_permissions },
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	CompanyTasksManager: {
		path: "/:companyMentionTag/company-tasks/:complete?",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="company-tasks"
				routeObject={ATS_BASE_ROUTES.CompanyTasksManager}
				{...args}
			>
				<TasksManager {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, complete) =>
			`/${companyMentionTag}/company-tasks/${complete || "incomplete"}`,
		role_permissions: {},
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	ElasticSearch: {
		path: "/:companyMentionTag/search/:tab?",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="search"
				routeObject={ATS_BASE_ROUTES.ElasticSearch}
				{...args}
			>
				<ElasticSearch {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, tab = "", query) =>
			`/${companyMentionTag}/search${tab ? "/" + tab : ""}${query ? query : ""}`,
		role_permissions: { ...all_role_permissions },
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	InvoiceCreation: {
		path: "/:companyMentionTag/temp/create-invoice",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="create invoice"
				routeObject={ATS_BASE_ROUTES.InvoiceCreation}
				{...args}
			>
				<InvoiceCreation {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, query) =>
			`/${companyMentionTag}/temp/create-invoice${query ? `${query}` : ""}`,
		role_permissions: {
			...role_permissions,
			recruiter: true,
		},
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	EditInvoice: {
		path: "/:companyMentionTag/temp/edit-invoice/:invoice_id",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="edit invoice"
				routeObject={ATS_BASE_ROUTES.InvoiceCreation}
				{...args}
			>
				<InvoiceCreation {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, invoiceId, query) =>
			`/${companyMentionTag}/temp/edit-invoice/${invoiceId}${query ? `${query}` : ""}`,
		role_permissions: {
			...role_permissions,
			recruiter: true,
		},
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	ViewInvoice: {
		path: "/:companyMentionTag/temp/view-invoice/:invoice_id",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="view invoice"
				routeObject={ATS_BASE_ROUTES.ViewInvoice}
				{...args}
			>
				<InvoiceCreation {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, invoiceId, query) =>
			`/${companyMentionTag}/temp/view-invoice/${invoiceId}${query ? `${query}` : ""}`,
		role_permissions: {
			...role_permissions,
			recruiter: true,
		},
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	TempManager: {
		path: "/:companyMentionTag/workforce/:tab?",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="workforce"
				routeObject={ATS_BASE_ROUTES.TempManager}
				{...args}
			>
				<TempManager {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, tab) => `/${companyMentionTag}/workforce/${tab || "dashboard"}`,
		role_permissions: { ...role_permissions, recruiter: true },
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	JobCreation: {
		path: "/:companyMentionTag/create-job",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="create job"
				routeObject={ATS_BASE_ROUTES.JobCreation}
				{...args}
			>
				<JobCreation {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, query) => `/${companyMentionTag}/create-job${query ? `${query}` : ""}`,
		role_permissions: {
			...role_permissions,
			recruiter: true,
			hiring_manager: true,
		},
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	JobEdit: {
		path: "/:companyMentionTag/edit-job/:job_slug",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="edit job"
				routeObject={ATS_BASE_ROUTES.JobEdit}
				{...args}
			>
				<JobCreation {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, jobSlug) => `/${companyMentionTag}/edit-job/${jobSlug}`,
		role_permissions: {
			...role_permissions,
			recruiter: true,
			hiring_manager: true,
		},
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	VideoInterview: {
		path: "/:companyMentionTag/jobs/video-interviews/create",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="interview-manager"
				routeObject={ATS_BASE_ROUTES.VideoInterview}
				{...args}
			>
				<VideoInterviewContainer {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, query) =>
			`/${companyMentionTag}/jobs/video-interviews/create${query ? query : ""}`,
		role_permissions: {
			...role_permissions,
			recruiter: true,
		},
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true },
	},
	JobVideoInterview: {
		path: "/:companyMentionTag/jobs/:jobSlug/video-interviews/create",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab="interview-manager"
				routeObject={ATS_BASE_ROUTES.JobVideoInterview}
				{...args}
			>
				<VideoInterviewContainer {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, jobSlug, query) =>
			`/${companyMentionTag}/jobs/${jobSlug}/video-interviews/create${query ? query : ""}`,
		role_permissions: {
			...role_permissions,
			recruiter: true,
		},
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true },
	},
	VideoInterviewReview: {
		path: "/:companyMentionTag/video-interview/:requestId/review",
		render: (args) => (
			<RouteConstructor inner={true} routeObject={ATS_BASE_ROUTES.VideoInterviewReview} {...args}>
				<VideoInterviewReview {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, requestId) =>
			`/${companyMentionTag}/video-interview/${requestId}/review`,
		role_permissions: { ...role_permissions, recruiter: true, hiring_manager: true },
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
};

export default ATS_BASE_ROUTES;
