import React from "react";
import styled from "styled-components";
import { COLORS } from "constants/style";
import useDropdown from "hooks/useDropdown";
import { BREAKPOINTS } from "constants/breakpoints";

export const PageContainer = styled.div`
	height: 100vh;
	width: 100vw;
	max-height: 100vh;
	max-width: 100vw;
	background: ${COLORS.primary_theme_blue};
	background: linear-gradient(137.42deg, #000000 -16.77%, #2a3744 50.1%, #000000 98.9%);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 40px 50px;
	padding-top: 80px;
`;

export const Input = styled.input`
	border: 1px solid #d4dfea;
	border-radius: 4px;
	padding: 7px 10px;
	font-size: 14px;
	width: 100%;
`;

export const Label = styled.label`
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	color: ${COLORS.primary_theme_blue};
	margin-bottom: 13px;
	display: block;

	@media ${BREAKPOINTS.for_phone_only} {
		font-size: 10px;
		margin-bottom: 10px;
	}
`;

export const ProgressButton = styled.button`
	background: ${COLORS.primary_theme_blue};
	border: solid 1px ${COLORS.primary_theme_blue};
	border-radius: 25.5px;
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	color: #ffffff;
	padding: 15px;
	width: 135px;
	margin: auto;
	margin-top: 50px;
	display: block;

	@media ${BREAKPOINTS.for_small_desktop_height} {
		margin-top: 30px;
	}

	@media ${BREAKPOINTS.for_phone_only} {
		margin-top: 30px;
		font-size: 12px;
		width: 100px;
		padding: 10px;
	}

	&.no-margin {
		margin: 0;
	}
`;

export const BackButton = styled(ProgressButton)`
	background: white;
	border: solid 1px ${COLORS.primary_theme_blue};
	color: ${COLORS.primary_theme_blue};
`;

export const SocialButton = styled.button`
	border: 1px solid #d4dfea;
	border-radius: 14px;
	font-weight: 600;
	line-height: 19px;
	color: ${COLORS.primary_theme_blue};
	text-align: left;
	padding: 9px 10px;
	font-size: 12px;
	text-align: center;

	img {
		height: 20px;
	}

	span {
		display: inline;
		margin-left: 5px;
	}

	@media ${BREAKPOINTS.for_med_desktop_up} {
		padding: 9px 29px;
		font-size: 14px;

		span {
			margin-left: 20px;
		}
	}
`;

export const OrSeparator = styled.div`
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: #d4dfea;
	margin-top: 50px;
	span {
		background: white;
		padding-left: 5px;
		padding-right: 5px;
		z-index: 1;
	}

	@media ${BREAKPOINTS.for_small_desktop_height} {
		margin-top: 20px;
	}

	&:after {
		content: "";
		height: 1px;
		width: 100%;
		background: #d4dfea;
		position: absolute;
	}
`;

export const SelectType = ({ value, onChange, disabled }) => {
	const { node, showSelect, setShowSelect } = useDropdown();

	return (
		<div className="leo-relative" ref={node}>
			<FalseInput
				className="leo-flex-center-between"
				onClick={() => (!disabled ? setShowSelect(!showSelect) : undefined)}
			>
				<span>{value || ""}</span>
				{!disabled && <i className="fas fa-angle-down"></i>}
			</FalseInput>
			{showSelect && (
				<DropDownWrapper>
					<SelectOption
						className="leo-flex-center"
						onClick={() => {
							onChange("Employer");
							setShowSelect(false);
						}}
					>
						Employer
					</SelectOption>
					<SelectOption
						className="leo-flex-center"
						onClick={() => {
							onChange("Agency");
							setShowSelect(false);
						}}
					>
						Recruitment Agency
					</SelectOption>
				</DropDownWrapper>
			)}
		</div>
	);
};

const FalseInput = styled.button`
	border: 1px solid #d4dfea;
	border-radius: 4px;
	padding: 7px 10px;
	font-size: 14px;
	width: 100%;
	min-height: 35.6px;
`;

const DropDownWrapper = styled.div`
	background: #ffffff;
	border-radius: 4px;
	box-shadow: rgba(116, 118, 123, 0.17) 0px 1px 6px;
	position: absolute;
	width: 100%;
	top: 40px;
	border-radius: 0px 0px 4px 4px;
	z-index: 1;
`;
const SelectOption = styled.button`
	height: 52px;
	padding-left: 38px;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	color: #1e1e1e;
	transition: all 200ms;
	width: 100%;

	&:hover {
		background: rgba(212, 223, 234, 0.45);
	}
`;

export const RequiredSup = styled.sup`
	font-size: 9px;
	color: ${COLORS.primary_red};
`;
