import React, { useState } from "react";
import { Label, ProgressButton, Input, BackButton } from "components/CompanySignup/components.sc";
import styled from "styled-components";
import notify from "notifications";
import {
	Elements,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_API_KEY } from "constants/api";
import { BREAKPOINTS } from "constants/breakpoints";
import { useTranslation } from "react-i18next";
// import TermsAndConditionsModal from "modals/TermsAndConditionsModal/TermsAndConditionsModal";
// import Fallback from "styles/Fallback";

const stripePromise = loadStripe(STRIPE_API_KEY);

const Wrapper = (props) => {
	return (
		<Elements stripe={stripePromise}>
			<BankDetailsStage {...props} />
		</Elements>
	);
};

const BankDetailsStage = ({ setStage, form, setPaymentMethod }) => {
	const { t } = useTranslation()
	const stripe = useStripe();
	const elements = useElements();
	const [billingName, setBillingName] = useState("");
	// const [termModal, setTermModal] = useState("termsAndconditions");

	const handleNext = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			notify("danger", t("statusMessage.theresBeenAnIssue"));
			return;
		}

		// Get a reference to a mounted CardElement. Elements knows how
		// to find your CardElement because there can only ever be one of
		// each type of element.
		const cardElement = elements.getElement(CardNumberElement);

		// Use your card Element with other Stripe.js APIs
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: "card",
			card: cardElement,
			billing_details: {
				name: billingName || form.name,
			},
		});
		if (error) {
			notify("danger", error.message);
		} else {
			setStage("terms_and_conditions");
			setPaymentMethod(paymentMethod);
		}
	};

	return (
		<>
			<Container>
				<div className="leo-mb-30">
					<Label>Name</Label>
					<Input value={billingName} onChange={(e) => setBillingName(e.target.value)} />
				</div>
				<div className="leo-mb-30">
					<Label>Card Number</Label>
					<CardNumberElement className="bank-details-input" />
				</div>
				<InputsGrid className="leo-mb-30">
					<div>
						<Label>Expire Date</Label>
						<CardExpiryElement className="bank-details-input" />
					</div>
					<div>
						<Label>Card Code (CVC)</Label>
						<CardCvcElement className="bank-details-input" />
					</div>
				</InputsGrid>
				<div className="leo-flex-center-center leo-mt-50">
					<BackButton
						className="no-margin"
						style={{ marginRight: "10px" }}
						onClick={() => setStage("company")}
					>
						<i className="fas fa-angle-left leo-mr-10"></i> Back
					</BackButton>
					<ProgressButton onClick={handleNext} className="no-margin" style={{ marginLeft: "10px" }}>
						Next
					</ProgressButton>
				</div>
				<Info>{`You'll be charged after the 2 FREE weeks `}</Info>
			</Container>
			{/* {termModal === "termsAndconditions" && (
				<Suspense fallback={<Fallback modal={true} />}>
					<TermsAndConditionsModal
						show={true}
						hide={() => {
							setTermModal(undefined);
						}}
					/>
				</Suspense>
			)} */}
		</>
	);
};

const Container = styled.div`
	.bank-details-input {
		border: 1px solid #d4dfea;
		border-radius: 4px;
		padding: 7px 10px;
		font-size: 14px;
		width: 100%;
	}
`;

const InputsGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 15px;
`;

const Info = styled.div`
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	color: #1e1e1e;
	margin-top: 13px;

	@media ${BREAKPOINTS.for_phone_only} {
		font-size: 10px;
	}
`;

export default Wrapper;
