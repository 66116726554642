import React, { useState } from "react";
import { COLORS } from "constants/style";
import { MAX_CREATIONS_FREE } from "constants/subscription";
import {
	openSubscriptionModal,
	refreshSubscriptionData,
} from "contexts/globalContext/GlobalMethods";
import useDropdown from "hooks/useDropdown";
import styled from "styled-components";
import { InfinityIcon } from "assets/svg/icons/Infinity";
import spacetime from "spacetime";
import { reactivateSubscription } from "helpersV2/subscriptions";
import SubscriptionReactivatedModal from "components/TeamView/CompanyPackageTab/SubscriptionReactivatedModal";
import { Link } from "react-router-dom";
import { ROUTES } from "routes";

const PlanInfo = ({ store }) => {
	const { node, showSelect, setShowSelect } = useDropdown();
	const [activeModal, setActiveModal] = useState(undefined);

	const handleReactivatePlan = async () => {
		await reactivateSubscription(store.subscription_settings.id);
		refreshSubscriptionData(store);
		setActiveModal("subscription-reactivated");
	};

	return (
		<div className="leo-relative leo-mr-15 leo-flex-center-between" ref={node}>
			<div className="leo-flex-center-between">
				{/* {!store.subscription_settings?.is_pro ? (
					<Button onClick={() => setShowSelect(!showSelect)} className="leo-flex-center">
						free
					</Button>
				) : ( */}
				{store.subscription_settings?.status === "lifetime" &&
					!store.subscription_settings?.legacy ? (
					<Link
						to={ROUTES.TeamView.url(store.company?.mention_tag, "package")}
						style={{ textDecoration: "none" }}
					>
						<Button
							className={`leo-flex-center  ${store.subscription_settings?.status === "cancelled" ? "cancelled" : "pro"
								}`}>
							<InfinityIcon />
							Lifetime
						</Button>
					</Link>
				) : store.subscription_settings?.status === "active" ? (
					<Link
						to={ROUTES.TeamView.url(store.company?.mention_tag, "package")}
						style={{ textDecoration: "none" }}
					>
						<Button className={`leo-flex-center  ${store.subscription_settings?.status === "cancelled" ? "cancelled" : "pro"
							}`}>
							<UpgradeIcon />
							Pro
						</Button>
					</Link>
				) : store.subscription_settings?.status === "trial" ? (
					<Link
						to={ROUTES.TeamView.url(store.company?.mention_tag, "package")}
						style={{ textDecoration: "none" }}
					>
						<Button className={`leo-flex-center  ${store.subscription_settings?.status === "cancelled" ? "cancelled" : "pro"
							}`}>Trial</Button>
					</Link>
				) : store.subscription_settings?.status === "starter" ? (
					<Button onClick={() => setShowSelect(!showSelect)} className={`leo-flex-center  ${store.subscription_settings?.status === "cancelled" ? "cancelled" : "pro"
						}`}>Starter</Button>
				) : (
					<Link
						to={ROUTES.TeamView.url(store.company?.mention_tag, "package")}
						style={{ textDecoration: "none" }}
					>
						<Button className={`leo-flex-center cancelled`}>Cancelled</Button>
					</Link>
				)}
				{/* )} */}
				{["trial", "starter"].includes(store.subscription_settings?.status) && (
					<HeaderCTA onClick={() => openSubscriptionModal(store)}>
						<span>Upgrade to</span>
						<UpgradeIcon />
						<span>Pro</span>
					</HeaderCTA>
				)}
				{store.subscription_settings?.status === "cancelled" &&
					spacetime(store.subscription_settings?.current_period_end).isAfter(spacetime.now()) && (
						<HeaderCTA onClick={handleReactivatePlan}>
							<span>Reactivate</span>
							<UpgradeIcon />
							<span>Pro</span>
						</HeaderCTA>
					)}
				{activeModal === "subscription-reactivated" && (
					<SubscriptionReactivatedModal hide={() => setActiveModal(undefined)} />
				)}
				{showSelect && (
					<Wrapper>
						<Label>Jobs</Label>
						<div className="leo-flex-center-between leo-mb-10">
							<PercentageBar
								percentage={percentage(store.total_creation_numbers?.jobs, MAX_CREATIONS_FREE.jobs)}
								color={COLORS.primary_orange}
								background="rgba(255, 160, 118, 0.2)"
							/>
							<Indicator color={COLORS.primary_orange}>
								{store.total_creation_numbers?.jobs}/{MAX_CREATIONS_FREE.jobs}
							</Indicator>
						</div>
						<Label>Candidates</Label>
						<div className="leo-flex-center-between leo-mb-10">
							<PercentageBar
								percentage={percentage(
									store.total_creation_numbers?.candidates,
									MAX_CREATIONS_FREE.candidates
								)}
								color="#5E67A7"
								background="#DEE1F9"
							/>
							<Indicator color="#5E67A7">
								{store.total_creation_numbers?.candidates}/{MAX_CREATIONS_FREE.candidates}
							</Indicator>
						</div>
						{store.company.type === "Agency" && (
							<>
								<Label>Clients</Label>
								<div className="leo-flex-center-between leo-mb-10">
									<PercentageBar
										percentage={percentage(
											store.total_creation_numbers?.clients,
											MAX_CREATIONS_FREE.clients
										)}
										color="#9C7B1B"
										background="rgba(244, 209, 110, 0.25)"
									/>
									<Indicator color="#9C7B1B">
										{store.total_creation_numbers?.clients}/{MAX_CREATIONS_FREE.clients}
									</Indicator>
								</div>
								<Label>Contacts</Label>
								<div className="leo-flex-center-between leo-mb-10">
									<PercentageBar
										percentage={percentage(
											store.total_creation_numbers?.contacts,
											MAX_CREATIONS_FREE.contacts
										)}
										color={COLORS.primary_blue}
										background="rgba(140, 212, 237, 0.3)"
									/>
									<Indicator color={COLORS.primary_blue}>
										{store.total_creation_numbers?.contacts}/{MAX_CREATIONS_FREE.contacts}
									</Indicator>
								</div>
								<Label>Deals</Label>
								<div className="leo-flex-center-between leo-mb-10">
									<PercentageBar
										percentage={percentage(
											store.total_creation_numbers?.deals,
											MAX_CREATIONS_FREE.deals
										)}
										color={COLORS.primary_green}
										background="rgba(53, 195, 174, 0.1)"
									/>
									<Indicator color={COLORS.primary_green}>
										{store.total_creation_numbers?.deals}/{MAX_CREATIONS_FREE.deals}
									</Indicator>
								</div>
							</>
						)}
						<div className="leo-flex-center-center">
							<ButtonUpgrade onClick={() => openSubscriptionModal(store)}>Upgrade</ButtonUpgrade>
						</div>
					</Wrapper>
				)}
			</div>
		</div>
	);
};

const percentage = (partialValue, totalValue) => {
	return (100 * partialValue) / totalValue;
};

const PercentageBar = ({ percentage, color, background }) => {
	return (
		<BaseBar background={background}>
			<CompletedBar percentage={percentage} color={color} />
		</BaseBar>
	);
};

const BaseBar = styled.div`
	width: 215px;
	position: relative;
	height: 5px;
	background: ${(props) => props.background};
	border-radius: 5px;
`;

const CompletedBar = styled.div`
	border-radius: 10px;
	width: ${(props) => props.percentage}%;
	position: absolute;
	height: 100%;
	background: ${(props) => props.color};
`;

const Button = styled.button`
	color: ${COLORS.primary_green};
	font-weight: 700;

	&.pro {
		color: ${COLORS.primary_theme_blue};
	}

	&.cancelled {
		color: ${COLORS.primary_red};
	}
`;

const Wrapper = styled.div`
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.03);
	border-radius: 0.25rem;
	z-index: 10;
	right: 10%;
	position: absolute;
	width: 300px;
	padding: 25px 10px;
	top: 100%;
`;

const Label = styled.label`
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	color: ${COLORS.primary_theme_blue};
	display: block;
`;

const Indicator = styled.div`
	color: ${(props) => props.color};
	font-size: 11px;
	font-weight: bold;
`;

const HeaderCTA = styled(Button)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: ${COLORS.primary_theme_blue};
	padding: 5px 15px;
	font-size: 14px;
	font-weight: 400;
	border-radius: 25.5px;
	color: #fff;
	margin-left: 10px;
	margin-bottom: 2px;

	& > div {
		margin: 0 5px !important;
	}
`;

const ButtonUpgrade = styled.button`
	margin: auto;
	margin-top: 20px;
	font-size: 12px;
	background: ${COLORS.primary_theme_blue};
	border-radius: 25.5px;
	color: white;
	padding: 5px 15px;
`;

export const UpgradeIcon = () => (
	<UpgradeWrapper>
		<i className="fas fa-trophy"></i>
	</UpgradeWrapper>
);

const UpgradeWrapper = styled.div`
	color: ${COLORS.primary_yellow};
	margin-right: 10px;

	i {
		color: ${COLORS.primary_yellow};
	}
`;
export default PlanInfo;
