import React, { useState, useEffect } from "react";
import useGlobalContext from "contexts/globalContext/GlobalContext";
import useHistoryContext from "contexts/historyContext/HistoryContext";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { checkLastCompany } from "contexts/globalContext/GlobalMethods";
import { ROUTES } from "routes";

const OuterPage = (props) => {
	const store = useGlobalContext();
	const historyStore = useHistoryContext();
	const [redirect, setRedirect] = useState(false);

	useEffect(() => {
		document.body.classList.add("outer");
		return () => document.body.classList.remove("outer");
	}, []);

	//THIS useEffect REDIRECT THE USER TO CHANGE HIS PASSWORD IF IT IS A TEMPORARY PASSWORD
	useEffect(() => {
		if (store.user && store.user.temp_password) {
			setRedirect(ROUTES.NewPassword.url());
		}
	}, [store.user]);

	useEffect(() => {
		if (store.session && store.allMyCompanies && store.user) {
			if (store.allMyCompanies.length === 0) {
				setRedirect(ROUTES.MyCompanies.url());
			} else if (store.allMyCompanies.length === 1) {
				setRedirect(ROUTES.CompanyDashboard.url(store.allMyCompanies[0].mention_tag));
			} else {
				let lastCompanyId = checkLastCompany();
				let companyMatch;
				store.allMyCompanies.map((comp) => {
					if (comp.id === lastCompanyId) {
						companyMatch = comp.mention_tag;
					}
					return null;
				});
				setRedirect(
					ROUTES.CompanyDashboard.url(companyMatch || store.allMyCompanies[0].mention_tag)
				);
			}
		}
	}, [store.session, store.allMyCompanies, store.user]);

	// update history state
	useEffect(() => {
		if (props.match.path) {
			if (historyStore.state.length > 0 && props.match.path === historyStore.state[0].path) {
				let historyCopy = [...historyStore.state];
				historyCopy[0] = {
					...props.match,
					...props.location,
					origin_name: props.originName,
				};
				historyStore.dispatch({
					type: "UPDATE_HISTORY",
					payload: historyCopy,
				});
			} else {
				historyStore.dispatch({
					type: "PUSH_HISTORY",
					payload: {
						...props.match,
						...props.location,
						origin_name: props.originName,
					},
				});
			}
			historyStore.dispatch({
				type: "UPDATE_CURRENT",
				payload: {
					match: props.match,
					location: props.location,
				},
			});
		}
	}, [props.match, props.originName]);

	return (
		<>
			{redirect && <Redirect to={redirect} />}
			{props.children}
		</>
	);
};

export default withRouter(OuterPage);
