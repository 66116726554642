import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "constants/style";
import {
	DashboardIcon,
	CandidatesIcon,
	JobsIcon,
	WorkforceIcon,
	CRMIcon,
	MarketingIcon,
	ScheduleIcon,
	AnalyticsIcon,
	TasksIcon,
	AgenciesIcon,
	SettingsIcon,
} from "components/ATSNavbar/icons";
import { useTranslation } from "react-i18next";

const iconsExchanger = {
	dashboard: (active) => <DashboardIcon active={active} />,
	TalentNetwork: (active) => <CandidatesIcon active={active} />,
	jobs: (active) => <JobsIcon active={active} />,
	workforce: (active) => <WorkforceIcon active={active} />,
	vendors: (active) => <AgenciesIcon active={active} />,
	clients: (active) => <CRMIcon active={active} />,
	marketing: (active) => <MarketingIcon active={active} />,
	calendar: (active) => <ScheduleIcon active={active} />,
	analytics: (active) => <AnalyticsIcon active={active} />,
	settings: (active) => <SettingsIcon active={active} />,
	"company-tasks": (active) => <TasksIcon active={active} />,
};

const MenuRow = ({ menuTab, expandedView, store, historyStore }) => {
	const { t } = useTranslation()
	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};

	return (
		<RowContainer
			className={`leo-pt-25 leo-pb-25 leo-pl-30 ${expandedView} ${historyStore.active_tab === menuTab.param ? "active" : ""
				}`}
		>
			<NavLink
				className={`${historyStore.active_tab === menuTab.param ? "active" : ""}`}
				to={menuTab.url(historyStore.current, store)}
				onClick={scrollToTop}
			>
				<IconContainer className="leo-relative">
					{menuTab.upgrade_to_use && (
						<ProIndicator>
							<i className="fas fa-trophy"></i>
						</ProIndicator>
					)}
					{iconsExchanger[menuTab.param]
						? iconsExchanger[menuTab.param](historyStore.active_tab === menuTab.param)
						: null}
				</IconContainer>
				{expandedView && (
					<>
						{menuTab.name !== "Vendors"
							? t(menuTab.prop) || menuTab.name
							: store.company.type === "Agency"
								? "Clients"
								: "Agencies"}
					</>
				)}
			</NavLink>
		</RowContainer>
	);
};

const RowContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: ${COLORS.text_medium_grey};

	&.active {
		color: ${COLORS.white};
	}
`;

const NavLink = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	white-space: nowrap;
	color: rgba(250, 248, 247,0.6);

	&:hover {
		text-decoration: none;
		color: ${COLORS.white};
	}

	&.active {
		color: ${COLORS.white};
	}
`;

const IconContainer = styled.div`
	width: 35px;
	margin-right: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ProIndicator = styled.div`
	color: ${COLORS.primary_yellow};
	position: absolute;
	top: -10px;
	left: -10px;
`;

export default MenuRow;
