import { ROUTES } from "routes";
const PROTO = {
	dashboard: {
		name: "dashboard",
		title: "dashboard.pageTitle",
		url: (routerProps) =>
			ROUTES.TempManager.url(routerProps.match.params.companyMentionTag, "dashboard"),
	},
	roster: {
		name: "roster",
		title: "Roster",
		url: (routerProps) =>
			ROUTES.TempManager.url(routerProps.match.params.companyMentionTag, "roster"),
	},
	jobs: {
		name: "jobs",
		title: "candidateProfile.tabs.jobs",
		url: (routerProps) =>
			ROUTES.TempManager.url(routerProps.match.params.companyMentionTag, "jobs"),
	},
	candidates: {
		name: "candidates",
		title: "candidates.title",
		url: (routerProps) =>
			ROUTES.TempManager.url(routerProps.match.params.companyMentionTag, "candidates"),
	},
	timesheets: {
		name: "timesheets",
		title: "navBar.timesheets",
		url: (routerProps) =>
			ROUTES.TempManager.url(routerProps.match.params.companyMentionTag, "timesheets"),
	},
	invoices: {
		name: "invoices",
		title: "navBar.invoices",
		url: (routerProps) =>
			ROUTES.TempManager.url(routerProps.match.params.companyMentionTag, "invoices"),
	},
	// rate_caculator: {
	//   param: "rate_caculator",
	//   name: "Rate Caculator",
	//   url: (routerProps, store) =>
	//     ROUTES.TempManager.url(
	//       routerProps.match.params.companyMentionTag ||
	//         store.company?.mention_tag ||
	//         store.allMyCompanies[0]?.mention_tag,
	//       "rate_caculator"
	//     ),
	// },
};

const tempPlusMenuGenerator = (store) => {
	const { owner, admin, recruiter } = store.role?.role_permissions || {};

	if (!owner && !admin && !recruiter) {
		return undefined;
	}
	return [
		PROTO.dashboard,
		PROTO.roster,
		PROTO.jobs,
		PROTO.candidates,
		PROTO.timesheets,
		PROTO.invoices,
		// PROTO.rate_caculator,
	];
};

export default tempPlusMenuGenerator;
