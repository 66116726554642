import { useState, useEffect } from "react";
import useGlobalContext from "contexts/globalContext/GlobalContext";

export const permissionChecker = (role_permissions, validPermissions) => {
	let answer = { view: false, edit: false };
	if (!role_permissions?.is_member) return answer;
	if (role_permissions?.owner) {
		return { view: true, edit: true };
	}
	if (role_permissions?.admin || validPermissions?.all) {
		answer.view = true;
	}
	if (!validPermissions) return answer;
	let validPermissionsArray = Object.entries(validPermissions)
		.filter((perm) => perm[1])
		.map((perm) => perm[0]);
	validPermissionsArray.map((permission) => {
		if (role_permissions?.[permission]) {
			answer.view = true;
			answer.edit = true;
		}
		return null;
	});
	return answer;
};

export const PermissionChecker = ({ valid, type, onFalse, children }) => {
	const store = useGlobalContext();
	const [permission, setPermission] = useState(undefined);

	useEffect(() => {
		if (store.role) {
			setPermission(permissionChecker(store.role.role_permissions, valid));
		}
	}, [store.role]);

	if (permission && permission[type]) {
		return children;
	} else if (permission && onFalse) {
		return onFalse();
	} else {
		return null;
	}
};

export const permissionExchanger = {
	default: {
		owner: "addFilter.owner",
		admin: "addFilter.admin",
		member: "addFilter.teamMember",
		manager: "addFilter.teamManager",
	},
};

export const rolesExchanger = {
	default: {
		recruiter: "jobTable.recruiter",
		hiring_manager: "jobTable.hiringManager",
		business: "jobTable.businessDev",
		marketer: "jobTable.marketer",
	},
};
