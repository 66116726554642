import { API_ROOT_PATH } from "constants/api";
import i18next from "i18next";
import notify from "notifications";

const getTmSettings = async (companyId, teamMemberId, session) => {
	const endpoint = `${API_ROOT_PATH}/v2/companies/${companyId}/team_members/${teamMemberId}/settings`;
	const options = { headers: session };

	try {
		const tmSettingsResponse = await (await fetch(endpoint, options)).json();
		if (tmSettingsResponse?.error) throw new Error("Unable to get team member's settings");
		return tmSettingsResponse;
	} catch (error) {
		notify("danger", error, i18next.t("statusMessage.teamMemberSettingsError"));
		return { error: true, data: error };
	}
};

const updateTmSettings = async (settings, companyId, teamMemberId, session) => {
	const endpoint = `${API_ROOT_PATH}/v2/companies/${companyId}/team_members/${teamMemberId}/settings/${settings.id}`;
	const options = { method: "PUT", headers: session, body: JSON.stringify({ settings }) };

	try {
		const tmSettingsResponse = await (await fetch(endpoint, options)).json();
		if (tmSettingsResponse?.error) throw new Error("Unable to update team member's settings");
		return tmSettingsResponse;
	} catch (error) {
		notify("danger", error, i18next.t("statusMessage.teamMemberSettingsError"));
		return { error: true, data: error };
	}
};

export { getTmSettings, updateTmSettings };
