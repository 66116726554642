import { ROUTES } from "routes";
import tempPlusMenuGenerator from "constants/menus/tempPlus";
import spacetime from "spacetime";
import { moveDate7DaysAhead } from "sharedComponents/SubscriptionChecker";


const PROTO = {
	dashboard: {
		param: "dashboard",
		name: "Dashboard",
		prop: "dashboard.pageTitle",
		url: (routerProps, store) =>
			ROUTES.CompanyDashboard.url(
				routerProps.match.params.companyMentionTag ||
				store.company?.mention_tag ||
				store.allMyCompanies[0]?.mention_tag
			),
	},
	talent: {
		param: "TalentNetwork",
		name: "Candidates",
		prop: "candidates.title",
		url: (routerProps, store) =>
			ROUTES.TalentNetwork.url(
				routerProps.match.params.companyMentionTag ||
				store.company?.mention_tag ||
				store.allMyCompanies[0]?.mention_tag
			),
	},
	jobs: {
		param: "jobs",
		name: "Jobs",
		prop: "candidateProfile.tabs.jobs",
		url: (routerProps, store) =>
			ROUTES.ViewJobs.url(
				routerProps.match.params.companyMentionTag ||
				store.company?.mention_tag ||
				store.allMyCompanies[0]?.mention_tag
			),
	},
	temp: {
		param: "workforce",
		name: "Workforce",
		prop: "navBar.workforce",
		url: (routerProps, store) =>
			ROUTES.TempManager.url(
				routerProps.match.params.companyMentionTag ||
				store.company?.mention_tag ||
				store.allMyCompanies[0]?.mention_tag
			),
		submenu: (store) => tempPlusMenuGenerator(store),
		// beta: true,
	},
	vendors: {
		param: "vendors",
		name: "Vendors",
		prop: "navBar.vendors",
		url: (routerProps, store) =>
			ROUTES.Vendors.url(
				routerProps.match.params.companyMentionTag ||
				store.company?.mention_tag ||
				store.allMyCompanies[0]?.mention_tag
			),
		submenu: true,
	},
	clients: {
		param: "clients",
		name: "Client CRM",
		prop: "navBar.clientCRM",
		url: (routerProps, store) =>
			ROUTES.ClientManager.url(
				routerProps.match.params.companyMentionTag ||
				store.company?.mention_tag ||
				store.allMyCompanies[0]?.mention_tag
			),
		submenu: true,
	},
	marketing: {
		param: "marketing",
		name: "Marketing",
		prop: "navBar.marketing",
		url: (routerProps, store) =>
			ROUTES.MarketingEmails.url(
				routerProps.match.params.companyMentionTag ||
				store.company?.mention_tag ||
				store.allMyCompanies[0]?.mention_tag
			),
		submenu: true,
		// beta: true,
	},
	calendar: {
		param: "calendar",
		name: "Schedule",
		prop: "stageOptions.schedule",
		url: (routerProps, store) =>
			ROUTES.Calendar.url(
				routerProps.match.params.companyMentionTag ||
				store.company?.mention_tag ||
				store.allMyCompanies[0]?.mention_tag
			),
	},
	analytics: {
		param: "analytics",
		name: "Analytics",
		prop: "jobTable.analytics",
		url: (routerProps, store) =>
			ROUTES.Analytics.url(
				routerProps.match.params.companyMentionTag ||
				store.company?.mention_tag ||
				store.allMyCompanies[0]?.mention_tag
			),
		submenu: true,
	},
	settings: {
		param: "settings",
		name: "Settings",
		prop: "navBar.settings",
		url: (routerProps, store) =>
			ROUTES.TeamView.url(
				routerProps.match.params.companyMentionTag ||
				store.company?.mention_tag ||
				store.allMyCompanies[0]?.mention_tag
			),
		submenu: true,
	},
	tasks: {
		param: "company-tasks",
		name: "Tasks",
		prop: "tasks",
		url: (routerProps, store) =>
			ROUTES.CompanyTasksManager.url(
				routerProps.match.params.companyMentionTag ||
				store.company?.mention_tag ||
				store.allMyCompanies[0]?.mention_tag
			),
	},
};

const mainMenuGenerator = (store) => {
	const { owner, admin, recruiter, business, marketer, hiring_manager } =
		store.role?.role_permissions || {};
	const type = store.company.type;
	const ownerAdmin = owner || admin;
	const subStatus = store.subscription_settings?.status
	const talent = ownerAdmin || recruiter || hiring_manager ? [{ ...PROTO.talent }] : [];
	const jobs = ownerAdmin || recruiter || hiring_manager ? [{ ...PROTO.jobs }] : [];
	const temp = type === "Agency" && (ownerAdmin || recruiter) && subStatus !== "starter" ? [{ ...PROTO.temp }] : [];
	const clients = type === "Agency" && (business || ownerAdmin) ? [{ ...PROTO.clients }] : [];
	const vendors = type === "Employer" && ownerAdmin ? [{ ...PROTO.vendors }] : [];
	const marketing =
		type === "Agency" && (ownerAdmin || marketer) && subStatus !== "starter"
			? [{ ...PROTO.marketing, upgrade_to_use: subStatus === "trial" && spacetime(spacetime.tomorrow()).diff(moveDate7DaysAhead(store.subscription_settings.created_at), "day") < 0 }]
			: [];
	const calendar = (type === "Agency" && (ownerAdmin || business || recruiter)) && subStatus !== "starter" ? [{ ...PROTO.calendar }] : [];
	const analytics = type === "Agency" && (ownerAdmin || recruiter || business) && subStatus !== "starter" ? [{ ...PROTO.analytics }] : [];
	const tasks = (type === "Agency" && ownerAdmin && subStatus !== "starter") ? [PROTO.tasks] : [];

	return [
		PROTO.dashboard,
		...talent,
		...jobs,
		...temp,
		...clients,
		...vendors,
		...marketing,
		...calendar,
		...analytics,
		...tasks,
		PROTO.settings,
	];
};

export default mainMenuGenerator;
