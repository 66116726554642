import { CableApp } from "websocket";

// professional_talent_network_#{params[:room]}
//
//
// talent_network_#{params[:room]}

export function connectGetUnreadMessages(ptn_id, addnewMessage) {
	CableApp.TalentNetwork = CableApp.cable.subscriptions.create(
		{
			channel: "ProfessionalTalentNetworkChannel",
			room: `${ptn_id}`,
		},
		{
			received: (newMessage) => addnewMessage(newMessage),
			disconnected: () => {},
			connected: () => {},
		}
	);
}

export function connectGetUnreadMessagesNumber(company_id, setNewTotal) {
	CableApp.TalentNetwork = CableApp.cable.subscriptions.create(
		{
			channel: "TalentNetworkChannel",
			room: `${company_id}`,
		},
		{
			received: (many) => setNewTotal(many),
			disconnected: () => {},
			connected: () => {},
		}
	);
}

export function connectGetUnreadMessageNotifications(store) {
	CableApp.TeamMember = CableApp.cable.subscriptions.create(
		{
			channel: "TeamMemberChannel",
			room: `${store.role.team_member.team_member_id}`,
		},
		{
			received: (messages) => {
				store.dispatch({ type: "UPDATE_UNREAD_MESSAGE_NOTIFICATIONS", payload: messages });
			},
			disconnected: () => {},
			connected: () => {},
		}
	);
}
