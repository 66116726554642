import React from "react";
import styled from "styled-components";
import {
	Input,
	Label,
	ProgressButton,
	// OrSeparator,
	// SocialButton,
	SelectType,
	RequiredSup,
} from "components/CompanySignup/components.sc";
import BankDetailsStage from "components/CompanySignup/BankDetailsStage";
import notify from "notifications";
import { EMAIL_REGEX } from "constants/regex";
import Spinner from "sharedComponents/Spinner";
import { BREAKPOINTS } from "constants/breakpoints";
import TermsAndConditions from "components/CompanySignup/TermsAndConditions";
import { useState } from "react";
// import facebook from "assets/facebook-logo.svg";
// import google from "assets/google-logo.svg";

const LeftForm = ({
	COPY,
	stage,
	setStage,
	form,
	setForm,
	isPro,
	signUpAction,
	saving,
	animation,
}) => {
	const [paymentMethod, setPaymentMethod] = useState(undefined);
	return (
		<Container className={`${animation} leo-relative`}>
			<Wrapper>
				<SectionTitle>{COPY[stage].title}</SectionTitle>
				{stage === "user" && <UserStage setStage={setStage} form={form} setForm={setForm} />}
				{stage === "company" && (
					<CompanyStage
						setStage={setStage}
						form={form}
						setForm={setForm}
						isPro={isPro}
						signUpAction={signUpAction}
					/>
				)}
				{stage === "bank_details" && (
					<BankDetailsStage
						setStage={setStage}
						form={form}
						setForm={setForm}
						signUpAction={signUpAction}
						setPaymentMethod={setPaymentMethod}
					/>
				)}
				{stage === "terms_and_conditions" && (
					<TermsAndConditions
						setStage={setStage}
						signUpAction={signUpAction}
						paymentMethod={paymentMethod}
					/>
				)}
			</Wrapper>
			{saving && (
				<LoadContainer>
					<Spinner />
				</LoadContainer>
			)}
		</Container>
	);
};

const UserStage = ({ setStage, form, setForm }) => {
	const progressNextStage = () => {
		if (!form.name) {
			return notify("danger", "You must input a name");
		}
		if (!form.email) {
			return notify("danger", "You must input an email");
		}
		if (EMAIL_REGEX.test(form.email) === false) {
			return notify("danger", "You must input a valid email");
		}
		if (!form.password) {
			return notify("danger", "You must input a password");
		}
		if (form.password.length < 8) {
			return notify(
				"danger",
				"Password is too small. Please write  a password between 8 and 64 characters"
			);
		}
		if (form.password.length > 64) {
			return notify(
				"danger",
				"Password is too long.  Please write  a password between 8 and 64 characters"
			);
		}
		if (!form.password_confirmation) {
			return notify("danger", "You must input a password confirmation");
		}
		if (form.password_confirmation !== form.password) {
			return notify("danger", "Password and  password confirmation must match");
		}
		setStage("company");
	};

	const onChange = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value });
	};
	return (
		<>
			<InputsGrid>
				<div>
					<Label>
						Name <RequiredSup>*</RequiredSup>
					</Label>
					<Input onChange={onChange} value={form.name} name="name" />
				</div>
				<div>
					<Label>Job Title</Label>
					<Input onChange={onChange} value={form.job_title} name="job_title" />
				</div>
				<div>
					<Label>
						Email Address <RequiredSup>*</RequiredSup>
					</Label>
					<Input onChange={onChange} value={form.email} name="email" type="email" />
				</div>
				<div>
					<Label>Phone Number</Label>
					<Input onChange={onChange} value={form.phone} name="phone" />
				</div>
				<div>
					<Label>
						Password <RequiredSup>*</RequiredSup>
					</Label>
					<Input
						onChange={onChange}
						value={form.password}
						name="password"
						maxLength="84"
						minLength="8"
						type="password"
					/>
				</div>
				<div>
					<Label>
						Confirm Password <RequiredSup>*</RequiredSup>
					</Label>
					<Input
						onChange={onChange}
						value={form.password_confirmation}
						name="password_confirmation"
						maxLength="84"
						minLength="8"
						type="password"
					/>
				</div>
			</InputsGrid>
			<ProgressButton onClick={progressNextStage}>
				Next <i className="fas fa-angle-right leo-ml-10"></i>
			</ProgressButton>
			{/*}<OrSeparator className="leo-flex-center-center  leo-relative">
				<span>or</span>
			</OrSeparator>
			<SocialContainer className="leo-flex-center-between">
				<SocialButton>
					<img src={facebook} alt="facebook logo" />
					<span>Sign Up with Facebook</span>
				</SocialButton>
				<SocialButton>
					<img src={google} alt="google logo" />
					<span>Sign Up with Google</span>
				</SocialButton>
			</SocialContainer>*/}
		</>
	);
};

const CompanyStage = ({ setStage, form, setForm, isPro, signUpAction }) => {
	const progressNextStage = () => {
		if (!form.company_name) {
			return notify("danger", "You must input a company name");
		}
		if (!form.company_type) {
			return notify("danger", "You must select a company type");
		}
		if (isPro) {
			setStage("bank_details");
		} else {
			signUpAction();
		}
	};

	const onChange = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value });
	};
	const selectType = (newType) => setForm({ ...form, company_type: newType });

	return (
		<>
			<div className="leo-mb-30">
				<Label>
					Company Type <RequiredSup>*</RequiredSup>
				</Label>
				<SelectType
					onChange={selectType}
					value={form.company_type === "Agency" ? "Recruitment  Agency" : form.company_type}
				/>
			</div>
			<div className="leo-mb-30">
				<Label>
					Company Name <RequiredSup>*</RequiredSup>
				</Label>
				<Input onChange={onChange} value={form.company_name} name="company_name" />
			</div>
			<ProgressButton onClick={progressNextStage}>
				{isPro ? (
					<>
						Next <i className="fas fa-angle-right leo-ml-10"></i>
					</>
				) : (
					"Sign up"
				)}
			</ProgressButton>
		</>
	);
};

export const Container = styled.div`
	background: #ffffff;
	box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
	border-radius: 14px;
	width: 100%;
	max-width: 585px;
	padding: 50px 65px 65px 65px;
	overflow: none;
	z-index: 1;

	@media ${BREAKPOINTS.for_tablet_landscape_up} {
		margin-right: 100px;
	}
	@media ${BREAKPOINTS.for_small_desktop_height} {
		padding: 20px 35px 35px 25px;
	}
	@media ${BREAKPOINTS.for_phone_only} {
		padding: 20px 35px 35px 25px;
	}

	&.fade-transition {
		animation: fadeTransition 800ms forwards;
	}

	&.no-margin {
		margin-right: 0px !important;
	}

	@keyframes fadeTransition {
		0% {
			opacity: 1;
		}
		45% {
			opacity: 0;
		}
		55% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`;

const Wrapper = styled.div``;

export const SectionTitle = styled.h1`
	font-weight: bold;
	font-size: 1.2em;
	line-height: 1.4em;
	text-align: center;
	color: #1e1e1e;
	margin-bottom: 3.2em;

	@media ${BREAKPOINTS.for_phone_only} {
		margin-bottom: 30px;
	}
`;

// const SocialContainer = styled.div`
// 	margin-top: 50px;
// 	display: grid;
// 	grid-template-columns: 1fr 1fr;
// 	grid-gap: 30px;
// 	@media ${BREAKPOINTS.for_small_desktop_height} {
// 		margin-top: 20px;
// 	}
// `;
export const InputsGrid = styled.div`
	display: grid;
	grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
	grid-gap: 1.4em;
`;

const LoadContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 14px;
	background: #eeeeee61;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export default LeftForm;
