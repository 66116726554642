import { AWS_CDN_URL } from "constants/api";

// export const fetchCountriesInfo = async () => {
// 	const url = `${AWS_CDN_URL}/countries-info.json`;
// 	console.log(url);
// 	// let error;
// 	return await fetch(url, {
// 		method: "get",
// 	}).json();
// 	// try {
// 	// 	let response = await fetch(url, {
// 	// 		method: "get",
// 	// 	});
// 	// 	let res = await response.json();
// 	// 	return res;
// 	// } catch (err) {
// 	// 	return { err: true, ...error, customError: err };
// 	// }
// };

export const fetchCountriesInfo = async () => {
	const url = `${AWS_CDN_URL}/countries-info.json`;

	let error;
	try {
		let response = await fetch(url, {
			method: "GET",
			// headers: session,
		});

		let res = await response.json();

		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to fetch countries info");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const fetchCurrenciesInfo = async () => {
	const url = `${AWS_CDN_URL}/currencies.json`;

	let error;
	try {
		let response = await fetch(url, {
			method: "GET",
			// headers: session,
		});

		let res = await response.json();

		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to fetch countries info");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};
