import React from "react";
import { store } from "react-notifications-component";
import styled from "styled-components";
import { COLORS } from "constants/style";
import AppButton from "styles/AppButton";
import UnbiasedAvatar from "sharedComponents/unbiased/UnbiasedAvatar";
// const StyleNot = styled.span`
//   background: red;
//   width: 100%;
//   border-radius: 5px;
//   padding: 5px;
// `;

// const Comp = props => <StyleNot id="hhhhh">{props.children}</StyleNot>;
let notificationObject = {
	title: "",
	message: "",
	type: "default",
	insert: "top",
	container: "top-right",

	animationIn: ["animated", "fadeIn"],
	animationOut: ["animated", "fadeOut"],
	// content: <Comp>{message}</Comp>,
	dismiss: {
		duration: 5000,
		pauseOnHover: true,
		showIcon: true,
		click: true,
	},
	width: 400,
};
const notify = (type, message, title) => {
	if (typeof message === "string") {
		let ntCopy = { ...notificationObject };
		ntCopy.type = type;
		ntCopy.message = message;
		ntCopy.title = title || "";
		store.addNotification(ntCopy);
	} else if (typeof message === "object") {
		if (Array.isArray(message)) {
			message.map((mss) => {
				let ntCopy = { ...notificationObject };
				ntCopy.type = type;
				ntCopy.message = mss;
				ntCopy.title = title || "";
				store.addNotification(ntCopy);
				return null;
			});
		} else {
			if (typeof message.error === "string") {
				let ntCopy = { ...notificationObject };
				ntCopy.type = type;
				ntCopy.message = message.error;
				ntCopy.title = title || "";
				store.addNotification(ntCopy);
			} else if (Array.isArray(message.error)) {
				message.error.map((mss) => {
					let ntCopy = { ...notificationObject };
					ntCopy.type = type;
					ntCopy.message = mss;
					ntCopy.title = title || "";
					store.addNotification(ntCopy);
					return null;
				});
			} else if (Array.isArray(message.errors)) {
				message.errors.map((mss) => {
					let ntCopy = { ...notificationObject };
					ntCopy.type = type;
					ntCopy.message = mss;
					ntCopy.title = title || "";
					store.addNotification(ntCopy);
					return null;
				});
			} else if (message.customError) {
				let ntCopy = { ...notificationObject };
				ntCopy.type = type;
				ntCopy.message = `${message.customError}`;
				ntCopy.title = title || "";
				store.addNotification(ntCopy);
			}
		}
	}
};

export default notify;

export const notifySMS = (type, message, sender, redirectToProfile, dismiss) => {
	if (type === "candidate") {
		let ntCopy = {
			...notificationObject,
			dismiss: { ...notificationObject.dismiss, duration: dismiss ? 3000 : 0 },
		};
		ntCopy.content = (props) => (
			<CandidateMessage
				{...props}
				sender={sender}
				message={message}
				redirectToProfile={redirectToProfile}
			/>
		);
		store.addNotification(ntCopy);
	}
};

const CandidateMessage = ({ sender, message, redirectToProfile, id }) => {
	return (
		<CandidateMessageContainer
			className={redirectToProfile ? "leo-flex leo-justify-between leo-align-start" : "leo-flex"}
		>
			<div className="leo-flex-center">
				<UnbiasedAvatar
					is_unbiased={sender.is_unbiased}
					name={sender.name}
					imgUrl={sender.avatar}
					size={30}
				/>
				<span>{message}</span>
			</div>
			<div className="leo-flex">
				{redirectToProfile && (
					<AppButton
						size="small"
						onClick={() => {
							redirectToProfile();
							store.removeNotification(id);
						}}
						className="leo-mr-10"
					>
						View
					</AppButton>
				)}
				<DismissButton
					onClick={() => store.removeNotification(id)}
					className="leo-flex-center-center dismiss-button"
				>
					<i className="fas fa-times"></i>
				</DismissButton>
			</div>
		</CandidateMessageContainer>
	);
};

const CandidateMessageContainer = styled.div`
	background: #77ebbe;
	border: 1px solid ${COLORS.primary_light_green};
	box-sizing: border-box;
	border-radius: 5px;
	width: 100%;
	padding: 10px;
	padding-left: 20px;

	span {
		margin-left: 5px;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		color: ${COLORS.primary_theme_blue};
		max-width: 280px;
		word-wrap: anywhere;
	}

	.dismiss-button {
		display: none;
	}

	&:hover {
		.dismiss-button {
			display: flex;
		}
	}
`;

const DismissButton = styled.button`
	position: absolute;
	top: -5px;
	left: -5px;
	height: 17px;
	width: 17px;
	background: ${COLORS.primary_red};
	border-radius: 17px;
	color: white;
	font-size: 10px;
	box-shadow: 0px 0px 2px rgba(116, 118, 123, 0.41);
	transition: all 100ms;
`;
