import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { COLORS } from "constants/style";
import { Container, SectionTitle } from "components/CompanySignup/LeftForm";
import BankDetailsStage from "components/CompanySignup/BankDetailsStage";
import TermsAndConditions from "components/CompanySignup/TermsAndConditions";
import InfoStage from "modals/UpgradeModal/InfoStage";
import PlanStage from "modals/UpgradeModal/PlanStage";
import FinalStage from "modals/UpgradeModal/FinalStage";
import AppButton from "styles/AppButton";
import { createProSubscription, reactivateSubscription } from "helpersV2/subscriptions";
import notify from "notifications";
import { getFullUser } from "contexts/globalContext/GlobalMethods";
import { fetchUpdateUser } from "helpersV2/user";
import { updateCompany } from "contexts/globalContext/GlobalMethods";
import { updateCompanyData } from "helpersV2/company";
import dots from "assets/dots-corner.svg";
import background0 from "components/CompanySignup/shapes/background-0.svg";
import background1 from "components/CompanySignup/shapes/background-1.svg";
import background2 from "components/CompanySignup/shapes/background-2.svg";
import background3 from "components/CompanySignup/shapes/background-3.svg";
import background4 from "components/CompanySignup/shapes/background-4.svg";
import BackgroundShapes from "components/CompanySignup/BackgroundShapes";
// import PackageInformation from "modals/UpgradeModal/PackageInformation";
import { getBillingSettings } from "contexts/globalContext/GlobalMethods";
import { useTranslation } from "react-i18next";

const UpgradeModal = ({ hide = () => {}, store, noModal, show }) => {
	const { t } = useTranslation()
	const [stage, setStage] = useState("initial");
	const [form, setForm] = useState({
		name: "",
		job_title: "",
		email: "",
		phone: "",
		company_type: "",
		company_name: "",
	});
	const [updatedInfo, setUpdatedInfo] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState(undefined);

	useEffect(() => {
		setForm({
			name: store.full_user?.name,
			job_title: store.full_user?.title,
			email: store.full_user?.email,
			phone: store.full_user?.phone_number,
			company_type: store.company?.type,
			company_name: store.company?.name,
		});
	}, [store.full_user, store.company]);

	useEffect(() => {
		if (!store.full_user) {
			getFullUser(store.session, store.dispatch);
		}
	}, [store.full_user]);

	useEffect(() => {
		const body = document?.querySelector("body");
		if (!show) {
			body.style.overflow = "auto";
		} else {
			body.style.overflow = "hidden";
		}
	}, [show]);

	const handleHide = () => {
		const body = document?.querySelector("body");
		body.style.overflow = "auto";
		hide();
	};

	const [paymentPeriod, setPaymentPeriod] = useState("monthly");

	const reactivateSubscriptionAction = async () => {
		const response = await reactivateSubscription(store.subscription_settings?.id, store.session);

		if (response?.status <= 300 && response?.status >= 200) {
			getBillingSettings(store.current_company_id, store.session, store.dispatch);
			setStage("final");
			setTimeout(handleHide, 2000);
		}
	};

	const signUpAction = (paymentMethod) => {
		if (updatedInfo) {
			fetchUpdateUser(store.session, store.session.id, {
				name: form.name,
				email: form.email,
				phone_number: form.phone || "",
				title: form.job_title || "",
			}).then((res) => {
				if (!res.err) {
					notify("info", t("statusMessage.profileUpdated"));
					getFullUser(store.session, store.dispatch);
				} else {
					notify("danger", res);
				}
			});
			updateCompanyData(store.session, store.company.id, {
				name: form.company_name,
			}).then((res) => {
				if (!res.err) {
					notify("info", t("statusMessage.companyUpdated"));
					updateCompany(store, store.company.mention_tag);
				} else {
					notify("danger", res);
				}
			});
		}
		let body = {
			email: form.email,
			company_id: store.company.id,
			billing: paymentPeriod,
			payment_method: paymentMethod.id,
		};
		createProSubscription(body, store.session).then((res) => {
			if (!res.err) {
				getBillingSettings(store.current_company_id, store.session, store.dispatch);
				setStage("final");
				setTimeout(function () {
					handleHide();
				}, 2000);
			}
		});
	};

	const handleSubmitAction = (payment_method) => {
		if (store.subscription_settings.status === "cancelled" && store.subscription_settings.id) {
			return reactivateSubscriptionAction();
		}

		return signUpAction(payment_method);
	};

	return (
		<Wrapper className={noModal ? "no-modal" : ""}>
			<BackgroundShapes COPY={COPY} stage={stage} />
			{!noModal && (
				<HideButton onClick={() => handleHide()}>
					<i className="fas fa-times"></i>
				</HideButton>
			)}
			{stage === "initial" && (
				<InitialContainer>
					<Title>
						{store.subscription_settings?.status === "cancelled"
							? "Reactivate your Pro"
							: "Why upgrade to Pro?"}
					</Title>
					<Copy>
						With {process.env.REACT_APP_NAME}&apos;s automation capabilities, KPI rings, management systems, and much more, we are
						putting the power back into the recruiter&apos;s hands. From video messaging to client
						branded job ads, {process.env.REACT_APP_NAME}s features are created to take the work out of your job. But, we
						didn’t stop there. Alongside {process.env.REACT_APP_NAME}, we created Alaska, Brief Builder and Job Board to
						automate, design and promote your services. Clients will want you; candidates will want
						to be you.
					</Copy>
					{/* <PackageInformation store={store} /> */}
					<div>
						<a href={`${process.env.REACT_APP_MARKETING_SITE_URL}/contact-us`} target="_blank" rel="noopener noreferrer">
							<AppButton
								theme="primary"
								// onClick={() => setStage("info")}
								className="leo-mt-30"
								id="upgradeCTA"
							>
								Get the best from {process.env.REACT_APP_NAME}
							</AppButton>
						</a>
					</div>
				</InitialContainer>
			)}
			{stage === "info" && (
				<InfoStage
					form={form}
					setForm={setForm}
					setStage={setStage}
					setUpdatedInfo={setUpdatedInfo}
				/>
			)}
			{stage === "plan" && (
				<PlanStage
					paymentPeriod={paymentPeriod}
					setPaymentPeriod={setPaymentPeriod}
					setStage={setStage}
				/>
			)}
			{stage === "bank_details" && (
				<Container className="no-margin">
					<SectionTitle>Enter Bank Details</SectionTitle>
					<BankDetailsStage
						setStage={setStage}
						form={form}
						setForm={setForm}
						signUpAction={handleSubmitAction}
						setPaymentMethod={setPaymentMethod}
					/>
				</Container>
			)}
			{stage === "terms_and_conditions" && (
				<Container className="no-margin">
					<SectionTitle>Read Our Terms And Condition</SectionTitle>
					<TermsAndConditions
						setStage={setStage}
						paymentMethod={paymentMethod}
						signUpAction={handleSubmitAction}
					/>
				</Container>
			)}
			{stage === "final" && (
				<>
					<FinalStage />
					<Dots src={dots} alt="" />
				</>
			)}
		</Wrapper>
	);
};

export default UpgradeModal;

const Title = styled.h1`
	font-weight: bold;
	font-size: 2.4em;
	line-height: 1.8em;
	text-align: center;
	color: #ffffff;
	margin-bottom: 30px;
`;

const Copy = styled.p`
	font-size: 0.8em;
	line-height: 1.4em;
	text-align: center;
	color: #ffffff;
	max-width: 843px;
`;

const InitialContainer = styled.div`
	/* height: 100vh; */
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
`;

const Wrapper = styled.div`
	width: 100vw;
	height: 100%;
	max-width: 100vw;
	max-height: 100%;
	background: red;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2000;
	background: ${COLORS.primary_theme_blue};
	background: linear-gradient(137.42deg, #000000 -16.77%, #2a3744 50.1%, #000000 98.9%);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow: none;

	&.no-modal {
		width: 100%;
		height: calc(100vh - 50px);
		position: initial;
		z-index: 0;
		padding-top: 50px;
	}
`;

const HideButton = styled.button`
	position: absolute;
	color: white;
	top: 30px;
	right: 30px;
	font-size: 26px;
`;

const Dots = styled.img`
	position: absolute;
	bottom: 0;
	right: 0;
	width: 40vw;
`;

const COPY = {
	initial: {
		background: background0,
	},
	info: {
		background: background1,
	},
	plan: {
		background: background2,
	},
	bank_details: {
		background: background3,
	},
	terms_and_conditions: {
		background: background3,
	},
	final: {
		background: background4,
	},
};
