import SIGNING_ROUTES from "routes/signingRoutes";
import PERSONAL_SETTINGS_ROUTES from "routes/personalSettingsRoutes";
import ATS_BASE_ROUTES from "routes/atsBaseRoutes";
import ATS_PROFILES_ROUTES from "routes/atsProfilesRoutes";

export const ROUTES = {
	...SIGNING_ROUTES,
	...PERSONAL_SETTINGS_ROUTES,
	...ATS_BASE_ROUTES,
	...ATS_PROFILES_ROUTES,
	// CompanyCreate: {
	//   path: "/create",
	//   render: (args) => (
	//     <Suspense fallback={<Fallback />}>
	//       <CompanyCreate {...args} />
	//     </Suspense>
	//   ),
	//   url: () => "/create",
	// },
};

export const ATS_PATHS = Object.values({
	...ATS_BASE_ROUTES,
	...ATS_PROFILES_ROUTES,
	...PERSONAL_SETTINGS_ROUTES,
}).reduce((acc, curr) => {
	return { ...acc, [curr.path]: true };
}, {});

export const REDIRECTS = {
	OutlookLogout: {
		to: "/",
		from: "/outlook-logout",
	},
	ProfSignin: {
		to: ROUTES.MyCompanies.url(),
		from: "/professional_auth/sign_in",
	},
	Privacy: {
		to: `${process.env.REACT_APP_MARKETING_SITE_URL}/privacyPolicy`,
		from: "/privacyPolicy",
	},
	Terms: {
		to: `${process.env.REACT_APP_MARKETING_SITE_URL}/termsOfService`,
		from: "/termsOfService",
	},
	SignUp: {
		to: ROUTES.CompanySignupFree.url(),
		from: "/signup",
	},
};
