import { storeInLocalStorage } from "contexts/globalContext/StorageMethods.js";

const StorageHandlers = {
	// UPDATE_EMAILS_SCAN: (state, action) => {
	// 	return { ...state, emailsScanPending: action.payload };
	// },
	UPDATE_TOTALS: (state, action) => {
		return { ...state, totals: { ...state.totals, ...action.payload } };
	},
	UPDATE_MAIN_ENDPOINT_LOAD: (state, action) => {
		return {
			...state,
			main_endpoints_load: { ...state.main_endpoints_load, ...action.payload },
		};
	},
	UPDATE_STORED_FILTERS: (state, action) => {
		return { ...state, stored_filters: { ...state.stored_filters, ...action.payload } };
	},
	UPDATE_STORED_SEGMENT: (state, action) => {
		return { ...state, stored_segment: { ...state.stored_segment, ...action.payload } };
	},
	ADD_STORED_SEARCHES: (state, action) => {
		let newArr = [];
		let origin = action.payload.origin;
		let currArr = state.stored_searches[origin] || [];

		//if search matches the last search replace it through slice. Used to stop duplication when using the same search and adding filters
		let match = 0;
		if (currArr[0] && currArr[0].search === action.payload.new.search) {
			let currFilters = currArr[0].filters ? currArr[0].filters.length : 0;
			let newFilters = action.payload.new.filters ? action.payload.new.filters.length : 0;
			if ((currFilters !== 0 && newFilters !== 0) || (currFilters === 0 && newFilters === 0)) {
				match = 1;
			}
		}
		newArr = [action.payload.new, ...currArr.slice(match, 9)];

		let newStoredSearches = { ...state.stored_searches, [origin]: newArr };
		storeInLocalStorage(newStoredSearches);
		return {
			...state,
			stored_searches: newStoredSearches,
		};
	},
	UPDATE_STORED_SEARCHES: (state, action) => {
		return {
			...state,
			stored_searches: { ...action.payload },
		};
	},
	USE_STORED_SEARCH: (state, action) => {
		return { ...state, use_stored_search: action.payload };
	},
	UPDATE_ATS_NAVBAR_SIZE: (state, action) => {
		return { ...state, ats_navbar_size: action.payload };
	},
	UPDATE_UNREAD_MESSAGE_NOTIFICATIONS: (state, action) => {
		return { ...state, unread_message_notifications: action.payload };
	},
	CLEAR_UNREAD_MESSAGE_NOTIFICATIONS: (state) => {
		return { ...state, unread_message_notifications: undefined };
	},
	OPEN_SUBSCRIPTION_MODAL: (state, action) => {
		return { ...state, subscription_modal: action.payload };
	},
};
export default StorageHandlers;
