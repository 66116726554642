import React, { useEffect, useCallback } from "react";
import styled from "styled-components";
import { useIntercom } from "react-use-intercom";
import { COLORS, COLORSV2 } from "constants/style";
import ReactDOM from "react-dom";

const IntercomComponent = ({ store, unreadIntercom }) => {
	const { boot, show } = useIntercom();
	const showIntercom = useCallback(() => show(), [show]);

	useEffect(() => {
		if (store.session && store.user) {
			boot({
				userId: store.user.id,
				email: store.session.uid,
				name: store.user.name,
				isConfirmed: store.user.is_confirmed,
				onboardingCompleted: store.user.onboarding_completed,
				tempPassword: store.user.temp_password,
				userStatus: store.user.user_status,
				customAttributes: {
					hide_default_launcher: true,
					background_color: COLORS.primary_theme_blue,
					action_color: COLORS.primary_theme_blue,
				},
			});
		}
	}, [store.session, store.user]);

	return (
		<>
			{store.session && store.user && (
				<>
					{ReactDOM.createPortal(
						<Button onClick={showIntercom} id="custom_intercom_launcher">
							<svg width="20" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M2 0a2 2 0 00-2 2v17.429a2 2 0 002 2h12.444l4.859 2.082a.5.5 0 00.697-.46V2a2 2 0 00-2-2H2z"
									fill="#fff"
								/>
								<path d="M2.857 15.238c5.579 2.522 8.707 2.558 14.286 0" stroke="#2A3744" />
							</svg>{" "}
							{unreadIntercom > 0 ? (
								<TotalIcon size={unreadIntercom} className="leo-flex-center-center">
									<span className="leo-flex-center-center">{unreadIntercom}</span>
								</TotalIcon>
							) : null}
						</Button>,
						document.getElementById("intercom-button-target")
					)}
				</>
			)}
		</>
	);
};

const Button = styled.button`
	width: 50px;
	height: 50px;
	border-radius: 50px;
	background: ${COLORSV2.nave_blue};
	position: fixed;
	bottom: 25px;
	right: 10px;
	color: white;
	z-index: 2000;
	transition: all 500ms;

	&.move-left {
		right: 70px;
	}
`;

const TotalIcon = styled.span`
	position: absolute;
	top: 5px;
	left: 5px;

	span {
		background: ${COLORS.primary_red};
		color: ${COLORS.white};
		font-size: 10px;
		border-radius: 100%;
		animation: grow 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6) forwards;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.68);
	}

	@keyframes grow {
		0% {
			opacity: 0;
			width: 0px;
			height: 0px;
		}
		to {
			opacity: 1;
			width: 20px;
			height: 20px;
		}
	}
`;

export default IntercomComponent;
