import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { AWS_CDN_URL } from "constants/api";
import { COLORS } from "constants/style";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
let timeout = null;

export const SearchBar = ({ search, setSearch }) => {
	let history = useHistory();
	const [searchValue, setSearchValue] = useState("");
	const [updateValue, setUpdateValue] = useState(false);

	useEffect(() => {
		if (searchValue !== search) {
			timeout = setTimeout(() => setUpdateValue(true), 500);
		}
		return () => clearTimeout(timeout);
	}, [searchValue]);

	useEffect(() => {
		if (search !== searchValue) {
			setSearchValue(search);
		}
	}, [search]);

	useEffect(() => {
		if (updateValue) {
			if (searchValue.length > 2) {
				setSearch(searchValue);
				let str = queryString.stringify({ search: searchValue });
				history.push({ search: str });
			} else if (search !== "") {
				history.push({ search: "" });
				setSearch("");
			}
			setUpdateValue(false);
		}
	}, [updateValue]);

	useEffect(() => {
		if (history?.location?.search) {
			let search = queryString.parse(history?.location?.search).search;
			if (search) {
				setSearchValue(search);
				setSearch(search);
			}
		}
	}, []);

	return (
		<SearchBarWrapper className="leo-flex-center leo-ml-50">
			<img src={`${AWS_CDN_URL}/icons/ElasticMagnifier.svg`} alt="" />
			<Search
				type="search"
				value={searchValue}
				onChange={(e) => setSearchValue(e.target.value)}
				placeholder={`Power Search`}
				autoFocus
			/>
		</SearchBarWrapper>
	);
};

const SearchBarWrapper = styled.div`
	svg,
	img {
		margin-right: 10px;
	}
`;

const Search = styled.input`
	background: transparent;
	border: none;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: ${COLORS.text_black};
	padding: 0;
	width: 350px;
`;
