import React from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { AWS_CDN_URL } from "constants/api";
import { BORDERS, COLORSV2 } from "constants/style";
import { COLORS } from "constants/style";
import UnbiasedAvatar from "sharedComponents/unbiased/UnbiasedAvatar";
import HiddenText from "sharedComponents/unbiased/HiddenText";
import { isUnbiasedHook } from "sharedComponents/unbiased/helpers";
import { useTranslation } from "react-i18next";

const UniversalModal = ({ show, hide, id, width, children }) => (
	<STUniModal
		show={show}
		onHide={hide}
		dialogClassName={width ? `modal-${width}w` : `modal-480w`}
		id={id}
		enforceFocus={false}
	>
		<Modal.Dialog className={width ? `modal-${width}w` : `modal-480w`}>{children}</Modal.Dialog>
	</STUniModal>
);

export default UniversalModal;

export const ModalFooter = ({ children, hide, cancelText }) => {
	const { t } = useTranslation()
	return (
		<ModalFooterST>
			<CloseButton
				type="button"
				className="button button--default button--grey-light button--full"
				onClick={hide}
			>
				{cancelText || t("close")}
			</CloseButton>
			{children}
		</ModalFooterST>
	)
};

export const ModalHeaderClassic = ({ title, closeModal, theme }) => (
	<ClassicModalHeader className={theme === "v2theme" ? "v2theme" : ""}>
		<h3>{title}</h3>
		<button type="button" onClick={closeModal}>
			<img src={`${AWS_CDN_URL}/icons/CloseModal2.svg`} alt="Close" />
		</button>
	</ClassicModalHeader>
);

export const ModalHeaderV2 = ({ name, userAvatar, subTitle, link, candidate, hide }) => {
	const is_unbiased = isUnbiasedHook(candidate);

	return (
		<ModalHeader>
			<div style={{ alignItems: "center" }} className="leo-flex">
				<UnbiasedAvatar name={name} imgUrl={userAvatar} size={50} is_unbiased={is_unbiased} />
				<div style={{ marginLeft: "15px" }}>
					<h2>
						<HiddenText str={name} replace={is_unbiased} />
					</h2>
					<span>{subTitle}</span>
				</div>
			</div>
			{link && <OpenButton to={link}>Open</OpenButton>}
			{hide && (
				<HideButton onClick={hide}>
					<i className="fas fa-times"></i>
				</HideButton>
			)}
		</ModalHeader>
	);
};

export const MinimalHeader = ({ title, hide, className }) => (
	<MinimalHeaderST className={className}>
		<h3>{title}</h3>
		<button onClick={() => (hide ? hide() : null)}>
			<i className="fas fa-times" />
		</button>
	</MinimalHeaderST>
);

export const ModalBody = styled(Modal.Body)`
	padding: 0 !important;

	&.no-header {
		border-top-left-radius: 8px !important;
		border-top-right-radius: 8px !important;
	}

	&.no-footer {
		border-bottom-left-radius: 8px !important;
		border-bottom-right-radius: 8px !important;
	}
`;

const ModalHeader = styled.div`
	align-items: center;
	background: ${COLORSV2.nave_blue};
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	color: ${COLORS.white};
	display: flex;
	justify-content: space-between;
	padding: 30px;

	h2 {
		font-size: 24px;
		font-weight: 500;
		margin-bottom: 5px;
	}

	span {
		font-size: 12px;
		opacity: 0.75;
	}
`;

const ClassicModalHeader = styled.div`
	padding: 30px 0;
	position: relative;
	border-bottom: ${BORDERS.grey_thin};
	background: ${COLORS.white};
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;

	h3 {
		color: ${COLORS.text_black};
		font-size: 20px;
		letter-spacing: -0.3px;
		text-align: center;
	}

	&.v2theme {
		background: ${COLORS.primary_theme_blue};
		h3 {
			text-align: left;
			color: ${COLORS.white};
			margin-left: 30px;
		}
	}

	button {
		opacity: 1;
		position: absolute;
		right: 30px;
		top: 31px;
	}
`;

const ModalFooterST = styled(Modal.Footer)`
	background: #cccccc;
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-end !important;
`;

export const ModalFooterV2 = styled.div`
	padding: 20px;
	background: #fbfbfb;
	border-radius: 0px 0px 8px 8px;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
`;

const OpenButton = styled(Link)`
	background: ${COLORS.white};
	border-radius: 4px;
	color: ${COLORS.text_black};
	font-size: 12px;
	font-weight: 500;
	padding: 4px 10px;
	text-transform: uppercase;

	&:hover {
		text-decoration: none;
	}
`;

const CloseButton = styled.button`
	width: auto;
`;

const STUniModal = styled(Modal)`
	.modal-dialog {
	}

	.modal-1040w {
		min-width: 1040px;
	}
	.modal-330w {
		min-width: 330px;
	}
	.modal-380w {
		min-width: 380px;
	}
	.modal-435w {
		min-width: 435px;
	}
	.modal-780w {
		min-width: 780px;
	}
	.modal-700w {
		min-width: 700px;
	}
	.modal-550w {
		min-width: 550px;
	}
	.modal-620w {
		min-width: 620px;
	}
	.modal-500w {
		min-width: 500px;
	}
	.modal-700w {
		min-width: 700px;
	}

	.modal-840w {
		min-width: 840px;
	}

	.modal-content {
		background: transparent;
		/* margin-top: 10px;
		margin-bottom: 10px; */
	}

	.modal-body {
		background: ${COLORS.white};
		border-radius: 0;

		.paragraph {
			font-size: 15px;
			line-height: 25px;
			margin: 0;
			margin-bottom: 20px;
		}

		span {
			&.status {
				background: rgba(0, 202, 165, 0.1);
				border-radius: 4px;
				color: ${COLORS.primary_light_green};
				display: inline-block;
				font-size: 10px;
				font-weight: 500;
				letter-spacing: 0.5px;
				line-height: 1;
				padding: 5px 8px;
				position: relative;
				text-transform: uppercase;
				top: -1px;
			}
		}
	}

	.modal-footer {
		background: #fafafa;
		border: 0;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		border-top: ${BORDERS.grey_thin};
	}
`;

const MinimalHeaderST = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: ${COLORSV2.nave_blue};
	padding: 15px;
	border-bottom: ${BORDERS.grey_thin};
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: ${COLORS.white};
	border-radius: 8px 8px 0px 0px;
	z-index: 200;
	position: relative;

	h3 {
		text-transform: capitalize;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: ${COLORSV2.nave_blue};
		max-width: 280px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	button {
		color: grey;
	}

	&.sticky {
		position: sticky;
		top: 0;
		background: ${COLORS.white};
		z-index: 1;
	}
`;

const HideButton = styled.button`
	position: absolute;
	top: 10px;
	right: 15px;
	color: white;
	font-size: 16px;
`;
