import React from "react";
import { COLORS } from "constants/style";
import UniversalModal, { ModalBody } from "modals/UniversalModal/UniversalModal";
import styled from "styled-components";

const ConfirmChangesModal = ({ hide, title = "", copy, children }) => {
	return (
		<UniversalModal show={true} hide={hide} id="confirm-package-changes" width={480}>
			<STModalBody className="no-footer no-header">
				<Close type="button" onClick={hide}>
					<i className="fas fa-times"></i>
				</Close>
				<Title>{title}</Title>
				<Copy>{children ? children : copy}</Copy>
			</STModalBody>
		</UniversalModal>
	);
};

export default ConfirmChangesModal;

const STModalBody = styled(ModalBody)`
	padding: 30px !important;
	text-align: center;
`;

const Title = styled.h2`
	font-weight: bold;
	font-size: 18px;
	text-align: center;
	color: ${COLORS.primary_green};
	text-transform: uppercase;
	font-size: 30px;
	margin-bottom: 30px;
	margin-top: 40px;
`;

const Copy = styled.p`
	font-weight: bold;
	margin-bottom: 20px;
	line-height: 25px;
	letter-spacing: 1.5px;
`;

const Close = styled.button`
	position: absolute;
	right: 10px;
	top: 10px;
	color: ${COLORS.suplement_dark_grey};
`;
