import { addNewAttribute } from "contexts/globalContext/GlobalMethods";
import GLOBAL_STATE, { STORAGE_STATE } from "contexts/globalContext/GlobalState";

const GlobalHandlers = {
	UPDATE_SESSION: (state, action) => {
		return { ...state, session: action.payload };
	},
	UPDATE_USER: (state, action) => {
		return { ...state, user: action.payload };
	},
	UPDATE_FULL_USER: (state, action) => {
		return { ...state, full_user: action.payload };
	},
	UPDATE_ALLMYCOMPANIES: (state, action) => {
		return { ...state, allMyCompanies: action.payload };
	},
	UPDATE_COMPANY: (state, action) => {
		localStorage.setItem("lastCompanyId", JSON.stringify(action.payload.id));
		return {
			...state,
			company: action.payload,
			current_company_id: action.payload.id,
		};
	},
	REPLACE_COMPANY: (state, action) => {
		localStorage.setItem("lastCompanyId", JSON.stringify(action.payload.id));
		return {
			...state,
			company: action.payload,
			current_company_id: action.payload.id,
			full_company: undefined,
			role: undefined,
			teamMembers: undefined,
			allTeamMembers: undefined,
			deletedTeamMembers: undefined,
			skills: undefined,
			industries: undefined,
			departments: undefined,
			business_areas: undefined,
			// sectors
			tags: undefined,
			interviewStages: undefined,
			sources: undefined,
			contacting_types: undefined,
			approval_process: undefined,
			job_extra_fields: undefined,
			twilio: undefined,
			nylas: undefined,
			settings: undefined,
			kpis: undefined,
			subscription_settings: undefined,
			total_creation_numbers: {
				users: 0,
				jobs: 0,
				candidates: 0,
				clients: 0,
				contacts: 0,
				deals: 0,
				pipelines: 0,
			},
		};
	},
	// DELETE_COMPANY: (state) => {
	// 	return { ...state, company: undefined, full_company: undefined, current_company_id: undefined };
	// },
	UPDATE_FULL_COMPANY: (state, action) => {
		return { ...state, full_company: action.payload };
	},
	UPDATE_ROLE: (state, action) => {
		return { ...state, role: action.payload };
	},
	UPDATE_TEAM_MEMBERS: (state, action) => {
		return {
			...state,
			teamMembers: action.payload.filter(
				(member) => member.active || (member.active_until && member.active_until < new Date())
			),
			allTeamMembers: action.payload.sort((first, second) =>
				first.active && !second.active ? -1 : !first.active && second.active ? 1 : 0
			),
			deletedTeamMembers: action.payload.filter(
				(member) => !member.active || (!!member.active_until && member.active_until >= new Date())
			),
		};
	},
	UPDATE_SKILLS: (state, action) => {
		return { ...state, skills: action.payload };
	},
	UPDATE_INDUSTRIES: (state, action) => {
		return { ...state, industries: action.payload };
	},
	UPDATE_DEPARTMENTS: (state, action) => {
		return { ...state, departments: action.payload };
	},
	UPDATE_BUSINESS_AREAS: (state, action) => {
		return { ...state, business_areas: action.payload };
	},
	UPDATE_TAGS: (state, action) => {
		// now it is tags not sectors
		return { ...state, tags: action.payload };
	},
	ADD_NEW_SKILL: (state, action) => {
		return { ...state, skills: addNewAttribute(state.skills, action.payload) };
	},
	ADD_NEW_INDUSTRY: (state, action) => {
		return {
			...state,
			industries: addNewAttribute(state.industries, action.payload),
		};
	},
	ADD_NEW_DEPARTMENT: (state, action) => {
		return {
			...state,
			departments: addNewAttribute(state.departments, action.payload),
		};
	},
	ADD_NEW_BUSINESS_AREA: (state, action) => {
		return {
			...state,
			business_areas: addNewAttribute(state.business_areas, action.payload),
		};
	},
	ADD_NEW_SECTOR: (state, action) => {
		return {
			...state,
			// not sectors now it is tags
			tags: addNewAttribute(state.tags, action.payload),
		};
	},
	UPDATE_INTERVIEW_STAGES: (state, action) => {
		return { ...state, interviewStages: action.payload };
	},
	UPDATE_SOURCES: (state, action) => {
		return { ...state, sources: action.payload };
	},
	UPDATE_CONTACTING_TYPES: (state, action) => {
		return { ...state, contacting_types: action.payload };
	},
	UPDATE_APPROVAL_PROCESS: (state, action) => {
		return { ...state, approval_process: action.payload };
	},
	UPDATE_JOB_EXTRA_FIELDS: (state, action) => {
		return { ...state, job_extra_fields: action.payload };
	},
	UPDATE_TWILIO: (state, action) => {
		return { ...state, twilio: action.payload };
	},
	UPDATE_COUNTRIES_INFO: (state, action) => {
		return { ...state, countries_info: action.payload };
	},
	UPDATE_CURRENCIES_INFO: (state, action) => {
		return { ...state, currencies_info: action.payload };
	},
	UPDATE_NYLAS: (state, action) => {
		return { ...state, nylas: action.payload };
	},
	UPDATE_TM_SETTINGS: (state, action) => {
		return { ...state, settings: action.payload };
	},
	UPDATE_KPIS: (state, action) => {
		return { ...state, kpis: action.payload };
	},
	UPDATE_BILLING_SETTINGS: (state, action) => {
		return { ...state, subscription_settings: action.payload };
	},
	UPDATE_CREATION_LIMIT: (state, action) => {
		return { ...state, total_creation_numbers: action.payload };
	},
	DELETE_ALL: () => {
		return { ...GLOBAL_STATE, ...STORAGE_STATE };
	},
	// keeping this for next year
	SNOW_MODE: (state, action) => {
		return { ...state, snow: action.payload };
	},
};
export default GlobalHandlers;
