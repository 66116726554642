import React, { useState, useEffect } from "react";
import styled from "styled-components";

const RotateItem = ({ newPos, children }) => {
	const [classPosition, setClassPosition] = useState(newPos);

	useEffect(() => {
		setClassPosition(newPos);
	}, [newPos]);
	return <Span className={classPosition || ""}>{children}</Span>;
};

export default RotateItem;

const Span = styled.span`
	&.up {
		animation: spinUp 200ms linear forwards;
	}
	&.down {
		animation: spinDown 200ms linear forwards;
	}

	@keyframes spinUp {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(180deg);
		}
	}

	@keyframes spinDown {
		from {
			transform: rotate(180deg);
		}
		to {
			transform: rotate(0deg);
		}
	}
`;
