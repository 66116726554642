import { fetchSession, fetchUser, fetchSignOut, fetchFullUser } from "helpersV2/user";
import {
	fetchAllMyCompanies,
	fetchCompanyData,
	fetchTeamMembers,
	fetchRole,
	fetchCompanyApprovalProcess,
	fetchCompanyJobExtraFields,
	fetchFullCompanyData,
	fetchKPIs,
} from "helpersV2/company";
import { fetchCompanySources } from "helpersV2/sources";
import { fetchCompanyContactingTypes } from "helpersV2/ContactingTypes";
import { fetchCountriesInfo, fetchCurrenciesInfo } from "helpersV2/jsonFiles";
import {
	fetchSkills,
	fetchIndustries,
	fetchLocations,
	fetchDepartments,
	fetchBusinessAreas,
	fetchSectors,
} from "helpersV2/global";
import { fetchInterviewStages, fetchUpdateInterviewStages } from "helpersV2/interviews";
import { fetchTwilioUser } from "helpersV2/twilio";

import notify from "notifications";
import { ENV_SESSION_NAME } from "constants/api";
import { fetchGetIncompleteTasks } from "helpersV2/tasks";
import { getNylasAccount } from "helpersV2/nylas/auth";
import { getTmSettings } from "helpersV2/teamMembers/settings";
import {
	fetchBillingSettings,
	fetchFreeAccountCreationLimit,
	isProSubscription,
} from "helpersV2/subscriptions";
import i18next from "i18next";

export const checkAndCreateSession = (dispatch) => {
	let localStorageSession = JSON.parse(localStorage.getItem(ENV_SESSION_NAME));
	let cookiesSession = findCookies();
	if (localStorageSession) {
		dispatch({ type: "UPDATE_SESSION", payload: localStorageSession });
	} else if (cookiesSession) {
		dispatch({ type: "UPDATE_SESSION", payload: cookiesSession });
	}
	return !!(localStorageSession || cookiesSession);
};

const findCookies = () => {
	let cookiesArr = document.cookie.split(";");
	let cookiesObj = {};
	cookiesArr.map((cookieStr) => {
		let arr = cookieStr.split("=");
		cookiesObj[arr[0].trim()] = arr[1];
		return null;
	});
	if (cookiesObj[ENV_SESSION_NAME]) {
		return JSON.parse(cookiesObj[ENV_SESSION_NAME]);
	} else {
		return null;
	}
};

export const checkLastCompany = () => {
	return JSON.parse(localStorage.getItem("lastCompanyId"));
};

export const getUser = async (dispatch, session) => {
	return fetchUser(session, session.username).then((user) => {
		dispatch({ type: "UPDATE_MAIN_ENDPOINT_LOAD", payload: { user: true } });
		if (!user.err) {
			dispatch({ type: "UPDATE_USER", payload: user });
		} else {
			notify("danger", user);
		}
		return user;
	});
};

export const getFullUser = async (session, dispatch) => {
	return fetchFullUser(session, session.username).then((user) => {
		if (!user.err) {
			dispatch({ type: "UPDATE_FULL_USER", payload: user });
		} else {
			notify("danger", user);
		}
		return user;
	});
};

export const getAllMyCompanies = (dispatch, session) => {
	return fetchAllMyCompanies(session).then((companies) => {
		dispatch({
			type: "UPDATE_MAIN_ENDPOINT_LOAD",
			payload: { all_companies: true },
		});
		if (!companies.err && companies.length > 0) {
			dispatch({ type: "UPDATE_ALLMYCOMPANIES", payload: companies });
		} else if (!companies.err && companies.length === 0) {
			dispatch({ type: "UPDATE_ALLMYCOMPANIES", payload: companies });
			notify("warning", i18next.t("statusMessage.noCompaniesAddedToThisProfile"));
		} else if (companies.err) {
			notify("danger", companies);
		}
		return companies;
	});
};

export const getCompany = async (dispatch, session, companyMentionTag) => {
	return fetchCompanyData(session, companyMentionTag).then((company) => {
		if (!company.err) {
			dispatch({ type: "UPDATE_COMPANY", payload: company });
		} else {
			notify("danger", company);
		}
		return company;
	});
};
export const getFullCompany = async (dispatch, session, companyMentionTag) => {
	return fetchFullCompanyData(session, companyMentionTag).then((company) => {
		if (!company.err) {
			dispatch({ type: "UPDATE_FULL_COMPANY", payload: company });
		} else {
			notify("danger", company);
		}
		return company;
	});
};

export const updateCompany = async (store, companyMentionTag) => {
	return fetchCompanyData(store.session, companyMentionTag).then((company) => {
		if (!company.err) {
			let newCompanies = store.allMyCompanies.map((comp) => {
				if (comp.id === company.id) {
					return company;
				} else {
					return comp;
				}
			});
			store.dispatch({ type: "UPDATE_COMPANY", payload: company });
			store.dispatch({ type: "UPDATE_ALLMYCOMPANIES", payload: newCompanies });
		} else {
			notify("danger", company);
		}
		return company;
	});
};

export const addCompany = async (store, companyMentionTag) => {
	return fetchCompanyData(store.session, companyMentionTag).then((company) => {
		if (!company.err) {
			let newCompanies = [company, ...store.allMyCompanies];
			store.dispatch({ type: "UPDATE_COMPANY", payload: company });
			store.dispatch({ type: "UPDATE_ALLMYCOMPANIES", payload: newCompanies });
		} else {
			notify("danger", company);
		}
		return company;
	});
};

export const getTeamMembers = (dispatch, session, companyId) => {
	return fetchTeamMembers(session, companyId).then((team) => {
		if (!team.err) {
			dispatch({
				type: "UPDATE_TEAM_MEMBERS",
				payload: team.sort(sortMembers),
			});
		} else {
			notify("warning", team);
		}
		return team;
	});
};

const sortMembers = (a, b) => a.name?.localeCompare(b.name);

export const getRole = (dispatch, session, companyId) => {
	return fetchRole(session, companyId).then((role) => {
		dispatch({ type: "UPDATE_MAIN_ENDPOINT_LOAD", payload: { role: true } });
		if (!role.err) {
			dispatch({ type: "UPDATE_ROLE", payload: formatRole(role) });
		} else {
			notify("danger", role);
		}
		return role;
	});
};

const formatRole = (role) => {
	return {
		...role,
		permission: role.is_member,
		role_permissions: {
			is_member: role.is_member,
			master_owner: role.team_member.master_owner,
			owner: role.team_member.master_owner || role.team_member.permission === "owner",
			admin: role.team_member.permission === "admin",
			manager: role.team_member.permission === "manager",
			marketer:
				role.team_member.permission === "owner" || role.team_member.roles.includes("marketer"),
			recruiter:
				role.team_member.permission === "owner" || role.team_member.roles.includes("recruiter"),
			hiring_manager: role.team_member.roles.includes("hiring_manager"),
			business:
				role.team_member.permission === "owner" || role.team_member.roles.includes("business"),
		},
	};
};

export const getSession = async (dispatch, credentials) => {
	let response = await fetchSession(credentials).then((session) => {
		if (!session.err) {
			dispatch({ type: "UPDATE_SESSION", payload: session });
			//store session in localStorage
			localStorage.setItem(ENV_SESSION_NAME, JSON.stringify(session));
			//create session cookie
			document.cookie = `${ENV_SESSION_NAME}=${JSON.stringify(
				session
			)}; path=/; domain=${process.env.REACT_APP_DOMAIN}; max-age=${session.expiry}; secure;`;
		} else {
			notify("danger", session);
		}
		return true;
	});
	return response;
};

export const logOut = async (dispatch, session) => {
	fetchSignOut(session).then(() => {
		//clear session in localStorage
		localStorage.clear();
		//clear session cookie
		document.cookie = `${ENV_SESSION_NAME}=; path=/; domain=${process.env.REACT_APP_DOMAIN}; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure; samesite=lax;`;
		dispatch({ type: "DELETE_ALL" });
	});
};

export const deleteStore = async (dispatch) => {
	dispatch({ type: "DELETE_ALL" });
	//clear session in localStorage
	localStorage.clear();
	//clear session cookie
	document.cookie = `${ENV_SESSION_NAME}=; path=/; domain=${process.env.REACT_APP_DOMAIN}; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure; samesite=lax;`;
};

export const getSkills = async (dispatch, session, company_id) => {
	fetchSkills(session, company_id).then((skills) => {
		if (!skills.err) {
			dispatch({ type: "UPDATE_SKILLS", payload: skills });
		} else {
			notify("danger", skills);
		}
	});
};
export const getIndustries = async (dispatch, session, company_id) => {
	fetchIndustries(session, company_id).then((industries) => {
		if (!industries.err) {
			dispatch({ type: "UPDATE_INDUSTRIES", payload: industries });
		} else {
			notify("danger", industries);
		}
	});
};
export const getDepartments = async (dispatch, session, company_id) => {
	fetchDepartments(session, company_id).then((departments) => {
		if (!departments.err) {
			dispatch({ type: "UPDATE_DEPARTMENTS", payload: departments });
		} else {
			notify("danger", departments);
		}
	});
};
export const getBusinessAreas = async (dispatch, session, company_id) => {
	fetchBusinessAreas(session, company_id).then((areas) => {
		if (!areas.err) {
			dispatch({ type: "UPDATE_BUSINESS_AREAS", payload: areas });
		} else {
			notify("danger", areas);
		}
	});
};
export const getSectors = async (dispatch, session, company_id, team_member_id) => {
	// now it is tags not sectors
	fetchSectors(session, company_id, team_member_id).then((tags) => {
		if (!tags.err) {
			dispatch({ type: "UPDATE_TAGS", payload: tags });
		} else {
			notify("danger", tags);
		}
	});
};
export const getLocations = async (dispatch) => {
	fetchLocations().then((locations) => {
		if (!locations.err) {
			let locObj = {};
			locations.map((loc) => {
				if (!locObj[loc.name.toLowerCase()]) {
					locObj[loc.name.toLowerCase()] = loc;
				}
				return null;
			});
			dispatch({ type: "UPDATE_LOCATIONS", payload: Object.values(locObj) });
		} else {
			notify("danger", locations);
		}
	});
};

export const getInterviewStages = async (dispatch, session, companyId) => {
	return fetchInterviewStages(session, companyId).then((stages) => {
		if (!stages.err) {
			dispatch({ type: "UPDATE_INTERVIEW_STAGES", payload: stages });
		} else {
			notify("danger", stages);
		}
		return stages;
	});
};

export const updateInterviewStages = (store, newStages) => {
	return fetchUpdateInterviewStages(store.session, store.current_company_id, newStages).then(
		(stages) => {
			if (!stages.err) {
				if (stages.body.interview_stages) {
					store.dispatch({
						type: "UPDATE_INTERVIEW_STAGES",
						payload: stages.body.interview_stages,
					});
				}

				if (!store.company.interview_stages_edited) {
					updateCompany(store, store.company.mention_tag);
				}
			} else {
				notify("danger", stages);
			}
			return stages;
		}
	);
};

export const getCompanySources = (dispatch, session, companyId) => {
	return fetchCompanySources(session, companyId).then((res) => {
		if (!res.err) {
			dispatch({
				type: "UPDATE_SOURCES",
				payload: res,
			});
		} else {
			notify("danger", res);
			dispatch({
				type: "UPDATE_SOURCES",
				payload: undefined,
			});
		}
	});
};

export const getCompanyContactingTypes = (dispatch, session, companyId) => {
	return fetchCompanyContactingTypes(session, companyId).then((res) => {
		if (!res.err) {
			dispatch({
				type: "UPDATE_CONTACTING_TYPES",
				payload: res,
			});
		} else {
			notify("danger", res);
			dispatch({
				type: "UPDATE_CONTACTING_TYPES",
				payload: undefined,
			});
		}
	});
};

export const getTasksTotal = (dispatch, session) => {
	return fetchGetIncompleteTasks(session, session.id).then((res) => {
		if (!res.err && res.count !== undefined) {
			dispatch({
				type: "UPDATE_TOTALS",
				payload: { uncompleted_tasks: res.count },
			});
		}
	});
};

export const addNewAttribute = (attrArr = [], newItem) => {
	let attrArrCopy = [...attrArr];
	let match;
	let copy;
	let index = 0;
	while (index < attrArr.length && !match && !copy) {
		let item = attrArr[index];
		let nextItem = attrArrCopy[index + 1];
		if (item.id === newItem?.id || nextItem?.id === newItem?.id) {
			copy = true;
		} else {
			let comparison = item.name.localeCompare(newItem?.name);
			let comparisonNext = attrArrCopy[index + 1]?.name.localeCompare(newItem?.name);
			if ((comparison === -1 && comparisonNext === 1) || comparison === 0) {
				match = true;
			}
		}
		index++;
	}
	if (copy) return attrArr;
	if (!match) {
		attrArrCopy.push(newItem);
	}
	if (match) {
		attrArrCopy.splice(index, 0, newItem);
	}
	return attrArrCopy;
};

export const getApprovalProcess = (companyId, session, dispatch) => {
	return fetchCompanyApprovalProcess(session, companyId).then((res) => {
		if (!res.err) {
			dispatch({
				type: "UPDATE_APPROVAL_PROCESS",
				payload: res,
			});
		} else {
			notify("danger", res);
			dispatch({
				type: "UPDATE_APPROVAL_PROCESS",
				payload: undefined,
			});
		}
	});
};

export const getJobExtraFields = (companyId, session, dispatch) => {
	return fetchCompanyJobExtraFields(session, companyId).then((res) => {
		if (!res.err) {
			dispatch({
				type: "UPDATE_JOB_EXTRA_FIELDS",
				payload: res,
			});
		} else {
			notify("danger", res);
			dispatch({
				type: "UPDATE_JOB_EXTRA_FIELDS",
				payload: undefined,
			});
		}
	});
};

export const getTwilioUser = (companyId, session, dispatch) => {
	return fetchTwilioUser(session, companyId).then((res) => {
		if (!res.err) {
			dispatch({
				type: "UPDATE_TWILIO",
				payload: res,
			});
		} else {
			notify("danger", res);
			dispatch({
				type: "UPDATE_TWILIO",
				payload: undefined,
			});
		}
	});
};

export const getCountriesInfo = (dispatch) => {
	return fetchCountriesInfo().then((res) => {
		if (!res.err) {
			dispatch({
				type: "UPDATE_COUNTRIES_INFO",
				payload: res,
			});
		} else {
			notify("danger", res);
			dispatch({
				type: "UPDATE_COUNTRIES_INFO",
				payload: undefined,
			});
		}
	});
};
export const getCurrenciesInfo = (dispatch) => {
	return fetchCurrenciesInfo().then((res) => {
		if (!res.err) {
			dispatch({
				type: "UPDATE_CURRENCIES_INFO",
				payload: res,
			});
		} else {
			notify("danger", res);
			dispatch({
				type: "UPDATE_CURRENCIES_INFO",
				payload: undefined,
			});
		}
	});
};

export const fetchNylasAccount = async (companyId, session, dispatch) => {
	const nylasAccountData = await getNylasAccount(companyId, session);

	if (nylasAccountData?.error) {
		return dispatch({ type: "UPDATE_NYLAS", payload: undefined });
	}
	return dispatch({ type: "UPDATE_NYLAS", payload: nylasAccountData });
};

export const fetchTmSettings = async (companyId, teamMemberId, session, dispatch) => {
	const tmSettingsData = await getTmSettings(companyId, teamMemberId, session);

	if (tmSettingsData?.error) {
		return dispatch({ type: "UPDATE_TM_SETTINGS", payload: undefined });
	}
	return dispatch({ type: "UPDATE_TM_SETTINGS", payload: tmSettingsData });
};

export const getKPIS = async (companyId, teamMemberId, session, teamMembers, dispatch) => {
	return fetchKPIs(companyId, teamMemberId, session).then((res) => {
		if (!res.err) {
			let ids = teamMembers.map((member) => member.team_member_id);
			let activekpis = {};
			Object.keys(res).map((key) => {
				if (ids.indexOf(Number(key)) > -1) {
					activekpis[key] = res[key];
				}
			});
			dispatch({
				type: "UPDATE_KPIS",
				payload: activekpis,
			});
		} else {
			notify("danger", res);
			dispatch({
				type: "UPDATE_KPIS",
				payload: undefined,
			});
		}
	});
};

export const getBillingSettings = async (companyId, session, dispatch) => {
	return fetchBillingSettings(companyId, session)
		.then((res) => {
			if (!res.err) {
				dispatch({
					type: "UPDATE_BILLING_SETTINGS",
					payload: {
						...res,
						items: res.items ?? res.__items__,
						is_pro: isProSubscription(res.status),
					},
				});
			} else {
				dispatch({
					type: "UPDATE_BILLING_SETTINGS",
					payload: { status: "free", is_pro: false },
				});
			}
		})
		.catch(() => {
			dispatch({
				type: "UPDATE_BILLING_SETTINGS",
				payload: { status: "free", is_pro: false },
			});
		});
};

export const getAllowance = async (company_id, session, dispatch) => {
	return fetchFreeAccountCreationLimit(company_id, session)
		.then((res) => {
			if (!res.err) {
				dispatch({
					type: "UPDATE_CREATION_LIMIT",
					payload: res,
				});
			} else {
				dispatch({
					type: "UPDATE_CREATION_LIMIT",
					payload: {
						users: 1,
						jobs: 10,
						candidates: 200,
						clients: 10,
						contacts: 20,
						deals: 10,
						pipelines: 1,
					},
				});
			}
		})
		.catch(() => {
			dispatch({
				type: "UPDATE_CREATION_LIMIT",
				payload: {
					users: 1,
					jobs: 10,
					candidates: 200,
					clients: 10,
					contacts: 20,
					deals: 10,
					pipelines: 1,
				},
			});
		});
};

export const openSubscriptionModal = (store, payload) => {
	store.dispatch({
		type: "OPEN_SUBSCRIPTION_MODAL",
		payload: payload === false ? false : true,
	});
};

export const refreshSubscriptionData = async (store) => {
	await getBillingSettings(store.current_company_id, store.session, store.dispatch);
	await getTeamMembers(store.dispatch, store.session, store.current_company_id);
};
