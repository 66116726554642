import React from "react";
import styled from "styled-components";
import AvatarIcon from "sharedComponents/AvatarIcon";
// import { generateReplaceString } from "sharedComponents/unbiased/helpers";

const UnbiasedAvatar = ({
	name,
	imgUrl,
	size,
	shape,
	style,
	className,
	colors,
	props,
	is_unbiased,
}) => {
	return (
		<UnbiasedWrapper size={size} shape={shape} className={className}>
			<AvatarIcon
				name={is_unbiased ? undefined : name}
				imgUrl={imgUrl}
				size={size}
				shape={shape}
				style={style}
				className={is_unbiased && imgUrl ? "leo-unbiased-strong" : ""}
				colors={colors}
				{...props}
			/>
		</UnbiasedWrapper>
	);
};

const UnbiasedWrapper = styled.div`
	border-radius: ${(props) => (props.shape === "square" ? "4px" : "50%")};
	height: ${(props) => props.size}px;
	min-height: ${(props) => props.size}px;
	min-width: ${(props) => props.size}px;
	width: ${(props) => props.size}px;
	overflow: hidden;
`;

export default UnbiasedAvatar;
