import { useReducer } from "react";
import GLOBAL_STATE, { STORAGE_STATE } from "contexts/globalContext/GlobalState";
import GlobalHandlers from "contexts/globalContext/GlobalHandlers";
import StorageHandlers from "contexts/globalContext/StorageHandlers";
import { createContainer } from "react-tracked";

const GlobalReducer = (state, action) => {
	let handlers = { ...GlobalHandlers, ...StorageHandlers };
	if (handlers.hasOwnProperty(action.type)) {
		return handlers[action.type](state, action);
	} else {
		return state;
	}
};

const useValue = () => useReducer(GlobalReducer, { ...GLOBAL_STATE, ...STORAGE_STATE });
export const {
	Provider: GlobalProvider,
	useTrackedState: useStore,
	useUpdate: useDispatch,
} = createContainer(useValue);
