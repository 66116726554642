import { useStore, useDispatch } from "contexts/historyContext/HistoryProvider";

const useHistoryContext = () => {
	const store = useStore();
	const dispatch = useDispatch();

	return { ...store, dispatch };
};

export default useHistoryContext;
