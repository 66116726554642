import React, { useState, useEffect } from "react";
import useGlobalContext from "contexts/globalContext/GlobalContext";
import { ENVIRONMENT_NAME } from "constants/api";

export const allowAntonio = (session) => {
	if (session) {
		if (ENVIRONMENT_NAME === "development" || ENVIRONMENT_NAME === "staging") {
			if (session.id === 12) { //&& session.username === "danpvernon"
				return true;
			}
		} else if (ENVIRONMENT_NAME === "production") {
			if (session.id === 12) { //&& session.username === "antoniogiugno"
				return true;
			}
		} else {
			return false;
		}
	} else {
		return false;
	}
};

export const AllowAntonio = ({ children }) => {
	const [isAllowed, setIsAllowed] = useState(false);
	const store = useGlobalContext();

	useEffect(() => {
		setIsAllowed(allowAntonio(store.session));
	}, [store.session]);

	return <>{isAllowed ? children : null}</>;
};
