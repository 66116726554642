import queryString from "query-string";
import { API_ROOT_PATH } from "constants/api";
import { NYLAS_AUTH_URL, NYLAS_CLIENT_ID } from "constants/nylas";

const getNylasAccount = async (companyId, session) => {
	const endpoint = `${API_ROOT_PATH}/v2/companies/${companyId}/our_nylas/auth/account`;
	const options = { headers: session };

	try {
		const accountResponse = await (await fetch(endpoint, options)).json();

		if (accountResponse?.error) throw new Error();

		return accountResponse;
	} catch (error) {
		return { error: true, data: error };
	}
};

const authNylasAccount = async (code, companyId, session) => {
	const endpoint = `${API_ROOT_PATH}/v2/companies/${companyId}/our_nylas/auth/token?code=${code}`;
	const options = { headers: session };

	try {
		const accountResponse = await (await fetch(endpoint, options)).json();

		if (accountResponse?.error) throw new Error();

		return accountResponse;
	} catch (error) {
		return { error: true, data: error };
	}
};

const logoutNylasAccount = async (companyId, session) => {
	const endpoint = `${API_ROOT_PATH}/v2/companies/${companyId}/our_nylas/auth/cancel_account`;
	const options = { method: "post", headers: session };
	try {
		const logoutResponse = await (await fetch(endpoint, options)).json();

		if (logoutResponse?.error) throw new Error();

		return logoutResponse;
	} catch (error) {
		return { error: true, data: error };
	}
};

// TeamMember that have connected account
const getNylasAuthenticatedMembers = async (companyId, session) => {
	const endpoint = `${API_ROOT_PATH}/v2/companies/${companyId}/our_nylas/auth/members`;
	const options = { headers: session };

	try {
		return await (await fetch(endpoint, options)).json();
	} catch (error) {
		return { error: true, data: error };
	}
};

// ${calendar ? `?page=calendar` : ""}
const getNylasAuthUrl = () => {
	const query = queryString.stringify({
		client_id: NYLAS_CLIENT_ID,
		redirect_uri: `${process.env.REACT_APP_NYLAS_REDIRECT_URI}`,
		response_type: "code",
		scopes: "email.modify, email.send, calendar",
	});
	return `${NYLAS_AUTH_URL}?${query}`;
};

export {
	getNylasAccount,
	authNylasAccount,
	getNylasAuthUrl,
	logoutNylasAccount,
	getNylasAuthenticatedMembers,
};
