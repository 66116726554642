import React from "react";
import retryLazy from "hooks/retryLazy";
import RouteConstructor from "routes/RouteConstructor";

const TasksManager = React.lazy(() => retryLazy(() => import("containers/TasksManager")));
const PERSONAL_SETTINGS_ROUTES = {
	TasksManager: {
		path: "/profile/tasks/:complete?",
		render: (args) => (
			<RouteConstructor
				inner={true}
				falseAts={true}
				activeTab="tasks"
				routeObject={PERSONAL_SETTINGS_ROUTES.TasksManager}
				{...args}
			>
				<TasksManager {...args} />
			</RouteConstructor>
		),
		url: (complete) => `/profile/tasks/${complete}`,
	},
};
export default PERSONAL_SETTINGS_ROUTES;
