import React, { Suspense } from "react";
import retryLazy from "hooks/retryLazy";
import RouteConstructor from "routes/RouteConstructor";
import Fallback from "styles/Fallback";

// const CreateAgency = React.lazy(() =>
//   retryLazy(() => import("containers/CreateAgency"))
// );
const CreateEmployer = React.lazy(() =>
  retryLazy(() => import("containers/CreateEmployer"))
);
const ProfessionalSignin = React.lazy(() =>
	retryLazy(() => import("containers/ProfessionalSignin"))
);
const CompanySignup = React.lazy(() => retryLazy(() => import("containers/CompanySignup")));
const ProfessionalLogoff = React.lazy(() =>
	retryLazy(() => import("containers/ProfessionalLogoff"))
);
const ForgotPassword = React.lazy(() => retryLazy(() => import("containers/ForgotPassword")));
const ResetPassword = React.lazy(() => retryLazy(() => import("containers/ResetPassword")));
const NewPassword = React.lazy(() => retryLazy(() => import("containers/NewPassword")));
const NylasAuthRedirect = React.lazy(() =>
	retryLazy(() => import("containers/Nylas/NylasAuthRedirect"))
);

const SIGNING_ROUTES = {
	ProfessionalSignin: {
		path: "/signin",
		render: (args) => (
			<RouteConstructor {...args}>
				<ProfessionalSignin {...args} />
			</RouteConstructor>
		),
		url: () => "/signin",
	},
	CompanySignupPro: {
		path: "/signup_pro",
		render: (args) => (
			<RouteConstructor {...args}>
				<CompanySignup {...args} />
			</RouteConstructor>
		),
		url: () => "/signup_pro",
	},
	CompanySignupFree: {
		path: "/signup_free",
		render: (args) => (
			<RouteConstructor {...args}>
				<CompanySignup {...args} />
			</RouteConstructor>
		),
		url: () => "/signup_free",
	},
	ProfessionalLogoff: {
		path: "/logoff",
		render: (args) => (
			<RouteConstructor inner={true} {...args}>
				<ProfessionalLogoff />
			</RouteConstructor>
		),
		url: () => "/logoff",
	},
	ForgotPassword: {
		path: "/forgotpassword",
		render: (args) => (
			<RouteConstructor {...args}>
				<ForgotPassword {...args} />
			</RouteConstructor>
		),
		url: () => "/forgotpassword",
	},
	ResetPassword: {
		path: "/resetpassword",
		render: (args) => (
			<RouteConstructor {...args}>
				<ResetPassword {...args} />
			</RouteConstructor>
		),
		url: () => "/resetpassword",
	},
	NewPassword: {
		path: "/newpassword",
		render: (args) => (
			<RouteConstructor inner={true} {...args}>
				<NewPassword {...args} />
			</RouteConstructor>
		),
		url: () => "/newpassword",
	},
	CreateEmployer: {
		path: "/create-employer",
		render: (args) => (
			<RouteConstructor {...args}>
				<CreateEmployer args={args} {...args} />
			</RouteConstructor>
		),
		url: () => "/create-employer",
	},
	// CreateAgency: {
	// 	path: "/create-agency",
	// 	render: (args) => (
	// 		<RouteConstructor inner={true} {...args}>
	// 			<CreateAgency args={args} {...args} />
	// 		</RouteConstructor>
	// 	),
	// 	url: () => "/create-agency",
	// },
	NylasAuthRedirect: {
		path: "/nylas/auth/redirect",
		render: () => (
			<Suspense fallback={<Fallback />}>
				<NylasAuthRedirect />
			</Suspense>
		),
		url: () => "/nylas/auth/redirect",
	},
};
export default SIGNING_ROUTES;
