import React, { Suspense, useState } from "react";
import { GlobalProvider } from "contexts/globalContext/GlobalProvider";
import { HistoryProvider } from "contexts/historyContext/HistoryProvider";
import { CalendarContextProvider } from "contexts/calendarContext/calendarProvider";
import ReactNotification from "react-notifications-component";
import { ROUTES, REDIRECTS } from "routes";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import MainPageFetcher from "PageWrappers/MainPageFetcher";
import InnerPage from "PageWrappers/InnerPage";
import Fallback from "styles/Fallback";
import retryLazy from "hooks/retryLazy";
import { IntercomProvider } from "react-use-intercom";
//ATS PAGES

// const CompanyCreate = React.lazy(() => retryLazy(()=> import("containers/OnboardFlow/PayingOnboardFlow"))
//
// );

//USER PAGES

//GDPR PAGES
// const Cookies = React.lazy(() => retryLazy(()=> import("containers/Cookies")) );
// const Privacy = React.lazy(() => retryLazy(()=>import("containers/Privacy") ) );
// const Terms = React.lazy(() => retryLazy(()=> import("containers/Terms")) );

//OTHER PAGES
const NotFound = React.lazy(() => retryLazy(() => import("containers/NotFound")));
const App = () => {
	const [unreadIntercom, setUnreadIntercom] = useState(0);
	const redirectsArr = Object.values(REDIRECTS);
	const routesArr = Object.values(ROUTES);
	return (
		<GlobalProvider>
			<HistoryProvider>
				<CalendarContextProvider>
					<IntercomProvider
						appId={process.env.REACT_APP_INTERCOM}
						onUnreadCountChange={(val) => setUnreadIntercom(val)}
					>
						<ReactNotification />
						<BrowserRouter>
							<MainPageFetcher unreadIntercom={unreadIntercom}>
								<Switch>
									<Route exact path="/" render={(args) => <InnerPage {...args} />} />
									{redirectsArr.map((red, ix) => (
										<Redirect to={red.to} from={red.from} key={`redirect-${ix}`} />
									))}
									{routesArr.map((route, index) => (
										<Route exact path={route.path} render={route.render} key={`route-${index}`} />
									))}
									<Route
										render={(args) => (
											<InnerPage {...args}>
												<Suspense fallback={<Fallback />}>
													<NotFound />
												</Suspense>
											</InnerPage>
										)}
									/>
								</Switch>
							</MainPageFetcher>
						</BrowserRouter>
					</IntercomProvider>
				</CalendarContextProvider>
			</HistoryProvider>
		</GlobalProvider>
	);
};

export default App;
