import { API_ROOT_PATH } from 'constants/api';

const elasticEndpoint = (companyId) => `${API_ROOT_PATH}/v2/companies/${companyId}/elastic`;
const performFetch = async (endpoint, options) => {
  try {
    const responseBody = await (await fetch(endpoint, options)).json()
    if (responseBody?.error) throw responseBody;

    return responseBody
  } catch (error) {
    return { error: true, data: error.data, message: error.message }
  }
}

const searchCount = async (payload, companyId, session, signal) => {
  const endpoint = `${elasticEndpoint(companyId)}/count`;
  const options = { method: 'post', headers: session, body: JSON.stringify(payload), signal };
  
  return await performFetch(endpoint, options);
}

const searchPreview = async (payload, companyId, session, signal) => {
  const endpoint = `${elasticEndpoint(companyId)}/search_preview`;
  const options = { method: 'post', headers: session, body: JSON.stringify(payload), signal };

  return await performFetch(endpoint, options);
}

const searchIndex = async (index, payload, companyId, session) => {
  const endpoint = `${elasticEndpoint(companyId)}/${index}/search`;
  const options = { method: 'post', headers: session, body: JSON.stringify(payload) };

  return await performFetch(endpoint, options);
}

export { searchCount, searchPreview, searchIndex }