import React from "react";
import styled from "styled-components";

export const CloseBtn = () => (
	<CloseButton>
		<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.326009 0.326009C-0.10867 0.760688 -0.10867 1.46544 0.32601 1.90012L8.65627 10.2304L0.784584 18.1021C0.349905 18.5368 0.349905 19.2415 0.784584 19.6762C1.21926 20.1109 1.92402 20.1109 2.3587 19.6762L10.2304 11.8045L17.6412 19.2154C18.0759 19.65 18.7807 19.65 19.2154 19.2154C19.65 18.7807 19.65 18.0759 19.2154 17.6412L11.8045 10.2304L19.6739 2.36095C20.1086 1.92627 20.1086 1.22152 19.6739 0.78684C19.2393 0.352161 18.5345 0.352162 18.0998 0.786841L10.2304 8.65627L1.90012 0.326009C1.46544 -0.10867 0.760688 -0.10867 0.326009 0.326009Z"
				fill="#B0BDCA"
			/>
		</svg>
	</CloseButton>
);

export default CloseBtn;

const CloseButton = styled.div`
	img {
		height: 12px;
		width: 12px;
	}
`;
