import styled from "styled-components";
import { COLORS } from "constants/style";
import { BREAKPOINTS } from "constants/breakpoints";

// export const MasterContainer = styled.div`
// 	max-width: 940px;
// 	margin-right: auto;
// 	margin-left: auto;
// 	padding-right: 15px;
// 	padding-left: 15px;
// 	width: 100%;
//
// 	@media screen and (max-width: 768px) {
// 		max-width: 720px;
// 	}
// 	@media screen and (max-width: 576px) {
// 		max-width: 540px;
// 	}
// `;

export const InnerPageContainer = styled.div.attrs((props) => ({
	className: (props.className || "") + " leo-flex-center",
}))`
	flex-direction: column;
	min-height: 100vh;
	padding-bottom: 150px;
	width: 100%;
	background-color: ${COLORS.white};
`;

// max-width: 1255px;
export const ATSContainer = styled.div`
	width: 100%;
	padding-left: 55px;
	padding-right: 35px;
	max-width: 1345px;
	margin-left: auto;
	margin-right: auto;

	@media ${BREAKPOINTS.for_phone_only} {
		padding-left: 20px;
		padding-right: 20px;
	}
`;

export const ProfilePageContainer = styled.div.attrs((props) => ({
	className: (props.className || "") + " leo-flex-center",
}))`
	flex-direction: column;
	padding: 0;
	background: ${COLORS.white};
	max-height: calc(100vh - 50px);
	width: 100%;

	&.grey-container {
		background: #eee;
	}
`;
