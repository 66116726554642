import React, { useEffect } from "react";
import { withRouter } from "react-router";
import Unauthorized from "components/Unauthorized.js";
import { ROUTES } from "routes";
import useGlobalContext from "contexts/globalContext/GlobalContext";
import useHistoryContext from "contexts/historyContext/HistoryContext";
import { PermissionChecker } from "constants/permissionHelpers";
import SubscriptionChecker from "sharedComponents/SubscriptionChecker";
import UpgradeModal from "modals/UpgradeModal";

const ATSWrapper = ({ activeTab, children, match, routeObject }) => {
	const store = useGlobalContext();
	const historyStore = useHistoryContext();
	// const INVITED_BY_AGENCY_ALLOWED_PAGES = {
	// 	[ROUTES.Vendors.path]: true,
	// 	[ROUTES.VendorPage.path]: true,
	// 	[ROUTES.JobDashboard.path]: true,
	// 	[ROUTES.TempJobDashboard.path]: true,
	// 	[ROUTES.CandidateProfile.path]: true,
	// };

	useEffect(() => {
		historyStore.dispatch({
			type: "UPDATE_ACTIVE_TAB",
			payload: activeTab,
		});
	}, [activeTab]);

	//IF COMPANY IN STORE AND COMPANY IN STORE IS THE SAME AS THE URL
	if (store.company && store.company.mention_tag === match.params.companyMentionTag && store.role) {
		return (
			<>
				{store.role && (
					<PermissionChecker
						valid={routeObject?.role_permissions}
						type="view"
						onFalse={() => <Unauthorized />}
					>
						<SubscriptionChecker
							valid={routeObject?.subscriptions_view}
							onFalse={() => <UpgradeModal noModal={true} store={store} show={false} />}
						>
							{children || <div />}
						</SubscriptionChecker>
					</PermissionChecker>
				)}
			</>
		);
	} else if (
		historyStore &&
		historyStore.current &&
		historyStore.current.match.path === ROUTES.MyCompanies.path
	) {
		return <>{children || <div />}</>;
	} else {
		return null;
	}
};

export default withRouter(ATSWrapper);
