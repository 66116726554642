import { useMemo } from "react";
import useGlobalContext from "contexts/globalContext/GlobalContext";

export const showBlurred = (candidate, teamMember, company) => {
	if (
		company.is_unbiased &&
		teamMember.is_unbiased &&
		(candidate.is_unbiased || candidate.candidates_is_unbiased) &&
		candidate.biased_member_ids?.indexOf(teamMember.team_member_id) === -1
	) {
		return true;
	}
	return false;
};

export const isUnbiasedHook = (candidate, teamMember) => {
	const { role, company } = useGlobalContext();

	const is_unbiased = useMemo(
		() =>
			candidate && (teamMember || role) && company
				? showBlurred(candidate, teamMember || role?.team_member, company)
				: false,
		[candidate, role, company, teamMember]
	);

	return is_unbiased;
};

const FILLER =
	"•••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••";
export const generateReplaceString = (str = "") =>
	`${str}`
		.split(" ")
		.map((sub) => FILLER.substring(0, sub.length))
		.join(" ");
