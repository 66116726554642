import { useStore, useDispatch } from "contexts/globalContext/GlobalProvider";

const useGlobalContext = () => {
	const store = useStore();
	const dispatch = useDispatch();

	return { ...store, dispatch };
};

export default useGlobalContext;
