import React from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

const scrollbarOptions = {
	className: "os-theme-thin-dark",
	scrollbars: {
		visibility: "auto",
		autoHide: "move",
		autoHideDelay: 500,
		dragScrolling: true,
		clickScrolling: true,
	},
};

// Only needs to wrap over a component with max size set.
// The component it is wrapping should not have an overflow set, scrollbar takes care of in
// Sometimes the scrollbar needs to be wrapped withing an empty div (modal and dropdowns mainly)
const Scrollbar = ({ children, style, callbacks }) => {
	return (
		<OverlayScrollbarsComponent options={{ ...scrollbarOptions, callbacks }} style={style}>
			{children}
		</OverlayScrollbarsComponent>
	);
};

export default Scrollbar;
