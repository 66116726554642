import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import notify from "notifications";

const { S3Client, GetObjectCommand } = require("@aws-sdk/client-s3");

const client = new S3Client({
	region: "eu-west-2",
	credentials: {
		accessKeyId: process.env.REACT_APP_MICROSERVICES_AWS_ACCESSKEY,
		secretAccessKey: process.env.REACT_APP_MICROSERVICES_AWS_SECRET_ACCESSKEY,
	},
});

export const getSignedURL = async (url, BUCKET, setUrl, showError, errorText) => {
	if (!url.includes("https://")) return url;
	if (url.includes(`https://${BUCKET}`)) return url;
	const key = url.split(`/${BUCKET}/`)[1].split("?")[0];
	const command = new GetObjectCommand({ Bucket: BUCKET, Key: decodeURIComponent(key) });
	try {
		const url = await getSignedUrl(client, command, { expiresIn: 3600 });
		if (setUrl) setUrl(url);
		return url;
	} catch (error) {
		console.log(error);
		if (showError) {
			notify("danger", errorText || "Unable to fetch resource.");
		}
		return url;
	}
};

// export const getSignedURL = async (url, BUCKET, setUrl, showError, errorText) => {
// 	const tmpURL = url;
// 	setUrl(tmpURL);
// 	if (!url.includes("https://")) return url;
// 	if (url.includes(`https://${BUCKET}`)) return url;

// 	const key = url.split(`/${BUCKET}/`)[1].split("?")[0];
// 	const command = new GetObjectCommand({ Bucket: BUCKET, Key: decodeURIComponent(key) });
// 	try {
// 		const url = await getSignedUrl(client, command, { expiresIn: 3600 });
// 		// if (setUrl) setUrl(url);
// 		return url;
// 	} catch (error) {
// 		console.log(error);
// 		if (showError) {
// 			notify("danger", errorText || "Unable to fetch resource.");
// 		}
// 		// return url;
// 	}
// 	return tmpURL;
// };
