import React, { useEffect, useState, memo, useMemo, Suspense } from "react";
import { ROUTES } from "routes";
import { Redirect } from "react-router-dom";
import useGlobalContext from "contexts/globalContext/GlobalContext";
import useHistoryContext from "contexts/historyContext/HistoryContext";
import AuthHeader from "components/AuthHeader";
import ATSNavbar from "components/ATSNavbar";
import IntercomComponent from "components/IntercomComponent";
import SentryComponent from "components/SentryComponent";
import MainLoadingScreen from "styles/MainLoadingScreen";
// import ClickDetector from "sharedComponents/ClickDetector";
import { getLocalStorageSearches } from "contexts/globalContext/StorageMethods";
import { showBlurred } from "sharedComponents/unbiased/helpers";
import { notifySMS } from "notifications";
import { openSubscriptionModal } from "contexts/globalContext/GlobalMethods";
import UpgradeModal from "modals/UpgradeModal";
//comment
// import LogRocket from "logrocket";
import spacetime from "spacetime";
import {
	getAllMyCompanies,
	getUser,
	checkAndCreateSession,
	getTeamMembers,
	getRole,
	getSkills,
	getIndustries,
	getBusinessAreas,
	getDepartments,
	getSectors,
	// getLocations,
	// getCurrencies,
	logOut,
	getInterviewStages,
	getCompanySources,
	// getCompanyContactingTypes,
	getApprovalProcess,
	getJobExtraFields,
	getTwilioUser,
	getCountriesInfo,
	getCurrenciesInfo,
	fetchNylasAccount,
	fetchTmSettings,
	getTasksTotal,
	getKPIS,
	getBillingSettings,
	getAllowance,
} from "contexts/globalContext/GlobalMethods";
import { connectWebSocket } from "websocket/index";
import {
	connectSkills,
	connectIndustries,
	connectDepartments,
	connectBusinessAreas,
	connectAllowance,
	// connectSectors,
} from "websocket/attributes";
import { connectGetUnreadMessageNotifications } from "websocket/twilio";
import { fetchUpdateUser } from "helpersV2/user";
// import { connectConversation } from "websocket/messagesLegacy";
import Fallback from "styles/Fallback";
import retryLazy from "hooks/retryLazy";
// import Snow from "sharedComponents/Snow";

const CandidateMiniProfile = React.lazy(() =>
	retryLazy(() => import("modals/CandidateMiniProfileModal"))
);

let tasks_interval;

const MainPageFetcher = ({ children, unreadIntercom }) => {
	const store = useGlobalContext();
	const historyStore = useHistoryContext();
	const navBarExceptions = useMemo(() => {
		return { [ROUTES.NewPassword?.path]: true };
	}, []);
	const [updateTried, setUpdateTried] = useState(false);
	const [redirect, setRedirect] = useState(undefined);
	const [activeModal, setActiveModal] = useState(undefined);
	const [activeCandidate, setActiveCandidate] = useState(undefined);
	// CHECK IF SESSION IS STORED IN LOCALSTORAGE OR COOKIES
	useEffect(() => {
		if (!store.session && historyStore.current?.match.path !== ROUTES.ProfessionalLogoff.path) {
			checkAndCreateSession(store.dispatch);
		}
	}, [store.session]);

	//initiate websocket channel connection
	useEffect(() => {
		if (store.session) {
			connectWebSocket(store.session);
		}
	}, [store.session]);

	useEffect(() => {
		if (store.session) {
			getLocalStorageSearches(store.dispatch);
		}
	}, [store.session]);

	//connect attributes
	useEffect(() => {
		if (store.current_company_id && store.session) {
			getSkills(store.dispatch, store.session, store.current_company_id);
			connectSkills(store);
			getIndustries(store.dispatch, store.session, store.current_company_id);
			connectIndustries(store);
			getDepartments(store.dispatch, store.session, store.current_company_id);
			connectDepartments(store);
			getBusinessAreas(store.dispatch, store.session, store.current_company_id);
			connectBusinessAreas(store);
			getAllowance(store.current_company_id, store.session, store.dispatch);
			connectAllowance(store);
		}
	}, [store.current_company_id, store.session]);

	useEffect(() => {
		if (store.current_company_id && store.session && store.role) {
			getSectors(
				store.dispatch,
				store.session,
				store.current_company_id,
				store.role.team_member.team_member_id
			);
		}
	}, [store.current_company_id, store.session, store.role]);

	useEffect(() => {
		getCountriesInfo(store.dispatch);
		getCurrenciesInfo(store.dispatch);
	}, [store.session]);

	// ONCE STORE HAS SESSION, GET USER AND ALLMYCOMPANIES
	useEffect(() => {
		if (store.session) {
			getUser(store.dispatch, store.session).then((res) => {
				if (res.err) {
					logOut(store.dispatch, store.session);
				}
			});
			getAllMyCompanies(store.dispatch, store.session).then((res) => {
				if (res.err) {
					logOut(store.dispatch, store.session);
				}
			});
		}
	}, [store.session, store.dispatch]);

	// ONCE COMPANY IS SET, FETCH TEAM_MEMBERS AND PERMISSIONS
	useEffect(() => {
		if (store.current_company_id && store.session) {
			getCompanySources(store.dispatch, store.session, store.current_company_id);
			getTeamMembers(store.dispatch, store.session, store.current_company_id);
			getRole(store.dispatch, store.session, store.current_company_id);
			getInterviewStages(store.dispatch, store.session, store.current_company_id);
			getApprovalProcess(store.current_company_id, store.session, store.dispatch);
			getJobExtraFields(store.current_company_id, store.session, store.dispatch);
			getTwilioUser(store.current_company_id, store.session, store.dispatch);
			getBillingSettings(store.current_company_id, store.session, store.dispatch);
		}
	}, [store.current_company_id, store.session, store.dispatch]);

	useEffect(() => {
		if (store.role && store.current_company_id && store.session) {
			fetchTmSettings(
				store.current_company_id,
				store.role.team_member.team_member_id,
				store.session,
				store.dispatch
			);
			fetchNylasAccount(store.current_company_id, store.session, store.dispatch);
		}
	}, [store.role, store.current_company_id, store.session]);

	useEffect(() => {
		if (store.role && store.current_company_id && store.session && store.teamMembers) {
			getKPIS(
				store.current_company_id,
				store.role.team_member.team_member_id,
				store.session,
				store.teamMembers,
				store.dispatch
			);
		}
	}, [store.role, store.current_company_id, store.session, store.teamMembers]);

	//check timezone
	useEffect(() => {
		if (store.user && !updateTried) {
			let timezone = spacetime().timezone().name;
			if (store.user.timezone !== timezone) {
				setUpdateTried(true);
				fetchUpdateUser(store.session, store.user.id, { timezone }).then((res) => {
					if (!res.err) {
						getUser(store.dispatch, store.session);
					}
				});
			}
		}
	}, [store.user, updateTried]);

	//TOTAL TASKS
	useEffect(() => {
		if (store.session) {
			getTasksTotal(store.dispatch, store.session);
			tasks_interval = setInterval(function () {
				if (!document.hidden) {
					getTasksTotal(store.dispatch, store.session);
				}
			}, 300000);
		}
		return () => clearInterval(tasks_interval);
	}, [store.session, store.dispatch]);

	//connect twilio messages
	useEffect(() => {
		if (store.role && store.role.team_member) {
			connectGetUnreadMessageNotifications(store);
		}
	}, [store.role]);

	//notify twilio messages
	useEffect(() => {
		if (store.unread_message_notifications) {
			Object.values(store.unread_message_notifications).map((candidate) => {
				if (
					historyStore.current.match.path !== ROUTES.CandidateProfile.path &&
					historyStore.current.match.params.tnProfileId !==
						`${candidate?.candidate_professional_id}`
				) {
					showNewMessage(candidate);
				}
			});
			store.dispatch({ type: "CLEAR_UNREAD_MESSAGE_NOTIFICATIONS" });
		}
	}, [store.unread_message_notifications]);

	const showNewMessage = (candidate) => {
		const is_unbiased = showBlurred(candidate, store.role.team_member, store.company);
		notifySMS(
			"candidate",
			`You got ${
				candidate.total_new_messages > 1
					? `${candidate.total_new_messages} new messages`
					: "a new message"
			} from ${is_unbiased ? candidate.unbiased_identifier : candidate.candidate_name}.`,
			{
				name: candidate.candidate_name,
				avatar: candidate.candidate_avatar_url,
				ptn_id: candidate.ptn_id,
				is_unbiased: is_unbiased,
			},
			() => {
				setActiveModal("candidate-mini-profile");
				setActiveCandidate({
					tnProfileId: candidate.candidate_professional_id,
				});
			}
		);
	};

	const closeModal = () => {
		setActiveModal(undefined);
		setActiveCandidate(undefined);
	};

	useEffect(() => {
		if (redirect) {
			setRedirect(undefined);
		}
	}, [redirect]);

	// useEffect(() => {
	// 	if (
	// 		store.role &&
	// 		store.company &&
	// 		store.user &&
	// 		process.env.NODE_ENV !== "development" &&
	// 		process.env.NODE_ENV !== "staging"
	// 	) {
	// 		LogRocket.identify(`${store.user?.name} - prof_id: ${store.session.id}`, {
	// 			id: store.session.id,
	// 			company_name: store.company.name,
	// 			company_id: store.company.id,
	// 			company_mention_tag: store.company.mention_tag,
	// 			avatar: store.user.avatar,
	// 			team_member_id: store.role.team_member.team_member_id,
	// 			...store.role.role_permissions,
	// 		});
	// 	}
	// }, [store.role, store.company, store.user]);
	const showIntercom = false;

	return (
		<>
			<MainLoadingScreen historyStore={historyStore} />
			{store.session &&
				store.user &&
				store.allMyCompanies &&
				!navBarExceptions[historyStore.current?.match.path] && <AuthHeader />}
			<ATSNavbar>{children}</ATSNavbar>
			{showIntercom && <IntercomComponent store={store} unreadIntercom={unreadIntercom} />}
			<SentryComponent store={store} />
			{/* kepping here for next year */}
			{/* <Snow store={store}/> */}
			{/*}<ClickDetector />*/}
			<div id="new-email-target" />
			<div id="card-portal-target" />
			<div id="side-modal-target" />
			<div id="action-menu-target" />
			<div id="intercom-button-target" />
			<div id="video-review-target" />
			<div id="modal-helper-target" />
			{/*}<button
				style={{
					position: "sticky",
					bottom: "10px",
					right: "50%",
					left: "50%",
					zIndex: "200",
					background: "red",
				}}
				onClick={() => {
					store.dispatch({
						type: "UPDATE_UNREAD_MESSAGE_NOTIFICATIONS",
						payload: {
							4796: {
								candidate_name: "KPI-2",
								candidate_avatar_url: null,
								candidate_professional_id: 10050,
								is_unbiased: false,
								biased_member_ids: [],
								total_new_messages: 7,
							},
						},
					});
				}}
			>
				NOTIFY
			</button>*/}
			{store.subscription_modal && (
				<UpgradeModal
					hide={() => openSubscriptionModal(store, false)}
					store={store}
					show={store.subscription_modal}
				/>
			)}
			{activeModal === "candidate-mini-profile" && activeCandidate && (
				<Suspense fallback={<Fallback modal={true} />}>
					<CandidateMiniProfile
						hide={closeModal}
						tnProfileId={activeCandidate.tnProfileId}
						activeActionType="message"
					/>
				</Suspense>
			)}
			{redirect && <Redirect to={redirect} />}
		</>
	);
};
export default memo(MainPageFetcher);
