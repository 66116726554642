import React, { memo } from "react";
import { COLORS } from "constants/style";

export const BurguerMenu = memo(() => (
	<svg width="25" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="25" height="2.75" rx="1.375" fill="#fff" />
		<rect y="9.625" width="25" height="2.75" rx="1.375" fill="#fff" />
		<rect y="19.25" width="25" height="2.75" rx="1.375" fill="#fff" />
	</svg>
));
export const DashboardIcon = memo(({ active }) => (
	<svg width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect
			x=".75"
			y=".75"
			width="9.75"
			height="12.25"
			rx="1.25"
			stroke={COLORS.white}
			strokeWidth="1.5"
			opacity={active ? 1 : 0.6}
		/>
		<rect
			x=".75"
			y="17"
			width="9.75"
			height="7.25"
			rx="1.25"
			stroke={COLORS.white}
			strokeWidth="1.5"
			opacity={active ? 1 : 0.6}
		/>
		<rect
			x="14.5"
			y="12"
			width="9.75"
			height="12.25"
			rx="1.25"
			stroke={COLORS.white}
			strokeWidth="1.5"
			opacity={active ? 1 : 0.6}
		/>
		<rect
			x="14.5"
			y=".75"
			width="9.75"
			height="8.5"
			rx="1.25"
			stroke={COLORS.white}
			strokeWidth="1.5"
			opacity={active ? 1 : 0.6}
		/>
	</svg>
));
export const CandidatesIcon = memo(({ active }) => (
	<svg width="23" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M16.71 6.25c0 3.027-2.495 5.5-5.599 5.5s-5.6-2.473-5.6-5.5 2.496-5.5 5.6-5.5c3.104 0 5.6 2.473 5.6 5.5zm-5.599 9.344c1.342 0 2.62-.283 3.774-.781h.67c3.278 0 5.917 2.614 5.917 5.812v2.031c0 .869-.72 1.594-1.63 1.594H2.38c-.911 0-1.631-.725-1.631-1.594v-2.031c0-3.198 2.64-5.813 5.917-5.813h.67a9.518 9.518 0 003.774.782z"
			fill="transparent"
			stroke={COLORS.white}
			strokeWidth="1.5"
			opacity={active ? 1 : 0.6}
		/>
	</svg>
));
export const JobsIcon = memo(({ active }) => (
	<svg width="27" height="27" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M26.25 9.494v14.557c0 1.279-.933 2.199-1.95 2.199H2.7c-1.017 0-1.95-.92-1.95-2.199V9.494c0-1.277.934-2.199 1.95-2.199h21.6c1.016 0 1.95.922 1.95 2.2z"
			stroke={COLORS.white}
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
			opacity={active ? 1 : 0.6}
		/>
		<path
			d="M7.834 6.682c.47-2.744 2.834-4.818 5.666-4.818 2.832 0 5.197 2.074 5.666 4.818H7.834z"
			stroke={COLORS.white}
			strokeWidth="2"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
			opacity={active ? 1 : 0.6}
		/>
		<path
			d="M2.25 15.636H9M18 15.636h6.75"
			stroke={COLORS.white}
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
			opacity={active ? 1 : 0.6}
		/>
		<rect
			x="9"
			y="13.364"
			width="9"
			height="4.545"
			rx="1"
			stroke={COLORS.white}
			strokeWidth="1.5"
			opacity={active ? 1 : 0.6}
		/>
	</svg>
));
export const WorkforceIcon = memo(({ active }) => (
	<svg width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.5 14.713a2.214 2.214 0 100-4.427 2.214 2.214 0 000 4.428z"
			fill={COLORS.white}
			opacity={active ? 1 : 0.6}
		/>
		<path
			d="M23.932 11.562H22.63a10.172 10.172 0 00-9.192-9.192V1.068A.934.934 0 0012.5.13a.934.934 0 00-.938.938V2.37a10.172 10.172 0 00-9.192 9.192H1.068a.934.934 0 00-.938.938c0 .52.417.937.938.937H2.37a10.172 10.172 0 009.193 9.193v1.302c0 .521.416.938.937.938.52 0 .938-.417.938-.938V22.63a10.172 10.172 0 009.192-9.193h1.302c.521 0 .938-.416.938-.937a.934.934 0 00-.938-.938zm-10.494 9.193V18.23a.934.934 0 00-.938-.937.934.934 0 00-.938.937v2.526a8.354 8.354 0 01-7.317-7.318H6.77c.52 0 .937-.416.937-.937a.934.934 0 00-.937-.938H4.245a8.354 8.354 0 017.317-7.317V6.77c0 .52.417.937.938.937.52 0 .938-.416.938-.937V4.245a8.354 8.354 0 017.317 7.317H18.23a.934.934 0 00-.937.938c0 .52.416.937.937.937h2.526a8.354 8.354 0 01-7.317 7.318z"
			fill={COLORS.white}
			opacity={active ? 1 : 0.6}
		/>
	</svg>
));
export const CRMIcon = memo(({ active }) => (
	<svg width="34" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M26.53 14.399c-.659-1.375-3.018-3.283-5.297-5.127-1.271-1.028-2.473-2-3.238-2.765a.7.7 0 00-.733-.164c-.471.171-.763.303-.992.405-.35.157-.468.21-.94.257a.7.7 0 00-.512.307c-.99 1.48-2.015 1.354-2.7 1.124-.218-.073-.257-.166-.278-.256-.147-.615.591-2.045 1.547-3.002 2.275-2.276 3.448-2.846 5.927-1.739a81.81 81.81 0 005.656 2.252.7.7 0 10.46-1.323 79.836 79.836 0 01-5.544-2.208c-3.21-1.435-4.971-.49-7.487 2.027-.958.958-2.259 2.895-1.923 4.316.145.605.57 1.053 1.203 1.262 1.586.523 3.034.022 4.112-1.409.445-.068.687-.169 1.051-.333.132-.059.286-.127.49-.21.801.752 1.882 1.627 3.02 2.547 2.058 1.665 4.392 3.552 4.917 4.644.26.539-.02.89-.208 1.051-.278.238-.657.314-.863.17a.701.701 0 00-1.093.65c.047.446-.36.7-.537.789-.448.227-.915.187-1.09.025a.7.7 0 00-1.175.447c-.042.459-.382.9-.826 1.072-.214.081-.525.13-.803-.125a.699.699 0 00-1.131.279c-.027.07-.087.238-.743.238-.466 0-1.305-.315-1.715-.587-.491-.323-3.573-2.622-6.234-4.85-.374-.316-1.02-.99-1.592-1.587-.507-.53-.97-1.01-1.208-1.211a.7.7 0 00-.904 1.07c.217.183.637.627 1.1 1.11.624.652 1.27 1.327 1.704 1.691 2.607 2.183 5.74 4.539 6.364 4.95.516.338 1.626.814 2.485.814.69 0 1.221-.158 1.59-.468a2.179 2.179 0 001.591-.016 2.757 2.757 0 001.458-1.346c.551.118 1.183.035 1.757-.253.561-.283.967-.716 1.169-1.221.557.03 1.13-.168 1.606-.575.803-.688 1.024-1.757.56-2.722z"
			fill={COLORS.white}
			opacity={active ? 1 : 0.6}
		/>
		<path
			d="M14 2.8H7.7a.7.7 0 000 1.4H14a.7.7 0 000-1.4zM28.582 12.91a.698.698 0 00-.972-.191l-2.047 1.375a.7.7 0 00.782 1.162l2.047-1.375a.7.7 0 00.19-.972zM24.236 16.25c-.501-.395-2.743-2.69-4.129-4.134a.7.7 0 10-1.01.969c.36.375 3.533 3.683 4.27 4.265.128.101.282.15.433.15a.7.7 0 00.435-1.25zM21.439 17.654c-.84-.67-2.946-2.91-3.422-3.427a.701.701 0 00-1.032.948c.025.026 2.54 2.741 3.579 3.573a.703.703 0 00.984-.11.702.702 0 00-.11-.984zM18.653 19.064c-.999-.841-3.041-3.016-3.44-3.443a.7.7 0 10-1.024.957c.574.614 2.529 2.686 3.56 3.557a.699.699 0 00.988-.084.7.7 0 00-.084-.987z"
			fill={COLORS.white}
			opacity={active ? 1 : 0.6}
		/>
		<path
			d="M8.182 1.591C6.982.455 2.184.086.742.001A.7.7 0 000 .7v12.6a.7.7 0 00.7.7h4.2a.7.7 0 00.665-.483C5.667 13.203 8.08 5.8 8.397 2.16a.696.696 0 00-.215-.569zM4.389 12.6H1.4V1.45c2.25.178 4.678.564 5.56.993-.371 3.066-2.05 8.513-2.57 10.157zM32.9 1.4c-5.496 0-8.545 1.407-8.672 1.465a.697.697 0 00-.297 1.004c.865 1.393 3.574 9.13 4.095 11.017a.7.7 0 00.675.514h4.2a.7.7 0 00.7-.7V2.1a.7.7 0 00-.7-.7zM32.2 14h-2.976c-.663-2.162-2.623-7.798-3.717-10.128 1.069-.362 3.363-.986 6.694-1.064V14z"
			fill={COLORS.white}
			opacity={active ? 1 : 0.6}
		/>
	</svg>
));
export const MarketingIcon = memo(({ active }) => (
	<svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.972 7.622c-4.379 0-7.94 3.352-7.94 7.472 0 2.48 1.654 4.478 2.748 5.8.176.212.34.409.478.587.697.898.61 1.567.61 1.574l.034.31a1.676 1.676 0 00.034 2.24 1.67 1.67 0 00-.448 1.144c0 .934.745 1.668 1.714 1.73a.964.964 0 00-.039.26c0 .581.502 1.053 1.12 1.053.618 0 1.119-.472 1.119-1.053a.998.998 0 00-.035-.25h1.47a.97.97 0 00-.036.25c0 .581.501 1.053 1.12 1.053.618 0 1.12-.472 1.12-1.053 0-.087-.015-.17-.035-.25h.014c1.033 0 1.842-.764 1.842-1.74 0-.43-.158-.829-.448-1.143.29-.315.448-.713.448-1.142 0-.37-.117-.708-.318-.988l-.025-.468c0-.006-.1-.595.589-1.898.248-.47.579-.938.928-1.433.879-1.242 1.873-2.65 1.873-4.584.002-4.12-3.559-7.471-7.937-7.471zm2.98 19.884h-5.694c-.268 0-.57-.324-.57-.757 0-.434.302-.758.57-.758h5.694c.269 0 .57.324.57.758 0 .433-.301.757-.57.757zm-5.576-3.799h5.692c.27 0 .571.324.571.758 0 .433-.302.757-.571.757h-5.692c-.27 0-.57-.324-.57-.758 0-.433.3-.757.57-.757zm7.13-4.988c-.366.518-.743 1.054-1.043 1.621-.447.846-.653 1.51-.738 2.01h-5.08c-.1-.512-.346-1.174-.902-1.89a27.349 27.349 0 00-.511-.63c-.997-1.203-2.361-2.85-2.361-4.736 0-3.166 2.737-5.741 6.101-5.741s6.1 2.575 6.1 5.741c0 1.41-.76 2.486-1.565 3.625zM15.076 5.98c.424 0 .766-.323.766-.721V.956c0-.398-.342-.72-.766-.72-.423 0-.766.322-.766.72v4.302c0 .399.343.722.766.722zM5.3 10.374L1.34 8.223a.794.794 0 00-1.047.264.7.7 0 00.28.985l3.959 2.151c.367.2.836.08 1.048-.264a.699.699 0 00-.28-.985zM29.858 8.487a.793.793 0 00-1.046-.264l-3.96 2.15a.699.699 0 00-.28.986c.212.345.68.463 1.048.264l3.958-2.15c.367-.2.492-.641.28-.986zM7.805 7.22c.21.345.68.463 1.047.264.367-.199.492-.64.28-.985L6.846 2.773c-.21-.345-.68-.463-1.047-.264a.699.699 0 00-.28.985L7.805 7.22zM21.3 7.484c.367.2.836.081 1.048-.264l2.285-3.726a.699.699 0 00-.28-.985c-.367-.2-.836-.081-1.047.264L21.02 6.499a.7.7 0 00.28.985z"
			fill={COLORS.white}
			opacity={active ? 1 : 0.6}
		/>
	</svg>
));
export const ScheduleIcon = memo(({ active }) => (
	<svg width="29" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M22.5 25.25h-20c-.865 0-1.75-.855-1.75-2.159V4.995c0-1.303.885-2.158 1.75-2.158h20c.865 0 1.75.855 1.75 2.158v18.096c0 1.304-.885 2.159-1.75 2.159z"
			stroke={COLORS.white}
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
			opacity={active ? 1 : 0.6}
		/>
		<path
			d="M5.303 5.52V1M19.697 5.52V1"
			stroke={COLORS.white}
			strokeWidth="2"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
			opacity={active ? 1 : 0.6}
		/>
		<path
			d="M3 8.75A.75.75 0 013.75 8h17.5a.75.75 0 010 1.5H3.75A.75.75 0 013 8.75z"
			fill={COLORS.white}
			opacity={active ? 1 : 0.6}
		/>
		{/* <circle cx="17.5" cy="18.5" r="7.5" fill="#fff" /> */}
		<circle
			cx="19.886"
			cy="20.886"
			r="8.136"
			fill="#fff"
			stroke={COLORS.white}
			strokeWidth="1.5"
			opacity={active ? 1 : 0.6}
		/>
		<path
			d="M19.438 20.702l-.026-3.509"
			stroke={"#0892C1"}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M19.54 20.886l3.163 2.36"
			stroke={"#0892C1"}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
));
export const AnalyticsIcon = memo(({ active }) => (
	<svg width="29" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5.01 17.033H2.12c-.753 0-1.366.619-1.366 1.38v6.09c0 .76.613 1.379 1.365 1.379H5.01c.753 0 1.366-.62 1.366-1.38v-6.087c0-.763-.613-1.382-1.366-1.382zm.204 7.472a.205.205 0 01-.204.206H2.12a.205.205 0 01-.204-.206v-6.09c0-.114.093-.205.203-.205H5.01c.114 0 .204.094.204.205v6.09zM12.28 11.985H9.39c-.753 0-1.367.619-1.367 1.38v11.14c0 .76.614 1.38 1.366 1.38h2.891c.753 0 1.366-.62 1.366-1.38v-11.14c.003-.761-.61-1.38-1.366-1.38zm.207 12.52a.205.205 0 01-.204.206H9.392a.205.205 0 01-.203-.206v-11.14c0-.115.093-.206.203-.206h2.891c.113 0 .204.093.204.205v11.141zM19.553 14.535h-2.891c-.753 0-1.366.62-1.366 1.38v8.587c0 .76.613 1.38 1.366 1.38h2.89c.753 0 1.367-.62 1.367-1.38v-8.584c0-.764-.614-1.383-1.366-1.383zm.203 9.97a.205.205 0 01-.203.206h-2.891a.205.205 0 01-.204-.206v-8.587c0-.115.093-.206.204-.206h2.89c.114 0 .204.094.204.206v8.587zM26.823 9.334h-2.891c-.753 0-1.366.62-1.366 1.38v13.791c0 .76.613 1.38 1.366 1.38h2.89c.753 0 1.366-.62 1.366-1.38V10.714c.003-.76-.61-1.38-1.365-1.38zm.206 15.171a.205.205 0 01-.203.206h-2.892a.205.205 0 01-.203-.206V10.714c0-.115.093-.206.203-.206h2.892c.113 0 .203.094.203.206v13.791z"
			fill={COLORS.white}
			opacity={active ? 1 : 0.6}
		/>
		<path
			d="M23.461 5.806a2.787 2.787 0 001.915.766c1.554 0 2.815-1.273 2.815-2.844 0-1.57-1.26-2.843-2.815-2.843S22.56 2.158 22.56 3.728c0 .4.082.775.227 1.122l-2.673 1.899a2.778 2.778 0 00-1.462-.799 2.808 2.808 0 00-3.027 1.441l-2.005-.713c.18-1.388-.674-2.735-2.049-3.11-1.499-.415-3.048.48-3.454 1.995a2.845 2.845 0 00.11 1.81l-2.705 1.84a2.79 2.79 0 00-1.959-.803C2.01 8.41.748 9.684.748 11.254c0 1.57 1.26 2.844 2.815 2.844s2.816-1.274 2.816-2.844c0-.379-.076-.737-.206-1.066l2.702-1.837c.331.326.744.572 1.22.704a2.804 2.804 0 003.144-1.267l2.063.733c-.11 1.418.843 2.724 2.258 3.009 1.525.305 3.007-.696 3.31-2.237.104-.54.049-1.071-.129-1.55l2.72-1.937zM25.379 2c.944 0 1.711.775 1.711 1.728 0 .954-.767 1.73-1.711 1.73a1.721 1.721 0 01-1.712-1.73A1.72 1.72 0 0125.38 2zM3.563 12.98a1.721 1.721 0 01-1.711-1.73c0-.953.767-1.728 1.711-1.728.945 0 1.712.775 1.712 1.729a1.72 1.72 0 01-1.712 1.729zm8.923-6.22a1.71 1.71 0 01-2.1 1.215 1.731 1.731 0 01-1.203-2.122 1.71 1.71 0 012.1-1.215 1.732 1.732 0 011.204 2.122zm7.3 2.313a1.711 1.711 0 01-2.011 1.359 1.727 1.727 0 01-1.345-2.031 1.711 1.711 0 012.01-1.359 1.727 1.727 0 011.345 2.031z"
			fill={COLORS.white}
			opacity={active ? 1 : 0.6}
		/>
	</svg>
));
export const TasksIcon = memo(({ active }) => (
	<svg width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect
			x="1"
			y="1"
			width="23"
			height="23"
			rx="3"
			stroke={COLORS.white}
			strokeWidth="2"
			opacity={active ? 1 : 0.6}
		/>
		<path
			d="M11.78 17.783a.643.643 0 01-.456-.189l-4-4a.645.645 0 11.912-.913l3.544 3.544 6.037-8.536a.646.646 0 01.912.912l-6.493 8.993a.648.648 0 01-.456.189z"
			fill={COLORS.white}
			stroke={COLORS.white}
			opacity={active ? 1 : 0.6}
		/>
	</svg>
));
export const AgenciesIcon = memo(({ active }) => (
	<svg width="31" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect
			x=".5"
			y="7.118"
			width="14.319"
			height="17.382"
			rx=".5"
			stroke={COLORS.white}
			opacity={active ? 1 : 0.6}
		/>
		<path
			d="M3.064 11.213h9.19m0 3.064h-9.19"
			stroke={COLORS.white}
			strokeLinecap="round"
			strokeLinejoin="round"
			opacity={active ? 1 : 0.6}
		/>
		<rect
			x="5.096"
			y="17.841"
			width="5.127"
			height="6.659"
			rx=".5"
			stroke={COLORS.white}
			opacity={active ? 1 : 0.6}
		/>
		<path
			d="M17.77 11.213h9.19m0 3.064h-9.19"
			stroke={COLORS.white}
			strokeLinecap="round"
			strokeLinejoin="round"
			opacity={active ? 1 : 0.6}
		/>
		<rect
			x="19.802"
			y="17.841"
			width="5.127"
			height="6.659"
			rx=".5"
			stroke={COLORS.white}
			opacity={active ? 1 : 0.6}
		/>
		<rect
			x="15.206"
			y=".5"
			width="14.441"
			height="24"
			rx=".5"
			stroke={COLORS.white}
			opacity={active ? 1 : 0.6}
		/>
		<rect
			x="18.147"
			y="3.441"
			width="8.559"
			height="3.412"
			rx=".5"
			stroke={COLORS.white}
			opacity={active ? 1 : 0.6}
		/>
	</svg>
));
export const SettingsIcon = memo(({ active }) => (
	<svg width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.34 25a12.998 12.998 0 01-1.734-.718 1.652 1.652 0 01-.235-.592l-.375-2.042a3.294 3.294 0 00-2.646-2.642l-2.038-.374a1.603 1.603 0 01-.593-.234C.44 17.845.2 17.266 0 16.67c.046-.214.132-.412.254-.59l1.177-1.707a3.298 3.298 0 000-3.737L.257 8.926a1.65 1.65 0 01-.254-.586c.198-.598.44-1.177.718-1.734.181-.115.382-.197.593-.235l2.041-.375A3.294 3.294 0 005.997 3.35l.374-2.038c.04-.219.12-.418.234-.593A12.96 12.96 0 018.333 0c.214.046.412.132.59.254L10.63 1.43a3.298 3.298 0 003.737 0L16.074.254c.181-.126.382-.21.586-.254.598.198 1.177.44 1.734.718.115.18.197.382.235.592l.375 2.042a3.294 3.294 0 002.646 2.642l2.038.374c.219.04.418.12.593.233.28.555.52 1.133.719 1.729a1.652 1.652 0 01-.253.59l-1.178 1.707a3.298 3.298 0 000 3.737l1.178 1.707c.125.18.209.382.253.586-.198.597-.44 1.177-.718 1.733a1.653 1.653 0 01-.592.236L21.648 19a3.294 3.294 0 00-2.642 2.646l-.374 2.038c-.04.219-.12.418-.234.592-.554.28-1.132.52-1.728.72a1.652 1.652 0 01-.59-.254l-1.707-1.177a3.298 3.298 0 00-3.737 0L8.93 24.743c-.18.125-.382.21-.586.254L8.34 25z"
			fill={COLORS.white}
			opacity={active ? 1 : 0.6}
		/>
		<circle cx="12.502" cy="12.502" r="5.268" fill="#0892C1" />
	</svg>
));
