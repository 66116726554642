import React, { useEffect } from "react";
import styled from "styled-components";
import { ATSContainer } from "styles/PageContainers";
import { COLORS } from "constants/style";
import useGlobalContext from "contexts/globalContext/GlobalContext";

const RecentSearches = ({ setSearch, origin }) => {
	const store = useGlobalContext();
	useEffect(() => {
		document.body.style.overflow = "hidden";
		return () => (document.body.style.overflow = "auto");
	}, []);

	return (
		<ResultsWrapper>
			<ATSContainer>
				<ResultsGrid>
					{store.stored_searches[origin] && store.stored_searches[origin].length > 0 && (
						<div>
							<Heading className="leo-mb-20">Recent Searches</Heading>
							<div>
								{store.stored_searches[origin].map((val, ix) => (
									<Match key={`recent-search-match-${ix}`}>
										<Info
											className="leo-flex leo-align-start leo-justify-between"
											onClick={() => setSearch(val.search)}
										>
											<h3>{val.search}</h3>
											<span>
												{val.results &&
													Object.entries(val.results).map((result, index, arr) => (
														<>
															{index === arr.length - 1 ? " and " : ""}
															<i>{result[1]}</i> {getNameString(result[0], result[1])}
															{index < arr.length - 2 ? ", " : ""}
														</>
													))}
											</span>
										</Info>
									</Match>
								))}
							</div>
						</div>
					)}
				</ResultsGrid>
			</ATSContainer>
		</ResultsWrapper>
	);
};

const getNameString = (name, total) => {
	return `${name.substring(0, 1).toUpperCase()}${name.substring(1, name.length - 1)}${
		total > 1 ? "s" : ""
	}`;
};

export default RecentSearches;

const ResultsWrapper = styled.div`
	/* background: #2a3744; */
	height: 100vh;
	overflow-y: auto;
	padding-top: 20px;
	max-height: calc(100vh - 100px);
`;

const ResultsGrid = styled.div`
	/* border: 1px solid red; */
	display: grid;
	grid-auto-rows: auto;
	grid-gap: 50px 75px;
	grid-template-columns: 1fr;
	justify-content: space-between;
	max-height: 80.5%;
	margin-top: 25px;
	overflow-y: auto;
	width: 100%;

	@media screen and (min-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
	}

	/* & > * {
    border: 1px solid yellow;
  } */
`;

const Heading = styled.h3`
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: ${COLORS.text_black};
`;

const Info = styled.button`
	flex-direction: column;

	h3 {
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: ${COLORS.text_black};

		&:hover {
			text-decoration: underline;
		}
	}

	span {
		font-size: 12px;
		line-height: 15px;
		color: ${COLORS.text_medium_grey};
	}
`;

const Match = styled.div`
	border-bottom: 1px solid rgba(255, 255, 255, 0.25);
	margin: 10px 0;
	padding-bottom: 10px;
`;
