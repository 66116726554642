import { API_ROOT_PATH, ENVIRONMENT_NAME } from "constants/api.js";
import fetch from "fetch-retry";
import queryString from "query-string";

export const fetchTwilioUser = async (session, companyId) => {
	const url = `${API_ROOT_PATH}/v2/twilio_messages/check_twilio_credentials?company_id=${companyId}`;

	let error;
	try {
		let response = await fetch(url, {
			method: "GET",
			headers: session,
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to fetch twilio info");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const sendConfirmationMessage = async (session, company_id) => {
	const url = `${API_ROOT_PATH}/v2/twilio_messages/send_confirmation_message`;

	let error;
	try {
		let response = await fetch(url, {
			method: "POST",
			headers: session,
			body: JSON.stringify({
				company_id,
				staging: ENVIRONMENT_NAME === "staging" ? true : undefined,
			}),
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to send test SMS");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const setMessagesAsRead = async (session, company_id, ptn_id) => {
	const url = `${API_ROOT_PATH}/v2/twilio_messages/set_unread_as_read`;

	let error;
	try {
		let response = await fetch(url, {
			method: "POST",
			headers: session,
			body: JSON.stringify({ company_id, ptn_id }),
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to set messages as read");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const getCandidatesUnreadMessages = async (session, company_id, ptn_array) => {
	const url = `${API_ROOT_PATH}/v2/twilio_messages/find_unread_messages`;

	let error;
	try {
		let response = await fetch(url, {
			method: "POST",
			headers: session,
			body: JSON.stringify({
				company_id,
				ptn_array,
			}),
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to get unread messages for this candidates");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const sendBulkMessages = async (session, body) => {
	const url = `${API_ROOT_PATH}/v2/twilio_messages/send_bulk_messages`;

	let error;
	try {
		let response = await fetch(url, {
			method: "POST",
			headers: session,
			body: JSON.stringify(body),
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to send SMS");
		}
		return res;
	} catch (err) {
		return { err: true, error, customError: err };
	}
};

export const sendSingleMessage = async (session, body) => {
	const url = `${API_ROOT_PATH}/v2/twilio_messages/send_message`;

	let error;
	try {
		let response = await fetch(url, {
			method: "POST",
			headers: session,
			body: JSON.stringify(body),
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to send SMS");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};

export const getCandidateMessages = async (session, company_id, ptn_id, signal) => {
	const query = queryString.stringify({ ptn_id, company_id });
	const url = `${API_ROOT_PATH}/v2/twilio_messages/find_messages?${query}`;

	let error;
	try {
		let response = await fetch(url, {
			method: "GET",
			headers: session,
			signal,
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to get candidate messages");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};
export const fetchRetrySendMessage = async (session, sms_id) => {
	const url = `${API_ROOT_PATH}/v2/twilio_messages/retry_message`;

	let error;
	try {
		let response = await fetch(url, {
			method: "POST",
			headers: session,
			body: JSON.stringify({ sms_id }),
		});
		let res = await response.json();
		if (!response.ok || res.error || res.errors) {
			error = res;
			throw new Error("Unable to send SMS");
		}
		return res;
	} catch (err) {
		return { err: true, ...error, customError: err };
	}
};
