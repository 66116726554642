import React from "react";
import styled from "styled-components";

const BackgroundShapes = ({ stage, COPY, animation }) => {
	return <Container backgroundUrl={COPY[stage].background} className={animation}></Container>;
};

const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	background: url(${(props) => props.backgroundUrl});
	background-size: cover;
	background-repeat: no-repeat;
	width: 100vw;
	height: 100vh;

	&.fade-transition {
		animation: fadeTransition 1000ms forwards;
	}

	@keyframes fadeTransitionBackground {
		0% {
			opacity: 1;
		}
		45% {
			opacity: 0;
		}
		55% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`;
export default BackgroundShapes;
