import React from "react";
import { Container, SectionTitle } from "components/CompanySignup/LeftForm";
import { ProgressButton, BackButton } from "components/CompanySignup/components.sc";
import styled from "styled-components";
import { Users, Total } from "components/CompanySignup/Pricing";
import { COLORS } from "constants/style";

const PlanStage = ({ setStage, paymentPeriod, setPaymentPeriod }) => {
	return (
		<Container className="no-margin">
			<SectionTitle>Choose your Pro Plan</SectionTitle>
			<ToggleContainer className="leo-relative  leo-flex-center-center">
				<div className="inner-container leo-flex-center-center">
					<span className="toggle-text-label leo-mr-10">Monthly</span>
					<Toggle
						checked={paymentPeriod === "yearly"}
						toggle={() => setPaymentPeriod(paymentPeriod === "monthly" ? "yearly" : "monthly")}
					/>
					<span className="toggle-text-label leo-ml-10 leo-relative">
						Annually <span className="annually-option">2 months free</span>
					</span>
				</div>
			</ToggleContainer>
			<PricingContainer>
				<STUsers className="leo-flex leo-align-baseline leo-mb-30">
					<i className="far fa-user"></i>
					<span className="users-number">x1</span>
					<span className="separator" />
					<span className="price-number">{paymentPeriod === "yearly" ? "£80" : "£100"}</span>
				</STUsers>
				<STTotal className="leo-flex leo-align-baseline leo-justify-between">
					<span className="total-text">Total</span>
					<span className="total-number">
						<i>£</i>
						{paymentPeriod === "yearly" ? "80" : "100"}
					</span>
				</STTotal>
			</PricingContainer>
			<div className="leo-flex-center-center leo-mt-50">
				<BackButton
					className="no-margin"
					style={{ marginRight: "10px" }}
					onClick={() => setStage("info")}
				>
					<i className="fas fa-angle-left leo-mr-10"></i> Back
				</BackButton>
				<ProgressButton
					onClick={() => setStage("bank_details")}
					className="no-margin"
					style={{ marginLeft: "10px" }}
				>
					Next <i className="fas fa-angle-right leo-ml-10"></i>
				</ProgressButton>
			</div>
			<SubTitle>You’ll be able to add users directly from your Plan section</SubTitle>
		</Container>
	);
};

const Toggle = ({ toggle, checked }) => {
	return (
		<ToggleWrapper>
			<Input
				id={`plan-period-checkbox`}
				type="checkbox"
				name="plan-period"
				onChange={toggle}
				checked={checked}
			/>
			<Label htmlFor={`plan-period-checkbox`} className={checked ? "checked" : "unchecked"} />
		</ToggleWrapper>
	);
};

const SubTitle = styled.span`
	font-size: 14px;
	line-height: 17px;
	color: #1e1e1e;
	margin: auto;
	margin-top: 15px;
	text-align: center;
`;

const PricingContainer = styled.div`
	max-width: 400px;
	margin: auto;
	margin-top: 64px;
	margin-bottom: 75px;
`;
const ToggleContainer = styled.div`
	max-width: 400px;
	margin: auto;
	&:after {
		content: "";
		height: 1px;
		width: 100%;
		background: #d4dfea;
		position: absolute;
	}

	.inner-container {
		background: white;
		z-index: 1;
		padding: 0px 13px;

		.toggle-text-label {
			font-size: 12px;
			line-height: 14px;
			color: #2a3744;
		}

		.annually-option {
			position: absolute;
			font-weight: bold;
			font-size: 13px;
			line-height: 17px;
			text-align: center;
			color: #2a3744;
			white-space: nowrap;
			top: 17px;
		}
	}
`;

const STTotal = styled(Total)`
	color: ${COLORS.primary_theme_blue};
`;
const STUsers = styled(Users)`
	color: ${COLORS.primary_theme_blue};
`;

const ToggleWrapper = styled.div`
	position: relative;
	border: 1px solid #2a3744;
	border-radius: 13px;
	width: 46px;
	height: 21px;
	cursor: pointer;
`;

const Input = styled.input`
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
`;

const Label = styled.label`
	position: absolute;
	border-radius: 100%;
	width: 17px;
	height: 17px;
	top: 1px;
	left: 1px;
	cursor: pointer;
	background: #2a3744;

	&.unchecked {
		left: 1px;
		right: unset;
	}

	&.checked {
		left: unset;
		right: 1px;
	}
`;
export default PlanStage;
