import detectHover from "detect-hover";

export const SIZE = {
	for_phone_only: "599px",
	for_tablet_portrait_up: "600px",
	for_tablet_landscape_up: "900px",
	for_desktop_up: "1200px",
	for_med_desktop_up: "1400px",
	for_big_desktop_up: "1800px",
};

export const BREAKPOINTS = {
	for_phone_only: `(max-width: ${SIZE.for_phone_only})`,
	for_tablet_portrait_up: `(min-width: ${SIZE.for_tablet_portrait_up})`,
	for_tablet_landscape_up: `(min-width: ${SIZE.for_tablet_landscape_up})`,
	for_desktop_up: `(min-width: ${SIZE.for_desktop_up})`,
	for_med_desktop_up: `(min-width: ${SIZE.for_med_desktop_up})`,
	for_big_desktop_up: `(min-width: ${SIZE.for_big_desktop_up})`,
	for_small_desktop_height: `(max-height: 899px)`,
};

export const CAN_HOVER = detectHover.hover;
