import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ROUTES } from "routes";

const Pricing = ({ isPro, paymentPeriod }) => {
	const proUrl = ROUTES.CompanySignupPro.url();
	return (
		<Container>
			<div className="leo-flex leo-align-baseline leo-mb-30">
				<Title>{isPro ? "Pro" : "Basic"}</Title>
				{!isPro && (
					<STLink className="leo-ml-10" to={proUrl}>
						Change to Pro Plan
					</STLink>
				)}
			</div>
			<Users className="leo-flex leo-align-baseline leo-mb-30">
				<i className="far fa-user"></i>
				<span className="users-number">x1</span>
				<span className="separator" />
				<span className="price-number">
					{isPro ? (paymentPeriod === "yearly" ? "£80" : "£100") : "£0"}
				</span>
			</Users>
			<Total className="leo-flex leo-align-baseline leo-justify-between">
				<span className="total-text">Total</span>
				<span className="total-number">
					{isPro ? (
						<>
							<i>£</i>
							{paymentPeriod === "yearly" ? "80" : "100"}
						</>
					) : (
						"Free"
					)}
				</span>
			</Total>
		</Container>
	);
};

const Container = styled.div`
	max-width: 400px;
	margin: auto;
	margin-top: 3em;
`;

const Title = styled.h2`
	font-weight: bold;
	font-size: 23px;
	line-height: 30px;
	color: #ffffff;
`;

const STLink = styled(Link)`
	font-size: 12px;
	line-height: 15px;
	color: #d4dfea;

	&:hover {
		color: #d4dfea;
	}
	&:active {
		color: #d4dfea;
	}
`;

export const Users = styled.div`
	color: white;

	.users-number {
		font-weight: bold;
		font-size: 12px;
		line-height: 15px;
		margin-left: 5px;
	}

	.price-number {
		font-weight: bold;
		font-size: 16px;
		line-height: 19px;
		text-align: center;
	}

	.separator {
		width: 100%;
		border-bottom: 2px dotted #d4dfea;
		margin: 0px 3px;
	}
`;

export const Total = styled.div`
	color: white;
	border-top: 1px solid #00e1a6;
	padding-top: 8px;

	.total-text {
		font-weight: bold;
		font-size: 16px;
		line-height: 19px;
		text-transform: "uppercase";
	}

	.total-number {
		font-weight: bold;
		font-size: 36px;
		line-height: 44px;

		i {
			font-weight: bold;
			font-size: 16px;
			line-height: 19px;
		}
	}
`;
export default Pricing;
