import React from "react";
import RouteConstructor from "routes/RouteConstructor";
import retryLazy from "hooks/retryLazy";
const JobDashboard = React.lazy(() => retryLazy(() => import("containers/JobDashboard")));
const VendorPage = React.lazy(() => retryLazy(() => import("containers/VendorPage")));
const DealProfile = React.lazy(() => retryLazy(() => import("containers/DealProfile")));
const ClientProfile = React.lazy(() => retryLazy(() => import("containers/ClientProfile")));
const ContactProfile = React.lazy(() => retryLazy(() => import("containers/ContactProfile")));
const CandidateProfile = React.lazy(() => retryLazy(() => import("containers/CandidateProfile")));
const EmailProfile = React.lazy(() => retryLazy(() => import("containers/EmailProfile")));

const role_permissions = {
	marketer: false,
	hiring_manager: false,
	recruiter: false,
	business: false,
};

const subscriptionViews = {
	free: true,
	active: true,
	trial: true,
	starter: true,
	cancelled: false,
	incomplete: false,
	incomplete_expired: false,
	past_due: false,
	unpaid: false,
	lifetime: true,
};

const ATS_PROFILES_ROUTES = {
	JobDashboard: {
		path: "/:companyMentionTag/jobs/:jobSlug/:tab?",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab={args.match.params.tab || "overview"}
				routeObject={ATS_PROFILES_ROUTES.JobDashboard}
				{...args}
			>
				<JobDashboard {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, jobSlug, tab) =>
			`/${companyMentionTag}/jobs/${jobSlug}/${tab || "overview"}`,
		role_permissions: {
			...role_permissions,
			recruiter: true,
			hiring_manager: true,
		},
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	TempJobDashboard: {
		path: "/:companyMentionTag/workforce/jobs/:jobSlug/:tab?",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab={args.match.params.tab || "overview"}
				routeObject={ATS_PROFILES_ROUTES.TempJobDashboard}
				{...args}
			>
				<JobDashboard {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, jobSlug, tab) =>
			`/${companyMentionTag}/workforce/jobs/${jobSlug}/${tab || "overview"}`,
		role_permissions: {
			...role_permissions,
			recruiter: true,
			hiring_manager: true,
		},
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	EmailProfile: {
		path: "/:companyMentionTag/marketing/:profileId/:tab",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab={args.match.params.tab || "overview"}
				routeObject={ATS_PROFILES_ROUTES.EmailProfile}
				{...args}
			>
				<EmailProfile {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, profileId, tab) =>
			`/${companyMentionTag}/marketing/${profileId}/${tab || "overview"}`,
		role_permissions: { ...role_permissions, marketer: true },
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true },
	},
	DealProfile: {
		path: "/:companyMentionTag/clients/deals/:profileId/:tab?",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab={args.match.params.tab || "overview"}
				routeObject={ATS_PROFILES_ROUTES.DealProfile}
				{...args}
			>
				<DealProfile {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, profileId, tab, query) =>
			`/${companyMentionTag}/clients/deals/${profileId}/${tab || "overview"}${query || ""}`,
		role_permissions: { ...role_permissions, business: true },
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	ClientProfile: {
		path: "/:companyMentionTag/clients/companies/:profileId/:tab?",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab={args.match.params.tab || "overview"}
				routeObject={ATS_PROFILES_ROUTES.ClientProfile}
				{...args}
			>
				<ClientProfile {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, profileId, tab, query) =>
			`/${companyMentionTag}/clients/companies/${profileId}/${tab || "overview"}${query || ""}`,
		role_permissions: { ...role_permissions, business: true },
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	ContactProfile: {
		path: "/:companyMentionTag/clients/contacts/:profileId/:tab?",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab={args.match.params.tab || "overview"}
				routeObject={ATS_PROFILES_ROUTES.ContactProfile}
				{...args}
			>
				<ContactProfile {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, profileId, tab, query) =>
			`/${companyMentionTag}/clients/contacts/${profileId}/${tab || "overview"}${query || ""}`,
		role_permissions: { ...role_permissions, business: true },
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	VendorPage: {
		path: "/:companyMentionTag/vendor/:vendorId/:tab?",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab={args.match.params.tab || "overview"}
				routeObject={ATS_PROFILES_ROUTES.VendorPage}
				{...args}
			>
				<VendorPage {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, vendorId, tab) =>
			`/${companyMentionTag}/vendor/${vendorId}/${tab || "overview"}`,
		role_permissions: { ...role_permissions },
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
	CandidateProfile: {
		path: "/:companyMentionTag/talent/:tnProfileId/:tab?",
		render: (args) => (
			<RouteConstructor
				inner={true}
				ats={true}
				activeTab={args.match.params.tab || "overview"}
				routeObject={ATS_PROFILES_ROUTES.CandidateProfile}
				{...args}
			>
				<CandidateProfile {...args} />
			</RouteConstructor>
		),
		url: (companyMentionTag, tnProfileId, tab, query) =>
			`/${companyMentionTag}/talent/${tnProfileId}/${tab || "overview"}${query ? `${query}` : ""}`,
		role_permissions: {
			...role_permissions,
			recruiter: true,
			hiring_manager: true,
		},
		subscriptions_view: { ...subscriptionViews, trialing: true, active: true, free: true },
	},
};

export default ATS_PROFILES_ROUTES;
