import React, { Suspense } from "react";
import InnerPage from "PageWrappers/InnerPage";
import OuterPage from "PageWrappers/OuterPage";
import ATSWrapper from "PageWrappers/ATSWrapper";
import FalseATSWrapper from "PageWrappers/FalseATSWrapper";
import Fallback from "styles/Fallback";

const RouteConstructor = ({ inner, ats, falseAts, children, activeTab, routeObject, ...props }) => {
	return (
		<>
			{inner ? (
				<InnerPage {...props}>
					{ats ? (
						<ATSWrapper activeTab={activeTab} routeObject={routeObject}>
							<Suspense fallback={<Fallback />}>{children}</Suspense>
						</ATSWrapper>
					) : falseAts ? (
						<FalseATSWrapper activeTab={activeTab} routeObject={routeObject}>
							<Suspense fallback={<Fallback />}>{children}</Suspense>
						</FalseATSWrapper>
					) : (
						<Suspense fallback={<Fallback />}>{children}</Suspense>
					)}
				</InnerPage>
			) : (
				<OuterPage {...props}>
					<Suspense fallback={<Fallback />}>{children}</Suspense>
				</OuterPage>
			)}
		</>
	);
};

export default RouteConstructor;
