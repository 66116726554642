import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
// import { ENVIRONMENT_NAME } from 'constants/api'

i18n
  // Enables the i18next backend
  .use(Backend)
  // Enable automatic language detection
  .use(LanguageDetector)
  // Enables the hook initialization module
  .use (initReactI18next)
  .init({
    // Standard language used
    fallbackLng: 'en',// de
    debug: false,
    //Detects and caches a cookie from the language provided
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage'],
      caches: ['localStorage', 'cookie'],
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupSessionStorage: 'i18nextLng',
      // htmlTag: document.documentElement,
    },
    supportedLngs: ['de', 'en'],
    interpolation: {
      escapeValue: false
    }
  })

export default i18n;
