export const addNewSearch = (dispatch, origin, value) => {
	if (value.search) {
		dispatch({ type: "ADD_STORED_SEARCHES", payload: { origin, new: value } });
	}
};

export const useStoredSearch = (dispatch, value) => {
	dispatch({ type: "USE_STORED_SEARCH", payload: value });
	setTimeout(function () {
		dispatch({ type: "USE_STORED_SEARCH", payload: undefined });
	}, 0);
};

export const getLocalStorageSearches = (dispatch) => {
	let localStorageSearches = JSON.parse(localStorage.getItem("stored_searches"));
	if (localStorageSearches) {
		dispatch({ type: "UPDATE_STORED_SEARCHES", payload: localStorageSearches });
	}
};

export const removeStorageSearches = (dispatch) => {
	dispatch({ type: "UPDATE_STORED_SEARCHES", payload: [] });
};

export const storeInLocalStorage = (storedSearches) => {
	let shortedStoredSearches = {};
	Object.entries(storedSearches).map(
		(entry) => (shortedStoredSearches[entry[0]] = entry[1].slice(0, 5))
	);
	localStorage.setItem("stored_searches", JSON.stringify(shortedStoredSearches));
};
